import { alias, date, identifier, serializable } from 'serializr';


export class StrikelogConflictClosingNoteEntity {
  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('noteText'))
  private _noteText: string;

  @serializable(alias('updatedAt', date()))
  private _updatedAt: Date;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;


  get createdAt(): Date {
    return this._createdAt;
  }

  get noteText(): string {
    return this._noteText;
  }

  set noteText(value: string) {
    this._noteText = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  get uuid(): string {
    return this._uuid;
  }
}
