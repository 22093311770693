import { StrikelogConflictStatus } from '@modules/strikelog/constants/strikelog-conflict-api-status.params';
import { StrikelogCollectiveAgreementEntity } from '@modules/strikelog/entities/strikelog-collective-agreement.entity';
import { StrikelogCommitteeEntity } from '@modules/strikelog/entities/strikelog-committee.entity';
import { StrikelogCompetenceCenterEntity } from '@modules/strikelog/entities/strikelog-competence-center.entity';
import { StrikelogConflictClosingNoteEntity } from '@modules/strikelog/entities/strikelog-conflict-closing-note.entity';
import { StrikelogConflictSummaryEntity } from '@modules/strikelog/entities/strikelog-conflict-summary.entity';
import { StrikelogDailyReportEntity } from '@modules/strikelog/entities/strikelog-daily-report.entity';
import { StrikelogEmailAddressEntity } from '@modules/strikelog/entities/strikelog-email-address.entity';
import { StrikelogExemptionApplicationEntity } from '@modules/strikelog/entities/strikelog-exemption-application.entity';
import { StrikelogStrikebreakEntity } from '@modules/strikelog/entities/strikelog-strikebreak.entity';
import { StrikelogUnionEntity } from '@modules/strikelog/entities/strikelog-union.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';


/**
 * @see STRIKELOG_CONFLICT_ENTITY_SCHEMA
 */
export class StrikelogConflictEntity {
  private _closingNote?: StrikelogConflictClosingNoteEntity;
  private _collectiveAgreement?: StrikelogCollectiveAgreementEntity;
  private _competenceCenters?: StrikelogCompetenceCenterEntity[] = [];
  private _committees?: StrikelogCommitteeEntity[] = [];
  private _conflictEmailAddresses?: StrikelogEmailAddressEntity[] = [];
  private _conflictSummary?: StrikelogConflictSummaryEntity;
  private _createdAt: Date;
  private _dailyReports?: StrikelogDailyReportEntity[] = [];
  private _description: string;
  private _endDate: Date;
  private _exemptionApplications?: StrikelogExemptionApplicationEntity[] = [];
  private _name: string;
  private _ongoing: boolean;
  private _availableToLocalStrikeCommittee: boolean;
  private _organizer?: StrikelogUserEntity;
  private _startDate: Date;
  private _status: StrikelogConflictStatus; // Possible status values from API
  private _strikebreaks?: StrikelogStrikebreakEntity[] = [];
  private _unions?: StrikelogUnionEntity[] = [];
  private _updatedAt: Date;
  private _uuid: string;

  get closingNote(): StrikelogConflictClosingNoteEntity | undefined {
    return this._closingNote;
  }

  set closingNote(value: StrikelogConflictClosingNoteEntity | undefined) {
    this._closingNote = value;
  }

  get collectiveAgreement(): StrikelogCollectiveAgreementEntity | undefined {
    return this._collectiveAgreement;
  }

  set collectiveAgreement(value: StrikelogCollectiveAgreementEntity | undefined) {
    this._collectiveAgreement = value;
  }

  get committees(): StrikelogCommitteeEntity[] {
    return this._committees ?? [];
  }

  set committees(value: StrikelogCommitteeEntity[]) {
    this._committees = value;
  }

  get competenceCenters(): StrikelogCompetenceCenterEntity[] {
    return this._competenceCenters ?? [];
  }

  set competenceCenters(value: StrikelogCompetenceCenterEntity[]) {
    this._competenceCenters = value;
  }

  get conflictEmailAddresses(): StrikelogEmailAddressEntity[] {
    return this._conflictEmailAddresses ?? [];
  }

  set conflictEmailAddresses(value: StrikelogEmailAddressEntity[]) {
    this._conflictEmailAddresses = value;
  }

  get conflictSummary(): StrikelogConflictSummaryEntity | undefined {
    return this._conflictSummary;
  }

  set conflictSummary(value: StrikelogConflictSummaryEntity | undefined) {
    this._conflictSummary = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get dailyReports(): StrikelogDailyReportEntity[] {
    return this._dailyReports ?? [];
  }

  set dailyReports(value: StrikelogDailyReportEntity[]) {
    this._dailyReports = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;
  }

  get exemptionApplications(): StrikelogExemptionApplicationEntity[] {
    return this._exemptionApplications ?? [];
  }

  set exemptionApplications(value: StrikelogExemptionApplicationEntity[]) {
    this._exemptionApplications = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get ongoing(): boolean {
    return this._ongoing;
  }

  set ongoing(value: boolean) {
    this._ongoing = value;
  }

  get availableToLocalStrikeCommittee(): boolean {
    return this._availableToLocalStrikeCommittee;
  }

  set availableToLocalStrikeCommittee(value: boolean) {
    this._availableToLocalStrikeCommittee = value;
  }

  get organizer(): StrikelogUserEntity | undefined {
    return this._organizer;
  }

  set organizer(value: StrikelogUserEntity | undefined) {
    this._organizer = value;
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
  }

  get status(): StrikelogConflictStatus {
    return this._status;
  }

  set status(value: StrikelogConflictStatus) {
    this._status = value;
  }

  get strikebreaks(): StrikelogStrikebreakEntity[] {
    return this._strikebreaks ?? [];
  }

  set strikebreaks(value: StrikelogStrikebreakEntity[]) {
    this._strikebreaks = value;
  }

  get unions(): StrikelogUnionEntity[] {
    return this._unions ?? [];
  }

  set unions(value: StrikelogUnionEntity[]) {
    this._unions = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
