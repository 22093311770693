import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@core/helpers/string.helper';


@Pipe({
  name: 'ucFirst',
  pure: true
})
export class UcFirstPipe implements PipeTransform {
  transform(str: string): string {
    return StringHelper.ucFirst(str);
  }
}
