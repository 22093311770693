import { alias, date, identifier, list, object, serializable } from 'serializr';
import { IdAddressEntity } from "@modules/user-management/entities/id-address.entity";
import { IdEmailAddressEntity } from "@modules/user-management/entities/id-email-address.entity";
import { IdPhoneNumberEntity } from "@modules/user-management/entities/id-phone-number.entity";
import { IdRoleEntity } from "@modules/user-management/entities/id-role.entity";

export class IdUserEntity {
  @serializable(alias('addresses', list(object(IdAddressEntity))))
  private _addresses: IdAddressEntity[] = [];

  @serializable(alias('bankAccount'))
  private _bankAccount: string;

  @serializable(alias('birthDate'))
  private _birthDate: string;

  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('email'))
  private _emailAddress: string;

  @serializable(alias('emails', list(object(IdEmailAddressEntity))))
  private _emailAddresses: IdEmailAddressEntity[] = [];

  @serializable(alias('employeeNumber'))
  private _employeeNumber?: number;

  @serializable(alias('firstName'))
  private _firstName: string;

  @serializable(alias('isTestUser'))
  private _isTestUser: boolean = false;

  @serializable(alias('lastName'))
  private _lastName: string;

  @serializable(alias('memberId'))
  private _memberId?: string;

  @serializable(alias('phone'))
  private _phoneNumber: string;

  @serializable(alias('phones', list(object(IdPhoneNumberEntity))))
  private _phoneNumbers: IdPhoneNumberEntity[] = [];

  @serializable(alias('rawSsn'))
  private _rawSsn: string;

  @serializable(alias('roles', list(object(IdRoleEntity))))
  private _roles: IdRoleEntity[] = [];

  @serializable(alias('TFAActivated'))
  private _tfaActivated: boolean;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  // @serializable(alias('verifications', list(object(UserVerificationEntity))))
  // private _verifications: UserVerificationEntity[] = [];

  @serializable(alias('verifiedAt', date() || null))
  private _verifiedAt: Date | null = null;


  get addresses(): IdAddressEntity[] {
    return this._addresses;
  }

  set addresses(value: IdAddressEntity[]) {
    this._addresses = value;
  }

  get bankAccount(): string {
    return this._bankAccount;
  }

  set bankAccount(value: string) {
    this._bankAccount = value;
  }

  get birthDate(): string {
    return this._birthDate;
  }

  set birthDate(value: string) {
    this._birthDate = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get emailAddress(): string {
    return this._emailAddress;
  }

  set emailAddress(value: string) {
    this._emailAddress = value;
  }

  get emailAddresses(): IdEmailAddressEntity[] {
    return this._emailAddresses;
  }

  set emailAddresses(value: IdEmailAddressEntity[]) {
    this._emailAddresses = value;
  }

  get employeeNumber(): number | undefined {
    return this._employeeNumber;
  }

  set employeeNumber(value: number | undefined) {
    this._employeeNumber = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get isTestUser(): boolean {
    return this._isTestUser;
  }

  set isTestUser(value: boolean) {
    this._isTestUser = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get memberId(): string | undefined {
    return this._memberId;
  }

  set memberId(value: string | undefined) {
    this._memberId = value;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  get phoneNumbers(): IdPhoneNumberEntity[] {
    return this._phoneNumbers;
  }

  set phoneNumbers(value: IdPhoneNumberEntity[]) {
    this._phoneNumbers = value;
  }

  get rawSsn(): string {
    return this._rawSsn;
  }

  set rawSsn(value: string) {
    this._rawSsn = value;
  }

  get roles(): IdRoleEntity[] {
    return this._roles;
  }

  set roles(value: IdRoleEntity[]) {
    this._roles = value;
  }

  get tfaActivated(): boolean {
    return this._tfaActivated;
  }

  set tfaActivated(value: boolean) {
    this._tfaActivated = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get verifiedAt(): Date | null {
    return this._verifiedAt;
  }

  set verifiedAt(value: Date | null) {
    this._verifiedAt = value;
  }
}
