import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';


export enum StrikelogEndpointsEnum {
  DELETE_EXEMPTION_APPLICATION_FILE = '/v2/exemption-application-files/:fileUuid',
  POST_EXEMPTION_APPLICATION = '/v4/exemption-applications',
  POST_EXEMPTION_APPLICATION_APPEAL_FILES = '/v2/exemption-appeals/:appealUuid/files',
  POST_EXEMPTION_APPLICATION_APPEAL_DECISION_FILES = '/v2/exemption-appeal-decisions/:appealDecisionUuid/files',
  POST_EXEMPTION_APPLICATION_DECISION_FILES = '/v2/exemption-application-decisions/:exemptionApplicationDecisionUuid/files',
  POST_EXEMPTION_APPLICATION_FILES = '/v1/exemption-application-files',
}

export const strikelogApi: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://api.streikelogg.fagforbundet.no',
  },
  staging: {
    baseUrl: 'https://api.streikelogg.fagforbundet.dev',
  },
  build: {
    baseUrl: 'https://api.streikelogg.fagforbundet.build',
  },
};
