import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';


@Component({
  selector: 'app-stats-box',
  templateUrl: './stats-box.component.html',
  styleUrls: ['./stats-box.component.scss']
})
export class StatsBoxComponent {
  @Input()
  anchor?: string;

  @Input()
  anchorParams: Params = {};

  @Input()
  count: number | string;

  @Input()
  emojiDescription?: string;

  @Input()
  emojiRating?: number;

  @Input()
  labelText: string;

  @Input()
  labelTextExtended?: string;
}
