<dialog
  #dialogRef
  [attr.inert]="closed || null"
  [class.close-on-click-outside]="
    currentModalEventData.modalData.closeOnClickOutside
  "
  class="{{ containerClass }}"
  [ngClass]="{ 'no-overflow': currentModalEventData.modalData.noOverflow }"
  >
  <div class="modal-host__content">
    @if (currentModalEventData.modalData.showCloseButton) {
      <button
        (click)="close(currentModalEventData.modalData)"
        (mouseenter)="hoveringCloseButton = true"
        (mouseleave)="hoveringCloseButton = false"
        aria-label="Lukk dialog"
        class="btn btn--close-modal"
        [tabindex]="0"
        type="button"
        >
        Lukk
        <svg-icon
          [applyClass]="true"
          [class.active]="hoveringCloseButton"
          src="assets/icons/x.svg"
          class="s28"
        ></svg-icon>
      </button>
    }

    @if (currentModalEventData.modalData.title) {
      <h1 class="modal-host__title">
        {{ currentModalEventData.modalData.title }}
      </h1>
    }

    <div #modalBodyRef id="modal-host__body" role="document"></div>
    <div style="padding-bottom: 35px;"></div>
  </div>
</dialog>
