import { DistributionEmailMessageCountEntity } from './distribution-email-message-count.entity';
import { DistributionSmsMessageCountEntity } from './distribution-sms-message-count.entity';
import { DistributionEntity } from './distribution.entity';


/**
 *
 * NB! Serializer schema: ../../schemas/distribution/distribution.schema.ts
 * @see DISTRIBUTION_REPORT_ENTITY_SCHEMA
 */
export class DistributionReportEntity {
  private _uuid: string;
  private _date: Date;
  private _distribution: DistributionEntity;
  private _emailMessageCount: DistributionEmailMessageCountEntity;
  private _smsMessageCount: DistributionSmsMessageCountEntity;
  private _sumNet: number;
  private _type: string;
  private _unitPriceNet: number;

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get distribution(): DistributionEntity {
    return this._distribution;
  }

  set distribution(value: DistributionEntity) {
    this._distribution = value;
  }

  get emailMessageCount(): DistributionEmailMessageCountEntity {
    return this._emailMessageCount;
  }

  set emailMessageCount(value: DistributionEmailMessageCountEntity) {
    this._emailMessageCount = value;
  }

  get smsMessageCount(): DistributionSmsMessageCountEntity {
    return this._smsMessageCount;
  }

  set smsMessageCount(value: DistributionSmsMessageCountEntity) {
    this._smsMessageCount = value;
  }

  get sumNet(): number {
    return this._sumNet;
  }

  set sumNet(value: number) {
    this._sumNet = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get unitPriceNet(): number {
    return this._unitPriceNet;
  }

  set unitPriceNet(value: number) {
    this._unitPriceNet = value;
  }
}
