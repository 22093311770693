import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable, filter, tap } from 'rxjs';

/**
 * In the routing module you can pass in a breadcrumb: `string | { key: string, label: string }`
 * in the data object for a route. If you pass in the object signature, you can modify the
 * breadcrumb label from your component
 */
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _crumbSubject = new BehaviorSubject<Breadcrumb[]>([]);
  private _crumbs = new Map<string, Breadcrumb>();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    const crumbs = this._makeBreadcrumbs(this._activatedRoute.root)
    this._setBreadcrumbs(crumbs)

    this._router.events
      .pipe(
        filter((event) => {
          return (
            event instanceof NavigationEnd || event instanceof NavigationCancel
          );
        }),
        tap(() => {
          const crumbs = this._makeBreadcrumbs(this._activatedRoute.root);
          this._setBreadcrumbs(crumbs);
        })
      )
      .subscribe();
  }

  getBreadcrumbs$(): Observable<Breadcrumb[]> {
    return this._crumbSubject.asObservable();
  }

  setBreadcrumbLabel(key: string, label: string) {
    const crumb = this._crumbs.get(key);
    if (crumb) {
      crumb.label = label;
      this._crumbSubject.next(Array.from(this._crumbs.values()));
    }
  }

  private _setBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this._crumbs.clear();

    breadcrumbs.forEach((crumb, index) => {
      if (crumb.key) {
        this._crumbs.set(crumb.key, crumb);
      } else {
        this._crumbs.set(index.toString(), crumb);
      }
    });

    this._crumbSubject.next(Array.from(this._crumbs.values()));
  }

  /**
   * https://medium.com/applantic/https-medium-com-applantic-how-to-implement-breadcrumb-navigation-in-angular-and-primeng-52573e49b97a
   */
  private _makeBreadcrumbs(
    activatedRoute: ActivatedRoute,
    url = '',
    breadcrumbs = Array<Breadcrumb>()
  ): Breadcrumb[] {
    const [child]: ActivatedRoute[] = activatedRoute.children;

    if (child == null) {
      return breadcrumbs;
    }

    const routeURL: string = child.snapshot?.url
      .map((segment) => segment.path)
      .join('/');

    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    const label =
      child.snapshot?.data['breadcrumb']?.label ||
      child.snapshot?.data['breadcrumb'];

    const key = child.snapshot?.data['breadcrumb']?.key;

    if (label) {
      breadcrumbs.push({ key, label, url });
    }

    return this._makeBreadcrumbs(child, url, breadcrumbs);
  }
}

export type Breadcrumb = {
  key?: string;
  label: string;
  url: string;
};
