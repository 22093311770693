import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';


@Component({
  selector: 'app-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
})
/**
 * Shows an input field which has an optionally clickable icon
 *
 * @param {string} iconPath The path for the icon to display
 */
export class IconInputComponent {
  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled = false;

  @Input()
  iconClassList = '';

  @Input()
  iconPath = 'assets/images/svg/spyglass.svg';

  @Input()
  iconPosition?: 'left' | 'right' = 'right';

  @HostBinding('class.processing')
  @Input()
  processing = false;

  @HostBinding()
  tabindex = '-1';

  @HostBinding('class.left')
  get left(): boolean {
    return this.iconPosition === 'left';
  }

  @HostBinding('class.right')
  get right(): boolean {
    return (!this.iconPosition || this.iconPosition === 'right');
  }

  /**
   *
   */
  clickAction(): void {
    if (!this.disabled) {
      this.clicked?.emit();
    }
  }
}
