import { alias, date, identifier, list, object, serializable } from 'serializr';

export class CancellationUnionEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('departmentNumber'))
  private _departmentNumber: string;

  constructor(name: string) {
    this._name = name;
  }

  get uuid() {
    return this._uuid;
  }

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get departmentNumber() {
    return this._departmentNumber;
  }
}
