import { BehaviorSubject, noop } from 'rxjs';
import { alias, custom, date, identifier, serializable } from 'serializr';

export class IdPhoneNumberEntity {
  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('countryCode'))
  private _countryCode: string;

  @serializable(alias('number'))
  private _number: string;

  @serializable(alias('primary'))
  private _primary: boolean = false;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  @serializable(alias('verifiedAt', custom(
    noop,
    (value, context) => {
      context.target.verified$.next(!!value);
      return value;
    }
  )))

  private readonly _verifiedAt: Date|null;

  verified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  verificationSent: boolean = false;

  constructor(uuid?: string) {
    if (uuid) {
      this._uuid = uuid;
    }
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get countryCode(): string {
    return this._countryCode;
  }

  set countryCode(value: string) {
    this._countryCode = value;
  }

  get number(): string {
    return this._number;
  }

  set number(value: string) {
    this._number = value;
  }

  get primary(): boolean {
    return this._primary;
  }

  set primary(value: boolean) {
    this._primary = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  get verified(): boolean {
    return !!this._verifiedAt;
  }

  /**
   *
   */
  get numberWithCountryPrefix(): string {
    if (this._countryCode) {
      return '+' + this._countryCode + this._number;
    } else {
      return this.number;
    }
  }
}
