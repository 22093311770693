import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent<T> implements OnChanges {
  @Input()
  isDisabled ?= false;

  @Output()
  itemSelected: EventEmitter<T | null> = new EventEmitter();

  @Output()
  itemUnselected: EventEmitter<T> = new EventEmitter();

  @Input()
  items: T[];

  @ViewChild('selectRef', {static: false})
  selectRef: ElementRef<HTMLElement>;

  @Input()
  selectText: string;

  @Input()
  selectedItems?: T[];

  @Input()
  valuePropertyName?: string; // Not needed if array primitives

  currentItem: T | undefined;
  isOpen = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && !changes['selectedItem'].isFirstChange()) {
      this.currentItem = changes['selectedItem'].currentValue;
    }

    if (changes['items'] && !changes['items'].isFirstChange()) {
      this.items = changes['items'].currentValue;

      // Reset if no longer available
      if (this.currentItem && this.items.indexOf(this.currentItem) === -1) {
        this.currentItem = undefined;
      }
    }
  }

  close(): void {
    this.isOpen = false;
  }

  isSelected(item: T): boolean {
    return (this.selectedItems != undefined) && (this.selectedItems.findIndex((all) => all === item) > -1);
  }

  /**
   * Chooses the given (/selected) item
   */
  pickItem(item): void {
    if (this.currentItem === item) {
      this.close();
      return;
    }

    this.currentItem = item;
    this.close();
    this.selectRef.nativeElement.focus();
    this.itemSelected.emit(this.currentItem);
  }

  /**
   * Returns the display text of given item
   * @see valuePropertyName
   */
  resolveText(item: any): string {
    if (typeof item === 'string') {
      return item;
    }

    const getText = (segments: Array<string>, value: object, index: number = 0): string => {
      value = value[segments[index]];

      const nextIndex = (index + 1);

      if (segments[nextIndex]) {
        return getText(segments, value, nextIndex);
      }

      return value.toString();
    };

    return getText(this.valuePropertyName?.split('.') ?? [], item);
  }

  /**
   * Toggles open state
   */
  toggleState(): void {
    if (this.isDisabled) {
      this.close();
      return;
    }

    this.isOpen = !this.isOpen;
  }

  unselect(event: Event, item: T): void {
    event.stopPropagation();
    this.itemUnselected.emit(item);
  }
}
