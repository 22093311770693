import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { StrikelogEmployerWorkplaceEntity } from '@modules/strikelog/entities/strikelog-employer-workplace.entity';
import { alias, list, object, serializable } from 'serializr';


export class StrikelogEmployerEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('fane2Id'))
  private _fane2Id: number;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('organizationNumber'))
  private _organizationNumber: string;

  @serializable(alias('workplaces', list(object(StrikelogEmployerWorkplaceEntity))))
  private _workplaces?: StrikelogEmployerWorkplaceEntity[] = [];

  get fane2Id(): number {
    return this._fane2Id;
  }

  set fane2Id(value: number) {
    this._fane2Id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get organizationNumber(): string {
    return this._organizationNumber;
  }

  set organizationNumber(value: string) {
    this._organizationNumber = value;
  }

  get workplaces(): StrikelogEmployerWorkplaceEntity[] {
    return this._workplaces ?? [];
  }

  set workplaces(value: StrikelogEmployerWorkplaceEntity[]) {
    this._workplaces = value;
  }
}
