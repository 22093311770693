import {Pipe, PipeTransform} from '@angular/core';
import {DistributionUserEntity} from '@core/entities/distribution/distribution-user.entity';

/**
 * As the first version of the distribution system did not have the user object, we need to handle that it can be missing.
 * Added test to see if just first or last name is missing for good measures.
 */
@Pipe({
  name: 'distributionUser'
})
export class DistributionUserPipe implements PipeTransform {
  /**
   *
   */
  transform(user: DistributionUserEntity | null): string {
    return user == null
      ? 'n/a'
      : `${user.firstName} ${user.lastName}`;
  }
}
