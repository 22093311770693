import {ChangeDetectionStrategy, Component, Input, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalBaseClassDirective} from '@shared/components/modal/content/modal-base.class';
import {TextModalDataInterface} from '@shared/components/modal/content/text-modal/text-modal-data.interface';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextModalComponent extends ModalBaseClassDirective {
  @Input()
  override data: TextModalDataInterface;

  constructor(
    private _sanitizer: DomSanitizer,
  ) {
    super();
  }

  get sanitizedBody(): string | null {
    return this._sanitizer.sanitize(SecurityContext.HTML, this.data.bodyHtml ?? '');
  }
}
