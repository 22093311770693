import { Component, HostBinding, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  LoadingScreenService,
  LoadingScreenDependencies
} from '@shared/components/loading-screen/loading-screen.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
  @HostBinding('attr.role')
  role = 'dialog';

  hidden = true;

  constructor(
    private readonly _loadingScreenService: LoadingScreenService,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this._listenToStateChange();
    this._handleRoutingEvents();
  }

  private _handleRoutingEvents(): void {
    this._router.events.pipe(
      tap((event) => {
        switch (event.constructor.name) {
          case NavigationCancel.name:
            this._loadingScreenService.removeDependant(LoadingScreenDependencies.NAVIGATION);
            break;
          case NavigationEnd.name:
            this._loadingScreenService.removeDependant(LoadingScreenDependencies.NAVIGATION);
            break;
          case NavigationError.name:
            this._loadingScreenService.removeDependant(LoadingScreenDependencies.NAVIGATION);
            break;
          case NavigationStart.name:
            this._loadingScreenService.addDependant(LoadingScreenDependencies.NAVIGATION);
            break;
        }
      })
    ).subscribe();
  }

  private _listenToStateChange(): void {
    this._loadingScreenService.getSubject().pipe(
      tap((state: boolean) => {
        this.hidden = state;
        if (this.hidden) {
          document.body.classList.remove('no-scroll');
        } else {
          document.body.classList.add('no-scroll');
        }
      })
    ).subscribe();
  }
}
