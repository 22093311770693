import { FfNgxEndpointRecords } from '@fagforbundet/ngx-components';

export const MembershipApiEndpoints = {
  GET_ORGANIZATIONAL_UNITS: {
    path: '/v1/organizational-units',
  },
  GET_PROFESSIONS: {
    path: '/v1/professions',
  },
  GET_REFERER: {
    path: '/v1/users/by-referrer-identifier/:identifier',
  },
  POST_MEMBERSHIP_APPLICATION: {
    path: '/v3/membership-applications',
  },
} satisfies FfNgxEndpointRecords;
