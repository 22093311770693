@if (iconPath) {
  <svg-icon
    [applyClass]="true"
    class="monochrome--gray"
    [class.active]="isActive"
    [class.monochrome--disabled]="disabled"
    [src]="iconPath"
    [svgStyle]="{'height.px': '18','width.px': '18'}"
    role="presentation"
  ></svg-icon>
}
{{ label }}
