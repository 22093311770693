import { StrikelogAttachmentEntity } from '@modules/strikelog/entities/strikelog-attachment.entity';
import { StrikelogCollectiveAgreementEntity } from '@modules/strikelog/entities/strikelog-collective-agreement.entity';
import { StrikelogCommitteeEntity } from '@modules/strikelog/entities/strikelog-committee.entity';
import { StrikelogConflictEntity } from '@modules/strikelog/entities/strikelog-conflict.entity';
import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { StrikelogEmployerWorkplaceEntity } from '@modules/strikelog/entities/strikelog-employer-workplace.entity';
import { StrikelogEmployerEntity } from '@modules/strikelog/entities/strikelog-employer.entity';
import { StrikelogUnionEntity } from '@modules/strikelog/entities/strikelog-union.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { alias, date, list, object, serializable } from 'serializr';


export class StrikelogStrikebreakEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('strikeBreakFiles', list(object(StrikelogAttachmentEntity))))
  private _attachments?: StrikelogAttachmentEntity[] = [];

  @serializable(alias('creator', object(StrikelogUserEntity)))
  private _author?: StrikelogUserEntity;

  @serializable(alias('collectiveAgreement', object(StrikelogCollectiveAgreementEntity)))
  private _collectiveAgreement?: StrikelogCollectiveAgreementEntity;

  @serializable(alias('localStrikeCommittee', object(StrikelogCommitteeEntity)))
  private _committee?: StrikelogCommitteeEntity;

  @serializable(alias('conflict', object(StrikelogConflictEntity)))
  private _conflict?: StrikelogConflictEntity;

  @serializable(alias('description'))
  private _description?: string;

  @serializable(alias('employer', object(StrikelogEmployerEntity)))
  private _employer?: StrikelogEmployerEntity;

  @serializable(alias('endedAt', date()))
  private _endedAt: Date;

  @serializable(alias('startedAt', date()))
  private _startedAt: Date;

  @serializable(alias('union', object(StrikelogUnionEntity)))
  private _union?: StrikelogUnionEntity;

  @serializable(alias('workplace', object(StrikelogEmployerWorkplaceEntity)))
  private _workplace?: StrikelogEmployerWorkplaceEntity;


  get attachments(): StrikelogAttachmentEntity[] {
    return this._attachments ?? [];
  }

  set attachments(value: StrikelogAttachmentEntity[]) {
    this._attachments = value;
  }

  get author(): StrikelogUserEntity | undefined {
    return this._author;
  }

  set author(value: StrikelogUserEntity | undefined) {
    this._author = value;
  }

  get collectiveAgreement(): StrikelogCollectiveAgreementEntity | undefined {
    return this._collectiveAgreement;
  }

  set collectiveAgreement(value: StrikelogCollectiveAgreementEntity | undefined) {
    this._collectiveAgreement = value;
  }

  get committee(): StrikelogCommitteeEntity | undefined {
    return this._committee;
  }

  set committee(value: StrikelogCommitteeEntity | undefined) {
    this._committee = value;
  }

  get conflict(): StrikelogConflictEntity | undefined {
    return this._conflict;
  }

  set conflict(value: StrikelogConflictEntity | undefined) {
    this._conflict = value;
  }

  get description(): string | undefined {
    return this._description;
  }

  set description(value: string | undefined) {
    this._description = value;
  }

  get employer(): StrikelogEmployerEntity | undefined {
    return this._employer;
  }

  set employer(value: StrikelogEmployerEntity | undefined) {
    this._employer = value;
  }

  get endedAt(): Date {
    return this._endedAt;
  }

  set endedAt(value: Date) {
    this._endedAt = value;
  }

  get startedAt(): Date {
    return this._startedAt;
  }

  set startedAt(value: Date) {
    this._startedAt = value;
  }

  get union(): StrikelogUnionEntity | undefined {
    return this._union;
  }

  set union(value: StrikelogUnionEntity | undefined) {
    this._union = value;
  }

  get workplace(): StrikelogEmployerWorkplaceEntity | undefined {
    return this._workplace;
  }

  set workplace(value: StrikelogEmployerWorkplaceEntity | undefined) {
    this._workplace = value;
  }
}
