import { alias, date, identifier, list, object, serializable } from 'serializr';
import { CancellationReasonsEntity } from './cancellation-reason.entity';
import {
  CancellationState,
  CancellationStatesEntity,
} from './cancellation-states.entity';
import { CancellationUserEntity } from './cancellation-user.entity';
import { CancellationUnionEntity } from './cancellation-union.entity';

export class MembershipCancellationEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('comment'))
  private _comment: string;

  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('union', object(CancellationUnionEntity)))
  private _union: CancellationUnionEntity;

  @serializable(alias('user', object(CancellationUserEntity)))
  private _user: CancellationUserEntity;

  @serializable(alias('states', list(object(CancellationStatesEntity))))
  private _states?: CancellationStatesEntity[] = [];

  @serializable(alias('reasons', list(object(CancellationReasonsEntity))))
  private _reasons?: CancellationReasonsEntity[] = [];

  @serializable(alias('processor', object(CancellationUserEntity)))
  private _processor?: CancellationUserEntity;

  get uuid() {
    return this._uuid;
  }

  get comment() {
    return this._comment;
  }

  get createdAt() {
    return this._createdAt;
  }

  get union() {
    return this._union;
  }

  get user() {
    return this._user;
  }

  get states() {
    return this._states ?? [];
  }

  set states(newStates: CancellationStatesEntity[]) {
    this._states = newStates;
  }

  get reasons() {
    return this._reasons;
  }

  get processor(): CancellationUserEntity | undefined {
    return this._processor;
  }

  set processor(value: CancellationUserEntity | undefined) {
    this._processor = value;
  }

  get processingComplete(): boolean {
    return this.states.some(({ type }) =>
      Array<CancellationState>(
        'cancelled',
        'awaiting_cancellation',
        'transferred_to_other_lo_union',
        'aborted'
      ).includes(type)
    );
  }
}
