import { ProxyRoleEntity } from '@core/entities/proxy/proxy-role.entity';
import { alias, identifier, list, object, serializable } from 'serializr';


export class ProxyUserEntity {
  @serializable(alias('emailAddress'))
  private _emailAddress: string;

  @serializable(alias('fane2Id', identifier()))
  private _fane2PersonId: string;

  @serializable(alias('firstName'))
  private _firstName: string;

  @serializable(alias('lastName'))
  private _lastName: string;

  @serializable(alias('roles', list(object(ProxyRoleEntity))))
  private _roles?: ProxyRoleEntity[] = [];

  @serializable(alias('type'))
  private _type: 'USER' | 'BASIC_AUTH_USER';

  get emailAddress(): string {
    return this._emailAddress;
  }

  set emailAddress(value: string) {
    this._emailAddress = value;
  }

  get fane2PersonId(): string {
    return this._fane2PersonId;
  }

  set fane2PersonId(value: string) {
    this._fane2PersonId = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get roles(): ProxyRoleEntity[] | undefined {
    return this._roles;
  }

  set roles(value: ProxyRoleEntity[] | undefined) {
    this._roles = value;
  }

  get type(): "USER" | "BASIC_AUTH_USER" {
    return this._type;
  }

  set type(value: "USER" | "BASIC_AUTH_USER") {
    this._type = value;
  }
}
