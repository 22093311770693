import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  Breadcrumb,
  BreadcrumbService,
} from '@shared/components/breadcrumb/breadcrumb.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]>;

  /**
   *
   */
  constructor(private _breadcrumbService: BreadcrumbService) {}

  /**
   *
   */
  ngOnInit(): void {
    this.breadcrumbs$ = this._breadcrumbService.getBreadcrumbs$();
  }
}
