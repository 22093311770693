import { DateHelper } from '@core/helpers/date.helper';
import { NumberHelper } from '@core/helpers/number.helper';
import { noop } from 'rxjs';
import { alias, custom, identifier, serializable } from 'serializr';


export class DistributionInvoiceBatchEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('batchNumber'))
  private _batchNumber: number;

  @serializable(alias('createdAt', custom(
    noop,
    timestamp =>  DateHelper.convertTimestampToDate(timestamp))
  ))
  private _createdAt: Date;

  @serializable(alias('periodEnd', custom(
    noop,
    timestamp =>  DateHelper.convertTimestampToDate(timestamp))
  ))
  private _periodEnd: Date;

  @serializable(alias('periodStart', custom(
    noop,
    timestamp =>  DateHelper.convertTimestampToDate(timestamp))
  ))
  private _periodStart: Date;

  @serializable(alias('sentAt', custom(
    noop,
    timestamp =>  DateHelper.convertTimestampToDate(timestamp))
  ))
  private _sentAt: Date;

  @serializable(alias('totalGross', custom(
    noop,
    amount => NumberHelper.convertIntToDecimal(amount))
  ))
  private _totalGross: number;

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get batchNumber(): number {
    return this._batchNumber;
  }

  set batchNumber(value: number) {
    this._batchNumber = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get periodEnd(): Date {
    return this._periodEnd;
  }

  set periodEnd(value: Date) {
    this._periodEnd = value;
  }

  get periodStart(): Date {
    return this._periodStart;
  }

  set periodStart(value: Date) {
    this._periodStart = value;
  }

  get sentAt(): Date {
    return this._sentAt;
  }

  set sentAt(value: Date) {
    this._sentAt = value;
  }

  get totalGross(): number {
    return this._totalGross;
  }

  set totalGross(value: number) {
    this._totalGross = value;
  }
}
