@if (processing) {
  <app-loading-indicator
    [ngClass]="getChildrenClassList()" role="status" size="s20"
  ></app-loading-indicator>
}

@if (iconPath && !processing) {
  <svg-icon
    class="icon"
    [ngClass]="getChildrenClassList()"
    [src]="iconPath"
    [svgStyle]="{'height.px': iconSize?.toString(),'width.px': iconSize?.toString()}"
    role="presentation"
  ></svg-icon>
}

@if (label && !processing) {
  <span>{{ label }}</span>
}

<ng-content></ng-content>
