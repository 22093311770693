import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfirmModalDataInterface } from '@shared/components/modal/content/confirm-modal/confirm-modal-data.interface';
import { ModalBaseClassDirective } from '@shared/components/modal/content/modal-base.class';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent extends ModalBaseClassDirective {
  @Input()
  override data: ConfirmModalDataInterface;

  /**
   * Callback on cancel/decline
   */
  cancel(): void {
    this.closeModal.emit();

    if (this.data.cancelCallback) {
      this.data.cancelCallback();
    }
  }

  /**
   * Callback on confirm
   */
  confirm(): void {
    this.closeModal.emit();

    if (this.data.confirmCallback) {
      this.data.confirmCallback();
    }
  }
}
