import { EmployerEntity } from '@core/entities/employer/employer.entity';
import { WorkplaceEntity } from '@core/entities/workplace/workplace.entity';
import { alias, date, object, serializable } from 'serializr';
import { CancellationUserIdentifierEntity } from './cancellation-user-identifier.entity';

export class CancellationUserEntity {
  @serializable(alias('firstName'))
  private _firstName: string;

  @serializable(alias('lastName'))
  private _lastName: string;

  @serializable(alias('primaryEmailAddress'))
  private _primaryEmailAddress: string;

  @serializable(alias('primaryPhoneNumber'))
  private _primaryPhoneNumber: string;

  @serializable(alias('birthDate', date()))
  private _birthDate: Date;

  @serializable(alias('userIdentifier', object(CancellationUserIdentifierEntity)))
  private _userIdentifier: CancellationUserIdentifierEntity;

  @serializable(alias('mainEmployer', object(EmployerEntity)))
  private _mainEmployer?: EmployerEntity;

  @serializable(alias('mainWorkplace', object(WorkplaceEntity)))
  private _mainWorkplace?: WorkplaceEntity;

  get firstName() {
    return this._firstName;
  }
  get lastName() {
    return this._lastName;
  }
  get primaryEmailAddress() {
    return this._primaryEmailAddress;
  }
  get primaryPhoneNumber() {
    return this._primaryPhoneNumber;
  }
  get birthDate() {
    return this._birthDate;
  }
  get userIdentifier() {
    return this._userIdentifier;
  }
  get mainEmployer() {
    return this._mainEmployer;
  }
  get mainWorkplace() {
    return this._mainWorkplace;
  }
}
