import { Highlightable } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';


@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements Highlightable {
  @Output()
  clicked: EventEmitter<any> = new EventEmitter();

  @Input()
  disabled = false;

  @Input()
  iconPath?: string;

  @HostBinding('attr.aria-label')
  @Input()
  label: string;

  @HostBinding('attr.processing')
  @Input()
  processing = false;

  @HostBinding('role')
  role = 'listitem';

  @HostBinding('tabindex')
  tabindex = '0';

  elementRef: ElementRef;

  private _isActive = false;

  constructor(private _elementRef: ElementRef) {
    this.elementRef = _elementRef;
  }

  /**
   * disabled=false still disables, while null omits attrib which is what we really want
   */
  @HostBinding('disabled')
  get disabledAttrib(): true | null {
    return this.disabled ? true : null;
  }

  @HostBinding('class.active')
  get isActive(): boolean {
    return this._isActive && !this.disabled;
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled || this.processing;
  }

  @HostListener('mouseenter', ['$event'])
  onEnter(e: MouseEvent): void {
    this.setActiveStyles();
  }

  @HostListener('mouseleave', ['$event'])
  onLeave(e: MouseEvent): void {
    this.setInactiveStyles();
  }

  @HostListener('click')
  click(): void {
    if (!this.disabled && !this.processing) {
      this.clicked.emit();
    }
  }

  /**
   *
   */
  getLabel(): string {
    return this.label;
  }

  /**
   *
   */
  setActiveStyles(): void {
    this._isActive = true;
  }

  /**
   *
   */
  setInactiveStyles(): void {
    this._isActive = false;
  }
}
