<div
  [class.error]="errors.length"
  [class.info]="informatives.length"
  class="feedback-messages"
  role="alertdialog"
  >
  <h2 role="heading">Tilbakemelding</h2>
  @if (errors.length) {
    <div aria-live="assertive" class="error" role="alert">
      Vi fant følgende feil:
      <ol>
        @for (userFeedback of errors; track userFeedback) {
          <li class="feedback-messages__message">{{ userFeedback.message }}.</li>
        }
      </ol>
    </div>
  }

  @if (informatives.length) {
    <div aria-live="assertive" class="info" role="alert">
      <ul>
        @for (userFeedback of informatives; track userFeedback) {
          <li class="feedback-messages__message">{{ userFeedback.message }}.</li>
        }
      </ul>
    </div>
  }
</div>
