


<div class="count-label" [class.dual]="count && anchor && emojiRating !== undefined">
  @if (count && anchor?.length) {
    <a
      [routerLink]="anchor"
      [queryParams]="anchorParams"
      >
      <div class="number-wrapper">
        <span class="number">{{count}}</span>
        @if (emojiRating !== undefined) {
          <div
            class="rating rating--emoji rating--{{emojiRating}}"
            [title]="emojiDescription || ''"
          ></div>
        }
      </div>
      <div class="label-wrapper">
        @if (labelTextExtended?.length) {
          <abbr [title]="labelTextExtended">{{labelText}}</abbr>
        } @else {
          <span>{{labelText}}</span>
        }
      </div>
    </a>
  } @else {
    <div class="number-wrapper">
      <span class="number">{{count || '-'}}</span>
    </div>
    <div class="label-wrapper">
      @if (labelTextExtended?.length) {
        <abbr [title]="labelTextExtended">{{labelText}}</abbr>
      } @else {
        <span>{{labelText}}</span>
      }
    </div>
  }
</div>
