import {alias, date, identifier, serializable} from 'serializr';

export class DepartmentUnionEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('type'))
  private _type: string;

  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }
}
