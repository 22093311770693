import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteNotificationInterface } from '@shared/components/site-notification/site-notification.interface';

@Injectable({
  providedIn: 'root'
})
export class SiteNotificationService {
  private readonly _siteNotifications$: BehaviorSubject<SiteNotificationInterface[]> = new BehaviorSubject([]);

  get siteNotifications$(): Observable<SiteNotificationInterface[]> {
    return this._siteNotifications$.asObservable();
  }

  addNotification(notification: SiteNotificationInterface): void {
    const current = this._siteNotifications$.getValue();
    if (current.find((n) => n.id === notification.id)) {
      return;
    }
    this._siteNotifications$.next([...current, notification]);
  }

  removeNotification(notification: SiteNotificationInterface): void {
    const current = this._siteNotifications$.getValue();
    let exists;
    current.forEach((n) => {
      if (n.id === notification.id) {
        exists = n;
      }
    });
    if (!exists) {
      return;
    }
    current.splice(current.indexOf(notification), 1);
    this._siteNotifications$.next([...current]);
  }

  clearNotifications(): void {
    this._siteNotifications$.next([]);
  }
}
