import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements AfterViewInit {
  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  /**
   * Color for button bg. Label for color will be adjusted
   */
  @Input()
  color: ButtonColors = 'red';

  @Input()
  disabled: boolean | null = null;

  @HostBinding('class')
  hostClass = 'btn';

  @Input()
  iconPath?: string;

  @Input()
  iconSize?: number;

  @HostBinding('attr.aria-label')
  @Input()
  label?: string;

  @HostBinding('class.processing')
  @Input()
  processing = false;

  @HostBinding('attr.role')
  role = 'button';

  @HostBinding('tabindex')
  @Input()
  tabindex = 0;

  active = false;
  hover = false;

  private _lightColors: ButtonColors[] = ['beige', 'gray', 'sand', 'transparent', 'white'];

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private _element: ElementRef,
    private _renderer: Renderer2
  ) { }

  /**
   * disabled=false still disables, while null omits attrib which is what we really want
   */
  @HostBinding('disabled')
  get disabledAttrib(): true | null {
    return this.disabled ? true : null;
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    if (this.color) {
      this.hostClass += ' btn--' + this.color;
      this._cdr.detectChanges();
    }
  }

  @HostListener('mouseenter', ['$event'])
  onEnter(): void {
    this.hover = true;
    this._cdr.detectChanges();
  }

  @HostListener('mouseleave', ['$event'])
  onLeave(): void {
    this.hover = false;
    this._cdr.detectChanges();
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(): void {
    this.active = true;
    this._cdr.detectChanges();
  }

  @HostListener('mouseup', ['$event'])
  onMouseRelease(): void {
    this.active = false;
    this._cdr.detectChanges();
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  clickCallback(e: Event|null): boolean {
    if (e) { // The Karma test runner doesnt supply event?
      e.stopPropagation();
      e.preventDefault();
    }

    if (!this.disabled && !this.processing) {
      this.clicked.emit();
    }

    return true;
  }

  @HostBinding('class.active')
  get isActive(): boolean {
    return this.active;
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled || this.processing;
  }

  /**
   * Retrieve the classes to apply to the svg element
   */
  getChildrenClassList(): string {
    let classes = '';

    if (this.iconSize) {
      classes += 's' + this.iconSize;
    }

    if (this._lightColors.indexOf(this.color) > -1) {
      classes += ' monochrome--dark';
    } else if (['red', 'blue', 'black', 'green'].indexOf(this.color) > -1) {
      classes += ' monochrome--white';
    }

    if (this.hover) {
      classes += ' active';
    }

    return classes;
  }
}

export type ButtonColors = 'beige' | 'gray' | 'green' | 'primary' | 'red' | 'sand' | 'secondary' | 'transparent' | 'white';
