import { alias, identifier, list, object, serializable } from 'serializr';


export class IdRoleEntity {
  @serializable(alias('children', list(object(IdRoleEntity))))
  private _children: IdRoleEntity[] = [];

  @serializable(alias('description'))
  private _description: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('role'))
  private _role: string;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;


  get children(): IdRoleEntity[] {
    return this._children;
  }

  set children(value: IdRoleEntity[]) {
    this._children = value;
  }

  get description(): string {
      return this._description;
  }

  set description( value: string ) {
      this._description = value;
  }

  get name(): string {
      return this._name;
  }

  set name( value: string ) {
      this._name = value;
  }

  get role(): string {
    return this._role;
  }

  set role( value: string ) {
    this._role = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
