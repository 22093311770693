import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';
import { IdApiInterface } from '@environments/assets/environment.interface';


export const idApi: EnvironmentTargetInterface = {
  build: {
    adminWebClientId: '2120fa72-0b5d-41e4-aae1-b47caa6e5110', // https://id.fagforbundet.build/clients/oauth/2120fa72-0b5d-41e4-aae1-b47caa6e5110
    baseUrl: 'https://api.id.fagforbundet.build',
  } as IdApiInterface,
  production: {
    adminWebClientId: '16cfbe4b-9d89-4a16-80ea-96a1aa17fb88',
    baseUrl: 'https://api.id.fagforbundet.no',
  } as IdApiInterface,
  staging: {
    adminWebClientId: 'e579832f-8373-46a7-8624-25e5b84b92a8',
    baseUrl: 'https://api.id.fagforbundet.dev',
  } as IdApiInterface,
};
