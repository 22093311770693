import { DateHelper } from '@core/helpers/date.helper';
import { NumberHelper } from '@core/helpers/number.helper';
import { noop } from 'rxjs';
import { alias, custom, identifier, object, serializable } from 'serializr';
import { DistributionReportEntity } from './distribution-report.entity';


export class DistributionInvoiceLineEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('createdAt', custom(
    noop,
    timestamp =>  DateHelper.convertTimestampToDate(timestamp))
  ))
  private _createdAt: Date;

  @serializable(alias('report', object(DistributionReportEntity)))
  private _report: DistributionReportEntity;

  @serializable(alias('sumNet', custom(
    noop,
    amount => NumberHelper.convertIntToDecimal(amount))
  ))
  private _sumNet: number;

  @serializable(alias('type'))
  private _type: string;

  @serializable(alias('unitPriceNet', custom(
    noop,
    amount => NumberHelper.convertIntToDecimal(amount))
  ))
  private _unitPriceNet: number;

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get report(): DistributionReportEntity {
    return this._report;
  }

  set report(value: DistributionReportEntity) {
    this._report = value;
  }

  get sumNet(): number {
    return this._sumNet;
  }

  set sumNet(value: number) {
    this._sumNet = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get unitPriceNet(): number {
    return this._unitPriceNet;
  }

  set unitPriceNet(value: number) {
    this._unitPriceNet = value;
  }
}
