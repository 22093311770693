import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringHelper } from '@core/helpers/string.helper';
import { ListItemComponent } from '@shared/components/list-item/list-item.component';
import { ScrollDirectionEnum } from '@shared/components/typeahead/scroll-direction.enum';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';


/**
 * Component to enhance an input field with typeahead autocompletion
 *
 * @example using external itemsSource
 * Fetch items from API (http searching)
 * <app-ff-typeahead
 *   (filterChanged)="fetchUsersNamed($event)"
 *   [formCtrl]="userObjectCtrl"
 *   [itemDisplayFormat]="%name% (%age%)"
 *   [itemPropertyForSearching]="name",
 *   [items$]="filteredUsersSubjectAsObservable$"
 *   [itemsSource]="external"
 * ></app-ff-typeahead>
 * where fetchUsersNamed(nameQuery: string) leverages debounceTime and switchMap
 *
 * @example using local itemsSource
 * Use static list of items (local filtering):
 * <app-ff-typeahead
 *   [formCtrl]="countryObjectCtrl"
 *   [itemDisplayFormat]="%Name% (%code%)",
 *   [items$]="resolvedCountries$"
 *   [itemsSource]="local"
 * ></app-ff-typeahead>
 *
 */
@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeaheadComponent implements AfterViewInit, OnDestroy {
  /**
   *
   */
  @ViewChild('fieldRef', {static: true})
  fieldRef: ElementRef<HTMLInputElement>;

  /**
   * Emits when entered search/filter term updates
   * @type {EventEmitter<string>}
   */
  @Output()
  filterChanged: EventEmitter<string> = new EventEmitter();

  /**
   * Set true to focus typeahead field in ngAfterViewInit()
   */
  @Input()
  focusInputOnLoad: boolean = false;

  /**
   * The FormControl from your Reactive Form.
   * Listen for ctrl changes in your parent component by using FormControl.valueChanges.pipe()
   */
  @Input()
  formCtrl: FormControl;

  /**
   * The filter <input> field will be given this id, so it can match an outside label for=""
   *
   * @type {string}
   */
  @Input()
  inputHtmlId?: string;

  /**
   * How the selected item is presented when the typeahead is not in focus.
   *
   * @example
   * Given that items look like: {code: 'US', name: 'United States'}
   *   having itemDisplayFormay: '%name% (%code%)'
   *   will yield: United States (US)
   */
  @Input()
  itemDisplayFormat: string = 'name';

  /**
   * Which property on each item should be searched
   */
  @Input()
  itemPropertyForSearching: string = 'name';

  /**
   * Choose whether the [items$] for this instance is fetched from an external source (api/http) or
   * exists in a local (static) array.
   *
   * If local is chosen, typeahead will filter the existing collection when user enters a search string.
   *
   * For external source it's expected that filtering is handled in the (filterChanged) emit callback.
   */
  @Input()
  itemsSource: 'external' | 'local';

  /**
   * The list of possible/matching items, as an observable
   */
  @Input()
  items$: Observable<object[]>;

  @ViewChild('panelRef', {static: false})
  panelRef: ElementRef<HTMLElement>;

  @ViewChildren(ListItemComponent)
  resultItems: QueryList<ListItemComponent>;

  /**
   * Optionally specify a #templateRef to an ng-template which defines how you want to format the results.
   * This ng-template will get a context object { active: boolean, index: number, item: object } where:
   *  - active is whether it is in focus using keyboard navigation
   *  - index is the result index
   *  - item is each result object, of same type as you passed to the 'items' param.
   *  Access these as ref-vars: let-myVar="item" as attrib on the ng-template tag
   *
   * @see https://angular.io/guide/structural-directives#the-ng-template
   * @see https://angular.io/guide/template-syntax#ref-vars
   *
   * @example
   * Define a custom ng-template:
   * <ng-template #devSearchTemplate let-active="active" let-dev="item" let-index="index">
   *   <div [attr.data-index]="index" [class.active]="active" class="typeahead-result" title="{{dev.name}}">
   *     <div class="name">{{dev.name}}</div >
   *     <div *ngIf="dev.phone" class="info">{{dev.phone}}</div>
   *   </div>
   * </ng-template>
   *
   * Pass the template using its #ElementRef:
   * <app-ff-typeahead [resultTemplate]="devSearchTemplate">
   */
  @Input()
  resultTemplate?: TemplateRef<any>;

  @Input()
  textEmptyResults: string = 'Ingen treff på søket ditt';

  @Input()
  textPlaceholder: string = 'Søk..';

  items: object[] = [];
  itemsFiltered: object[] = [];
  keyManager: ActiveDescendantKeyManager<ListItemComponent>;
  panelVisible: boolean = false;

  private readonly _onDestroy$ = new Subject<void>();

  private _exceptionKeys = ['ArrowDown', 'ArrowUp', 'Backspace', 'Delete', 'Enter', 'Escape', 'Space', 'Tab'];
  private _inputFieldValueSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  private _processing: boolean = false;
  private _selectedItem: object | null = null;
  private _virtualPanelTop: number = 0;

  constructor(
    private _cdr: ChangeDetectorRef
  ) {
  }

  /**
   *
   * @returns {string}
   */
  get fieldValue(): string {
    return this.fieldRef.nativeElement.value;
  }

  /**
   * @param {string} value
   * @private
   */
  set fieldValue(value: string) {
    this.fieldRef.nativeElement.value = value;
  }

  /**
   *
   * @returns {boolean}
   */
  get processing(): boolean {
    return this._processing;
  }

  @Input()
  set processing(state: boolean) {
    this._processing = state;
    this._cdr.detectChanges();
  }

  /**
   *
   * @returns {object | null}
   */
  get selectedItem(): object | null {
    return this._selectedItem;
  }

  /**
   *
   * @param {object | null} object
   */
  set selectedItem(object) {
    this._selectedItem = object ? object as ListItemComponent : null;
    this._cdr.detectChanges();
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.keyManager = new ActiveDescendantKeyManager(this.resultItems).withTypeAhead();

    if (!this.selectedItem && this.formCtrl.value) {
      this.selectItem(this.formCtrl.value);
    }

    this._setUpSubscriptions();

    if (this.focusInputOnLoad) {
      setTimeout(() => {
        this.fieldRef.nativeElement.focus();
      }, 1);
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   *
   */
  clearSelectedItem(emitToSubscribers: boolean = true): void {
    this.fieldValue = '';
    // this.keyManager.setActiveItem(undefined);
    this.selectedItem = null;
    this.formCtrl.setValue(this.selectedItem);

    if (emitToSubscribers) {
      this._inputFieldValueSubject$.next('');
    }
  }

  /**
   *
   */
  closePanel(focusInputField: boolean = true): void {
    this.itemsFiltered = this.items;

    if (this.selectedItem) {
      this.fieldValue = this.formatDisplay(this.selectedItem);
    } else {
      this.fieldValue = '';
      this.filterChanged.emit('');
    }

    this._setPanelVisibility(false);

    if (focusInputField) {
      this.fieldRef.nativeElement.focus();
      this.fieldRef.nativeElement.select();
    } else {
      this.fieldRef.nativeElement.blur();
    }
    this._cdr.detectChanges();
  }

  /**
   * Prevents chosen text (item) from being highlighted in browser
   * @param {Event} event
   */
  focusInput(event: Event): void {
    if (!this.selectedItem && !this.panelVisible) {
      this.openPanel();
    }
  }

  /**
   *
   * @param {object | null} item
   * @returns {string}
   */
  formatDisplay(item: object | null): string {
    if (!item) {
      return '';
    }

    // Direct match on a property
    if (item[this.itemDisplayFormat]) {
      // ucFirst if property specified with capital first letter
      return StringHelper.firstCharIsUpper(this.itemDisplayFormat) ?
        StringHelper.ucFirst(item[this.itemDisplayFormat]) :
        item[this.itemDisplayFormat];
    }

    // Replace placeholders
    return this.itemDisplayFormat.replace(
      /(%)([_?a-zA-Z.]+)(%)/g,
      (match, group1, group2) => {
        const field = item[group2];
        if (!field) {
          return '';
        }
        return StringHelper.firstCharIsUpper(group2) ? StringHelper.ucFirst(field) : field;
      }
    );
  }

  /**
   *
   * @param {Event} event
   */
  handleScroll(event: Event): void {
    if (!(document.activeElement === this.fieldRef.nativeElement &&
      event.target === this.fieldRef.nativeElement
    )) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    let direction: ScrollDirectionEnum = ScrollDirectionEnum.DOWN;
    if (event instanceof KeyboardEvent) {
      direction = event.key === 'ArrowDown' ? ScrollDirectionEnum.DOWN : ScrollDirectionEnum.UP;
    } else if (event instanceof WheelEvent) {
      direction = event.deltaY > 0 ? ScrollDirectionEnum.DOWN : ScrollDirectionEnum.UP;
    }

    if (this.panelVisible) {
      this._scrollPanel(direction);
    } else {
      this._scrollSelection(direction);
    }
  }

  /**
   * Handles key inputs, some should be caught and prevented from bubbling.
   * For instance the ArrowUp key should be prevented from also scrolling the browser window
   * @param {KeyboardEvent} keyEvent
   */
  keyDown(keyEvent: KeyboardEvent) {
    const key = keyEvent.key.toString();

    // Handle special keys/exceptions
    if (key.length > 1 || this._exceptionKeys.indexOf(keyEvent.key) > -1) {
      let preventDefault = false;
      let processRegularKeys = true;

      switch (key) {
        case 'ArrowDown':
        case 'ArrowUp':
          preventDefault = true;
          processRegularKeys = false;
          this.handleScroll(keyEvent);
          this.keyManager.onKeydown(keyEvent);
          break;

        case 'Backspace':
        case 'Delete':
          if (this.selectedItem) {
            this._setPanelVisibility(true);
            this.clearSelectedItem(true);
            processRegularKeys = false;
          }
          break;

        case 'Enter':
          preventDefault = true;
          processRegularKeys = false;
          if (this.panelVisible) {
            if (this.keyManager.activeItem) {
              this.selectItem(this.keyManager.activeItem.item);
            } else {
              this.closePanel();
            }
          } else {
            this.openPanel();
          }
          break;

        case 'Escape':
          processRegularKeys = false;
          this._setPanelVisibility(false);
          break;

        case 'Tab':
          processRegularKeys = false;
          if (!this.selectedItem && this.keyManager.activeItem) {
            this.selectItem(this.keyManager.activeItem.item);
          } else {
            this._setPanelVisibility(false);
          }
          break;

        default:
          // All other keys are passed on to the manager
          this.keyManager.onKeydown(keyEvent);
          break;
      }

      if (preventDefault) {
        keyEvent.stopPropagation();
        keyEvent.preventDefault();
      }
      if (!processRegularKeys) {
        return;
      }
    }

    // Start handling new input to the text field
    if (this.selectedItem) {
      this.clearSelectedItem(false);
    } else if (key === 'Backspace' && this.fieldValue.length < 2) {
      this.clearSelectedItem(false);
    }

    // Reset scrolling
    this._setPanelVisibility(true);
    this.panelRef.nativeElement.scrollTop = 0;
    this._virtualPanelTop = 0;

    // Postpone to allow this (keydown) event to be finished so the updated value is what is being nexted
    setTimeout(() => {
      this._inputFieldValueSubject$.next(this.fieldValue);
    }, 200);
  }

  /**
   * Open the panel containing the results list
   */
  openPanel(): void {
    this._setPanelVisibility(true);
    this.clearSelectedItem();
    this.fieldRef.nativeElement.focus();
    this._cdr.detectChanges();
  }

  /**
   * Select an item from the results list
   * @param {object} item
   */
  selectItem(item: object): void {
    this.selectedItem = item;
    this.fieldValue = this.formatDisplay(item);
    this.formCtrl.setValue(this.selectedItem);
    this.keyManager.setActiveItem(this._selectedItem as ListItemComponent);

    if (this.panelVisible) {
      this.closePanel();
    }
  }

  /**
   * Filters collection of items if applicable
   *
   * @private
   */
  private _filterItems(): void {
    // Nothing to filter
    if (!this.itemPropertyForSearching || !this._inputFieldValueSubject$.value.length) {
      // For static list filtering make sure to finish processing state (hide spinner & show results)
      this.processing = false;
      this.itemsFiltered = this.items;
      return;
    }

    if (this.itemsSource === 'external') {
      this.itemsFiltered = this.items;
      return;
    }

    // Carry out filtering of local collection
    this.itemsFiltered = this.items.filter((item) =>
      item[this.itemPropertyForSearching].toLocaleLowerCase().includes(this._inputFieldValueSubject$.value.toLocaleLowerCase())
    );

    this.processing = false;
  }

  /**
   * This scrolls the results panel on keyboard up/down and tries to keep it within bounds
   *
   * @param {"up" | "down"} direction
   * @private
   */
  private _scrollPanel(direction: ScrollDirectionEnum): void {
    if (!this.panelVisible) {
      return;
    }

    const panel = this.panelRef.nativeElement;
    const startBounds = Math.floor(panel.clientHeight / 2);
    const endBounds = Math.floor(panel.scrollHeight - (panel.clientHeight / 2));
    let scrollAmount: number = 45;
    if (this.resultItems && this.resultItems.first && this.resultItems.first.scrollHeight) {
      // The scrollAmount equals the height of one item. That height varies with dynamic result templates
      scrollAmount = this.resultItems.first.scrollHeight;
    }

    const proposedVirtual: number = direction === ScrollDirectionEnum.DOWN ? this._virtualPanelTop + scrollAmount : this._virtualPanelTop - scrollAmount;
    const proposedPanel: number = direction === ScrollDirectionEnum.DOWN ? panel.scrollTop + scrollAmount : panel.scrollTop - scrollAmount;
    let newTop: number;

    if (proposedVirtual < startBounds) {
      newTop = this._virtualPanelTop = 0;
      if (direction === ScrollDirectionEnum.DOWN) {
        this._virtualPanelTop = proposedVirtual;
      }
    } else if (proposedVirtual > endBounds) {
      newTop = this._virtualPanelTop = panel.scrollHeight - panel.clientHeight;
      if (direction === ScrollDirectionEnum.UP) {
        this._virtualPanelTop = endBounds;
      }
    } else {
      newTop = proposedPanel;
      this._virtualPanelTop = proposedVirtual;
    }

    panel.scrollTop = newTop;
  }

  /**
   * @param {ScrollDirectionEnum} direction
   * @private
   */
  private _scrollSelection(direction: ScrollDirectionEnum): void {
    if (!this.selectedItem || this.selectedItem[this.itemPropertyForSearching] === undefined) {
      return;
    }

    const currStep: number = this.itemsFiltered.findIndex((it) => it[this.itemPropertyForSearching] === this.selectedItem?.[this.itemPropertyForSearching]);
    let newItem: object;

    if (direction === ScrollDirectionEnum.UP) {
      newItem = currStep < 1 ? this.itemsFiltered[0] : this.itemsFiltered[currStep - 1];
    } else {
      newItem = this.itemsFiltered.length <= (currStep + 1) ? this.itemsFiltered[currStep] : this.itemsFiltered[currStep + 1];
    }
    this.selectItem(newItem);
  }

  /**
   * @private
   */
  private _setUpSubscriptions(): void {
    // Monitor changes to typeaheads internal input field
    this._inputFieldValueSubject$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((input?: string) => {
        this._virtualPanelTop = 0;
        this.panelRef.nativeElement.scrollTop = 0;
        this.processing = true;

        this.filterChanged.emit(input);
        this._filterItems(); // Also stops processing if static list
      }),
      takeUntil(this._onDestroy$),
      catchError((e) => {
        this.processing = false;
        return throwError(e);
      }),
    ).subscribe();

    // Monitor [external] changes to formCtrl (which is NOT the input field)
    this.formCtrl.valueChanges.pipe(
      tap((val) => {
        this.selectedItem = val;
        this.fieldValue = this.formatDisplay(val);
        this.processing = false;
      }),
      takeUntil(this._onDestroy$),
      catchError((e) => {
        this.processing = false;
        return throwError(e);
      }),
    ).subscribe();

    // Monitor [external] changes to the items collection
    this.items$.pipe(
      tap((items: object[]) => {
        this.items = items;
        this._filterItems();
        // Don't show panel if user hasn't interacted
        if (this.formCtrl.touched) {
          this._setPanelVisibility(true);
        }
        // Check if selected item is in new collection
        if (this.formCtrl.value && this.items.indexOf(this.formCtrl.value) === 0) {
          this.formCtrl.setValue(null);
        }
        this.processing = false;
      }),
      takeUntil(this._onDestroy$),
      catchError((e) => {
        this.processing = false;
        return throwError(e);
      }),
    ).subscribe();
  }

  /**
   * Toggles whether the panel of matches is shown or not
   * @param {boolean} show
   * @private
   */
  private _setPanelVisibility(show: boolean): void {
    this.panelVisible = !!show;
  }
}
