import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ModalDataInterface } from './modal-data.interface';


@Directive()

/**
 *
 */
export abstract class ModalBaseClassDirective {
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  @Input()
  data: ModalDataInterface;
}
