import { Pipe, PipeTransform } from '@angular/core';
import { IdUserEntity } from "@modules/user-management/entities/id-user.entity";

@Pipe({
  name: 'usersName',
  pure: true
})
export class UsersNamePipe implements PipeTransform {
  /**
   * @param user The user entity
   * @param fallback If true, falls back to 'brukeren' else just ''
   */
  transform(user: IdUserEntity, fallback = true): string {
    if (!user) {
      return fallback ? 'brukeren' : '';
    }

    return '«' + user.firstName + ' ' + user.lastName + '»';
  }
}
