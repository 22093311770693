import { StrikelogAttachmentEntity } from '@modules/strikelog/entities/strikelog-attachment.entity';
import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import {
  StrikelogExemptionAppealDecisionEntity,
} from '@modules/strikelog/entities/strikelog-exemption-appeal-decision.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { alias, list, object, serializable } from 'serializr';


export class StrikelogExemptionAppealEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('appealText'))
  private _appealText: string;

  @serializable(alias('exemptionAppealFiles', list(object(StrikelogAttachmentEntity))))
  private _attachments?: StrikelogAttachmentEntity[] = [];

  @serializable(alias('creator', object(StrikelogUserEntity)))
  private _creator?: StrikelogUserEntity;

  @serializable(alias('exempted'))
  private _exempted: number;

  @serializable(alias('exemptionAppealDecision', object(StrikelogExemptionAppealDecisionEntity)))
  private _exemptionAppealDecision?: StrikelogExemptionAppealDecisionEntity;

  get appealText(): string {
    return this._appealText;
  }

  set appealText(value: string) {
    this._appealText = value;
  }

  get attachments(): StrikelogAttachmentEntity[] {
    return this._attachments ?? [];
  }

  set attachments(value: StrikelogAttachmentEntity[]) {
    this._attachments = value;
  }

  get creator(): StrikelogUserEntity | undefined {
    return this._creator;
  }

  set creator(value: StrikelogUserEntity | undefined) {
    this._creator = value;
  }

  get exempted(): number {
    return this._exempted;
  }

  set exempted(value: number) {
    this._exempted = value;
  }

  get exemptionAppealDecision(): StrikelogExemptionAppealDecisionEntity | undefined {
    return this._exemptionAppealDecision;
  }

  set exemptionAppealDecision(value: StrikelogExemptionAppealDecisionEntity | undefined) {
    this._exemptionAppealDecision = value;
  }
}
