import {alias, identifier, serializable} from 'serializr';

export class WorkplaceEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('name'))
  private _name: string;

  constructor(name: string) {
    this._name = name;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
