import { inject, Injectable } from "@angular/core";
import { AdminWebUserEntity } from '@core/entities/user/admin-web-user.entity';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Injectable({
  providedIn: 'root'
})
export class AuthStore {
  readonly #oidcSecurityService = inject(OidcSecurityService);
  readonly #user$ = new BehaviorSubject<AdminWebUserEntity | undefined>(undefined);

  static readonly KEY_REDIRECT_ROUTE = 'storedRedirectRoute';
  static readonly KEY_REDIRECT_ROUTE_TIMESTAMP = 'storedRedirectRouteTimestamp';
  static readonly TTL_REDIRECT_ROUTE = 900000; // Milliseconds before redirect is considered expired
  static readonly KEY_UUID = 'storedUuid'
  static readonly KEY_FIRST_NAME = 'storedFirstName'
  static readonly KEY_LAST_NAME = 'storedLastName'
  static readonly KEY_REMEMBER_ME = 'storedRememberMe'

  constructor() {
    this.#oidcSecurityService.userData$.pipe(
      filter((userInfo: any) => {
        return !!userInfo;
      }),
      tap((userInfo: any) => {
        this.#user$.next(deserialize(AdminWebUserEntity, userInfo));
      })
    ).subscribe();
  }

  get storage(): Storage {
    return localStorage;
  }

  get user$(): Observable<AdminWebUserEntity|undefined> {
    return this.#user$.asObservable();
  }

  deleteItem(key: string): void {
    this.storage.removeItem(key);
  }

  readItem<T>(key: string, removeFromStore = false): T | undefined {
    const data = this.storage.getItem(key);

    if (data) {
      if (removeFromStore) {
        this.deleteItem(key);
      }

      return JSON.parse(data);
    }

    return undefined;
  }

  storeItem<T>(key: string, value: T): void {
    this.storage.setItem(key, JSON.stringify(value));
  }
}
