import { alias, date, identifier, serializable } from 'serializr';


export class StrikelogCollectiveAgreementEntity {
  @serializable(alias('code'))
  private _code: string;

  @serializable(alias('fane2Id'))
  private _fane2Id: number;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('type'))
  private _type: string;

  @serializable(alias('validFrom', date()))
  private readonly _validFrom?: Date;

  @serializable(alias('validTo', date()))
  private readonly _validTo?: Date;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;


  constructor(props: {[Properties in keyof StrikelogCollectiveAgreementEntity]?: StrikelogCollectiveAgreementEntity[Properties]} = {}) {
    if (props.code) {
      this.code = props.code;
    }
    if (props.fane2Id) {
      this.fane2Id = props.fane2Id;
    }
    if (props.name) {
      this.name = props.name;
    }
    if (props.type) {
      this.type = props.type;
    }
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get fane2Id(): number {
    return this._fane2Id;
  }

  set fane2Id(value: number) {
    this._fane2Id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
