import { StrikelogAttachmentEntity } from '@modules/strikelog/entities/strikelog-attachment.entity';
import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { alias, list, object, serializable } from 'serializr';


export class StrikelogExemptionAppealDecisionEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('exemptionAppealDecisionFiles', list(object(StrikelogAttachmentEntity))))
  private _attachments?: StrikelogAttachmentEntity[] = [];

  @serializable(alias('creator', object(StrikelogUserEntity)))
  private _creator?: StrikelogUserEntity;

  @serializable(alias('decisionText'))
  private _decisionText: string;

  @serializable(alias('exempted'))
  private _exempted: number;


  get attachments(): StrikelogAttachmentEntity[] {
    return this._attachments ?? [];
  }

  set attachments(value: StrikelogAttachmentEntity[]) {
    this._attachments = value;
  }

  get creator(): StrikelogUserEntity | undefined {
    return this._creator;
  }

  set creator(value: StrikelogUserEntity | undefined) {
    this._creator = value;
  }

  get decisionText(): string {
    return this._decisionText;
  }

  set decisionText(value: string) {
    this._decisionText = value;
  }

  get exempted(): number {
    return this._exempted;
  }

  set exempted(value: number) {
    this._exempted = value;
  }
}
