import { ElementRef } from '@angular/core';


export class ElementRefHelper {
  static readonly FOCUSABLE_SELECTORS = [
    'a[href]:not([tabindex^="-"])',
    'area[href]:not([tabindex^="-"])',
    'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
    'input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked',
    'select:not([disabled]):not([tabindex^="-"])',
    'textarea:not([disabled]):not([tabindex^="-"])',
    'button:not([disabled]):not([tabindex^="-"])',
    'iframe:not([tabindex^="-"])',
    'audio[controls]:not([tabindex^="-"])',
    'video[controls]:not([tabindex^="-"])',
    '[contenteditable]:not([tabindex^="-"])',
    '[tabindex]:not([tabindex^="-"]):not(.cdk-focus-trap-anchor)',
  ];

  static readonly INTERACTABLE_TAGS = [
    'input',
    'select',
    'textarea',
  ];


  /**
   * Set the focus to the first element with `autofocus` or the first focusable
   * child of the given element
   */
  static focusFirstApplicableChild(node: Element, respectAutofocus = true): void {
    const autofocusNode = node.querySelector('[autofocus]');
    if (respectAutofocus && autofocusNode) {
      (autofocusNode as HTMLElement).focus();
    } else {
      const candidates = this.getFocusableChildren(node);
      if (candidates[0]) {
        candidates[0].focus();
      }
    }
  }

  /**
   * Get all focusable children of the given element
   */
  static getFocusableChildren(node): HTMLElement[] {
    return [...node.querySelectorAll(this.FOCUSABLE_SELECTORS.join(','))].filter(child => {
      return !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length);
    });
  }

  /**
   * Determine if users can interact with given element
   */
  static isUserInteractable(element: HTMLElement): boolean | null {
    return (
      (this.INTERACTABLE_TAGS.includes(element.tagName.toLowerCase())) ||
      (element.parentNode && this.getFocusableChildren(element.parentNode).includes(element))
    );
  }

  /**
   * (Smooth) scroll to the nativeElement of the passed ElementRef.
   */
  static scrollTo(elRef: ElementRef, scrollOpt: ScrollIntoViewOptions = {behavior: 'smooth', block: 'center'}): void {
    if (!elRef) {
      return;
    }

    setTimeout(() => {
      elRef.nativeElement.scrollIntoView(scrollOpt);
    }, 10);
  }
}
