import { noop } from 'rxjs';
import { alias, custom, serializable } from 'serializr';


export class StrikelogPaginationEntity {
  readonly PER_PAGE = 20;

  /**
   * The current page. Used for pagination in FE.
   * Noop serializer because we are not sending this to BE. BE accepts only limit+offset.
   */
  @serializable(alias('currentPage', custom(() => noop, val => val)))
  protected _currentPage = 1;

  /**
   * Used for paginating results in API
   */
  @serializable(alias('offset'))
  protected _offset?: number;

  /**
   * How many items per page. Used by BE as 'limit'
   */
  @serializable(alias('limit'))
  protected _perPage = this.PER_PAGE;

  /**
   * Optional search query
   */
  @serializable(alias('query'))
  protected _query?: string;

  /**
   * API response 'totalCount' holds the total amount of items disregarding pagination
   */
  @serializable(alias('totalCount'))
  protected _totalItems?: number;

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }

  get offset(): number | undefined {
    return this._offset;
  }

  set offset(value: number | undefined) {
    this._offset = value;
  }

  get perPage(): number {
    return this._perPage;
  }

  set perPage(value: number) {
    this._perPage = value;
  }

  get query(): string | undefined {
    return this._query;
  }

  set query(value: string | undefined) {
    this._query = value;
  }

  get totalItems(): number | undefined {
    return this._totalItems;
  }

  set totalItems(value: number | undefined) {
    this._totalItems = value;
  }
}
