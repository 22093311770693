@if (searchPerformed && !processing) {
  <div class="results--count">
    @if (!(results$ | async)?.length) {
      <span>Ingen treff på søket</span>
    }
    @if (pagedEntity && pagedEntity.totalItems) {
      <span>
        @if (pagedEntity.totalItems > pagedEntity.perPage) {
          <span>{{pagedEntity.perPage}} av totalt </span>
        }
        {{pagedEntity.totalItems}} resultater
      </span>
    }
  </div>
}

<div class="results">
  @for (result of (( results$ | async ) ?? [] ) | paginate: {
    currentPage: pagedEntity.currentPage,
    itemsPerPage: pagedEntity.perPage,
    totalItems: pagedEntity.totalItems
    }; track result) {
    <div
      class="results__item"
      >
      <ng-template #tmplDefault let-result="item">
        @if (result) {
          <div class="entity-box box box--padded box--rounded box--gray">
            <div class="data">
              <main class="name">{{result?.name}}</main>
              <footer class="description">{{result?.description}}</footer>
            </div>
          </div>
        }
      </ng-template>
      <ng-container
        *ngTemplateOutlet="resultTemplate || tmplDefault; context: {item: result}"
      ></ng-container>
    </div>
  }
</div>


@if ((results$ | async)?.length) {
  <app-pagination
    (pageChange)="pageChanged.emit($event)"
  ></app-pagination>
}
