import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FilesEndpointsEnum } from "@environments/apis/files.api";
import { map } from "rxjs/operators";
import { FsUserInterface } from "@modules/filesystem/interfaces/fs-user.interface";
import { ApiService } from "@core/services/api.service";
import { IdRoleInterface } from "@core/interfaces/id-role.interface";

@Injectable({
  providedIn: "root",
})
export class FilesystemService {
  readonly #apiService = inject(ApiService);

  getSelfRoles(): Observable<IdRoleInterface[]> {
    return this.#apiService.get("files", FilesEndpointsEnum.GET_SELF).pipe(
      map((response: { user: FsUserInterface }) => {
        return response.user.roles ?? []
      })
    );
  }
}
