import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { CustomHttpParameterCodec } from '@core/utils/custom-http-parameter-codec';
import { noop } from 'rxjs';
import { alias, custom, serializable } from 'serializr';

/**
 * Generic pagination handling.
 * Calculating page count and conversion b/w FE & BE formats.
 * @see PageableUnionsEntity for example of extending this
 */
export class PaginationQueryParamsEntity {
  /**
   * The current page. Used for pagination in FE.
   * Noop serializer because this is a FE-only concept. BE doesn't use "pages", only limit+offset.
   * @type {number}
   * @private
   */
  @serializable(alias('page', custom(() => noop, val => val)))
  protected _currentPage = 1;

  /**
   * Used for paginating results in API
   * @type {number}
   * @private
   */
  @serializable(alias('offset'))
  protected _offset?: number;

  /**
   * How many items per page. Used by BE as 'limit'
   * @type {number}
   * @private
   */
  @serializable(alias('limit'))
  protected _perPage = 20;

  /**
   * Optional search query
   * @type {string}
   * @private
   */
  @serializable(alias('query'))
  protected _query?: string;

  /**
   * API response 'count' holds the total amount of items disregarding pagination
   * @type {number}
   * @private
   */
  @serializable(alias('count'))
  protected _totalItems?: number;

  /**
   * Get the query params needed for navigationExtras
   * @returns {Params}
   */
  get angularQueryParams(): Params {
    return {page: this.currentPage, query: this.query} as Params;
  }

  /**
   * Get pagination details as encoded HttpParams ready for API
   * @returns {HttpParams}
   */
  get apiRequestParams(): HttpParams {
    let params = new HttpParams({encoder: new CustomHttpParameterCodec()});
    params = params.set('limit', this.perPage.toString());

    if (this.calculateOffset() > 0 && this.offset != null) {
      params = params.set('offset', this.offset.toString());
    }

    if (this.query) {
      params = params.set('query', this.query);
    }

    return params;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }

  get offset(): number | undefined {
    return this._offset;
  }

  set offset(value: number | undefined) {
    this._offset = value;
  }

  get perPage(): number {
    return this._perPage;
  }

  set perPage(value: number) {
    this._perPage = value;
  }

  get query(): string | undefined {
    return this._query;
  }

  set query(value: string | undefined) {
    this._query = value;
  }

  get totalItems(): number | undefined {
    return this._totalItems;
  }

  set totalItems(value: number | undefined) {
    this._totalItems = value;
  }

  /**
   * Calculate new offset, store it and return new value
   * @returns {number}
   */
  calculateOffset(): number {
    this.offset = (this.currentPage - 1) * this.perPage;
    return this.offset;
  }
}
