import { StrikelogAttachmentEntity } from '@modules/strikelog/entities/strikelog-attachment.entity';
import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { StrikelogExemptionAppealEntity } from '@modules/strikelog/entities/strikelog-exemption-appeal.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { alias, list, object, serializable } from 'serializr';


export class StrikelogExemptionApplicationDecisionEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('exemptionApplicationDecisionFiles', list(object(StrikelogAttachmentEntity))))
  private _attachments?: StrikelogAttachmentEntity[] = [];

  @serializable(alias('creator', object(StrikelogUserEntity)))
  private _creator?: StrikelogUserEntity;

  @serializable(alias('decisionText'))
  private _decisionText: string;

  @serializable(alias('exempted'))
  private _exempted: number;

  @serializable(alias('exemptionAppeal', object(StrikelogExemptionAppealEntity)))
  private _exemptionAppeal?: StrikelogExemptionAppealEntity;


  get attachments(): StrikelogAttachmentEntity[] {
    return this._attachments ?? [];
  }

  set attachments(value: StrikelogAttachmentEntity[]) {
    this._attachments = value;
  }

  get creator(): StrikelogUserEntity | undefined {
    return this._creator;
  }

  set creator(value: StrikelogUserEntity | undefined) {
    this._creator = value;
  }

  get decisionText(): string {
    return this._decisionText;
  }

  set decisionText(value: string) {
    this._decisionText = value;
  }

  get exempted(): number {
    return this._exempted;
  }

  set exempted(value: number) {
    this._exempted = value;
  }

  get exemptionAppeal(): StrikelogExemptionAppealEntity | undefined {
    return this._exemptionAppeal;
  }

  set exemptionAppeal(value: StrikelogExemptionAppealEntity | undefined) {
    this._exemptionAppeal = value;
  }
}
