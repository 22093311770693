import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";

@Pipe({ name: 'asFormGroup' })
export class AsFormGroupPipe implements PipeTransform {
  transform(control: AbstractControl | null): UntypedFormGroup {
    if (!control) {
      throw('Tried to specify Abstract Control as Form Control, but the received control was not defined.');
    }

    return control as UntypedFormGroup;
  }
}