import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ObjectHelper } from '@core/helpers/object.helper';
import { StringHelper } from '@core/helpers/string.helper';
import { FileInterface } from '@modules/filesystem/interfaces/file.interface';
import { FileListOptionsInterface } from '@shared/components/file-list/file-list-options.interface';
import { FileUploadOptionsInterface } from '@shared/components/file-upload/assets/file-upload-options.interface';
import { FileUploadEntity } from '@shared/components/file-upload/assets/file-upload.entity';
import { FileUploadService } from '@shared/components/file-upload/assets/file-upload.service';
import { FileSizePipe } from '@shared/pipes/file-size.pipe';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnDestroy, OnInit {
  static readonly REGEX_FILE_EXT: RegExp = /(?:\.([^.]+))?$/;

  @Output()
  apiResponse = new EventEmitter<
    UploadResponse<{ body: { file: FileInterface } }>
  >();

  @Output()
  fileSelected = new EventEmitter<FileUploadEntity[]>();

  @Input()
  options: Partial<FileUploadOptionsInterface>;

  @Input()
  requestBodyParams?: HttpParams;

  // Used to trigger upload when uploadOnSelection = false
  @Input()
  triggerUpload = new Observable<void>();

  @Input()
  uploadOnSelection = true;

  stagedFiles: FileUploadEntity[] = [];
  allowedFormats: string;
  config: FileUploadOptionsInterface = {
    apiRequest: {
      headers: {},
      method: 'POST',
      params: {},
      responseType: 'json',
      url: undefined,
      withCredentials: true,
    },
    formats: {
      allowed: ['doc', 'docx', 'jpeg', 'jpg', 'odt', 'pdf', 'txt', 'png'],
      disallowed: ['vbs', 'js', 'sh'],
    },
    htmlId: 'fileUpload_' + StringHelper.generateId(),
    maxFileSize: 10485760, // 10 MB
    multipleFiles: false,
    removeSucceeded: false,
    showProgressBar: true,
    translationStrings: {
      allowedFilesHeading: 'Laster opp',
      discardedFilesHeading: 'Følgende ble ikke lastet opp',
      fileTypes: 'filtyper',
      invalidExtension: 'Ugyldig filtype',
      labelSelectBtn: 'Velg fil',
      maxFileSize: 'Filstørrelsen overskrider maksgrensen',
      sizeLimit: 'Filstr.',
      uploadError: 'Feilet',
      uploadSuccess: 'Fullført',
    },
    uploadButtonClassList: 'btn btn--secondary',
  };
  descriptionOfFormatsAllowed: string;
  discardedFiles: UploadDiscarded[] = [];
  discardedFilesOptions: FileListOptionsInterface<UploadDiscarded> = {
    removeImmediately: false,
    showRemoveBtn: false,
  };
  processing = false;

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _fileSizePipe: FileSizePipe,
    private _fileUploadService: FileUploadService,
    private _http: HttpClient
  ) {}

  @Input()
  set resetFileUpload(reset: boolean) {
    if (reset === true) {
      this.resetComponent();
    }
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   *
   */
  ngOnInit(): void {
    this.config = ObjectHelper.nestedAssign(this.config, this.options);
    this.triggerUpload
      .pipe(
        takeUntil(this._onDestroy$),
        tap(() => {
          this.uploadFiles();
        })
      )
      .subscribe();

    if (this.config.formats == null) return;

    this.allowedFormats = this.config.formats.allowed
      .map((ext) => '.' + ext)
      .join(',');

    if (this.config.translationStrings != null) {
      if (
        this.config.formats.allowed.length &&
        !this.config.formats.allowed.includes('*')
      ) {
        this.descriptionOfFormatsAllowed =
          ', ' +
          this.config.translationStrings.fileTypes +
          ': ' +
          this.config.formats.allowed.join(', ') +
          '.';
      }

      if (
        this.config.multipleFiles &&
        !this.options.translationStrings?.labelSelectBtn
      ) {
        this.config.translationStrings.labelSelectBtn = 'Velg filer';
      }
    }
  }

  /**
   *
   */
  fileSelectionChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    const { allowed = [], disallowed = [] } = this.config.formats ?? {};
    let fileList: FileList;
    fileList = target.files as FileList;
    this.stagedFiles = [];
    this.discardedFiles = [];

    for (const file of fileList as unknown as Iterable<File>) {
      const currentFileExt = FileUploadComponent.REGEX_FILE_EXT.exec(
        file.name
      )?.[1].toLowerCase();
      const isValidSize =
        this.config.maxFileSize == null || file.size <= this.config.maxFileSize;
      const isValidExtension = allowed.includes('*')
        ? currentFileExt == null || !disallowed.includes(currentFileExt)
        : currentFileExt != null &&
          allowed.includes(currentFileExt) &&
          !disallowed.includes(currentFileExt);

      if (isValidExtension && isValidSize) {
        this.stagedFiles.push(new FileUploadEntity(file));
      } else {
        this.discardedFiles.push({
          name: file.name,
          size: file.size,
          errorMsg:
            (!isValidExtension
              ? this.config.translationStrings?.invalidExtension
              : this.config.translationStrings?.maxFileSize) ?? '',
        });
      }
    }

    this.fileSelected.emit(this.stagedFiles);
    target.value = '';

    if (this.uploadOnSelection) {
      this.uploadFiles();
    }
  }

  /**
   *
   */
  removeFile(
    idx: number,
    removeFrom: 'allowed' | 'notAllowed' = 'allowed'
  ): void {
    if (removeFrom === 'allowed') {
      this.stagedFiles.splice(idx, 1);
    } else {
      this.discardedFiles.splice(idx, 1);
    }
  }

  /**
   *
   */
  resetComponent(): void {
    this.stagedFiles = [];
    this.discardedFiles = [];
  }

  /**
   *
   */
  uploadFiles(): void {
    if (!this.stagedFiles.length) return;
    this.processing = true;
    const uploadReqs$: Observable<UploadResult>[] = [];

    this.stagedFiles.forEach((file) => {
      uploadReqs$.push(
        this._fileUploadService
          .upload(file, this.config, this.requestBodyParams)
          .pipe(
            map(
              (response: UploadResponse<{ body: { file: FileInterface } }>) => {
                this.apiResponse.emit(response);
                return { file, response } as UploadResult;
              }
            ),
            catchError((e) => {
              return of({ file, response: e } as UploadResult); // Early catch and convert to let all inner observables in forkJoin complete
            })
          )
      );
    });

    forkJoin([...uploadReqs$])
      .pipe(
        tap((responses: [UploadResult]) => {
          responses.forEach((result: UploadResult) => {
            if (result.file.status === 'succeeded') {
              if (this.config.removeSucceeded) {
                this._removeAllowedFile(result.file.file.name);
              }
            }
            if (result.response instanceof HttpErrorResponse) {
              this._showUserErrorMsg(result);
            }
          });
        }),
        finalize(() => {
          this.apiResponse.emit();
          this.processing = false;
          this._cdr.detectChanges();
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  /**
   *
   */
  private _removeAllowedFile(name: string): void {
    let idx = this.stagedFiles.findIndex((stack) => stack.file.name === name);
    if (idx > -1) {
      this.stagedFiles.splice(idx, 1);
    }
  }

  /**
   *
   */
  private _showUserErrorMsg(result: UploadResult): void {
    if (result.response instanceof HttpErrorResponse) {
      this._removeAllowedFile(result.file.file.name);

      let message = '';

      if (result.response.status === 413) {
        message = UploadErrorsEnum.FILE_TOO_LARGE;
      } else if (result.response?.error?.error) {
        message =
          UploadErrorsEnum[result.response.error.error.toUpperCase()] ||
          result.response.error.error;
      }

      this.discardedFiles.push({
        name: result.file.file.name,
        size: result.file.file.size,
        errorMsg: message,
      });
    }
  }
}

export type UploadDiscarded = { name: string; size: number; errorMsg: string };
export type UploadResponse<T = unknown> = HttpResponse<T> | HttpErrorResponse;
export type UploadResult = {
  file: FileUploadEntity;
  response?: UploadResponse;
};

enum UploadErrorsEnum {
  FILE_EXISTS = 'En fil med dette navnet ekisterer allerede i mappen',
  FILE_TOO_LARGE = 'Filstørrelsen er for stor',
}
