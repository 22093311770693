import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { DistributionInvoiceEntity } from '@core/entities/distribution/distribution-invoice.entity';
import { PaginationQueryParamsEntity } from '@core/entities/query-params/pagination-query-params.entity';
import { DepartmentUnionEntity } from '@core/entities/union/department-union.entity';
import { DateHelper } from '@core/helpers/date.helper';
import { alias, list, object, serializable } from 'serializr';

export class PageableDistributionInvoicesEntity extends PaginationQueryParamsEntity {
  @serializable(alias('invoices', list(object(DistributionInvoiceEntity))))
  private _invoices: DistributionInvoiceEntity[] = [];

  @serializable(alias('uuid', object(DepartmentUnionEntity)))
  private _departmentUnionUuid: string;

  @serializable(alias('sentSince'))
  private _sentSince: number;

  @serializable(alias('sentUntil'))
  private _sentUntil: number;

  @serializable(alias('year'))
  private _year: string;

  /**
   * Extend super to append the role property
   */
  override get angularQueryParams(): Params {
    return {...super.angularQueryParams, year: this.year} as Params;
  }

  /**
   * Extend super to append the role property
   */
  override get apiRequestParams(): HttpParams {
    let params = super.apiRequestParams;

    if (this.departmentUnionUuid) {
      params = params.set('departmentUnionUuid', this.departmentUnionUuid);
    }

    if (this.year) {
      const selectedYear = parseInt(this.year, 10);
      params = params.set('sentSince', DateHelper.getStartOfYearTimestamp(selectedYear).toString());
      params = params.set('sentUntil', DateHelper.getStartOfYearTimestamp(selectedYear + 1).toString());
    }

    return params;
  }

  get invoices(): DistributionInvoiceEntity[] {
    return this._invoices;
  }

  set invoices(value: DistributionInvoiceEntity[]) {
    this._invoices = value;
  }

  get departmentUnionUuid(): string {
    return this._departmentUnionUuid;
  }

  set departmentUnionUuid(value: string) {
    this._departmentUnionUuid = value;
  }

  get sentSince(): number {
    return this._sentSince;
  }

  set sentSince(value: number) {
    this._sentSince = value;
  }

  get sentUntil(): number {
    return this._sentUntil;
  }

  set sentUntil(value: number) {
    this._sentUntil = value;
  }

  get year(): string {
    return this._year;
  }

  set year(value: string) {
    this._year = value;
  }
}
