import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distributionType'
})
export class DistributionTypePipe implements PipeTransform {
  /**
   *
   */
  transform(distributionType: string): string {
    return RecipientListTypesEnum [distributionType];
  }
}

export enum RecipientListTypesEnum {
  'OUTGOING_SMS' = 'Utgående SMS'
}
