import { Component, HostBinding, Input } from '@angular/core';


@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
  @Input()
  done: boolean;

  @Input()
  error: boolean;

  @Input()
  size?: 'small' | 'medium' | 'large' | 's16' | 's18' | 's20' | 's24';

  @Input()
  stroke?: string;

  @HostBinding('role')
  readonly role = 'spinner';
}
