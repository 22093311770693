import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export enum FilesEndpointsEnum {
  DELETE_FILE = '/v1/files/:fileUuid',
  DELETE_FILE_ACCESS_LEVEL = '/v1/files/:fileUuid/access-levels/:accessLevelUuid',
  DELETE_FILE_ATTACHMENT = '/v1/files/:fileUuid/attachments/:attachmentUuid',
  DELETE_FILE_CONTACT = '/v1/files/:fileUuid/contact-informations/:contactInformationUuid',
  DELETE_COLLECTION = '/v1/collections/:collectionUuid',
  DELETE_COLLECTION_FILE = '/v1/collections/:collectionUuid/files/:fileUuid',
  DELETE_DIRECTORY = '/v1/directories/:directoryUuid',
  GET_ACCESS_LEVELS = '/v1/access-levels',
  GET_COLLECTION_FILES = '/v1/collections/:collectionUuid/files',
  GET_COLLECTIONS = '/v1/collections',
  GET_DIRECTORY = '/v1/directories/:directoryUuid',
  GET_FILE_DOWNLOAD_URL = '/v1/files/:fileUuid/download-url',
  GET_ITEMS = '/v1/items',
  GET_SELF = '/v1/users/self',
  PATCH_COLLECTION = '/v1/collections/:collectionUuid',
  PATCH_DIRECTORY = '/v1/directories/:directoryUuid',
  PATCH_FILE = '/v1/files/:fileUuid',
  POST_COLLECTION = '/v1/collections',
  POST_DIRECTORY = '/v1/directories',
  PUT_COLLECTION_FILE = '/v1/collections/:collectionUuid/files/:fileUuid',
  PUT_CONTACT_INFORMATIONS = '/v1/contact-informations',
  PUT_DIRECTORY_FILE = '/v1/directories/:directoryUuid/files/:fileUuid',
  PUT_FILE_ACCESS_LEVEL = '/v1/files/:fileUuid/access-levels/:accessLevelUuid',
  PUT_FILE_ATTACHMENT = '/v1/files/:fileUuid/attachments/:attachmentUuid',
  PUT_FILE_ATTACHMENT_SORT = '/v1/files/:fileUuid/attachments/sort',
  PUT_FILE_CONTACT_IFORMATIONS = '/v1/files/:fileUuid/contact-informations/:contactInformationUuid'
}

export const filesApi: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://api.filer.fagforbundet.no',
  },
  staging: {
    baseUrl: 'https://api.filer.fagforbundet.dev',
  },
};
