import { Component, OnDestroy, OnInit } from '@angular/core';
import { SiteNotificationInterface } from '@shared/components/site-notification/site-notification.interface';
import { SiteNotificationService } from '@shared/components/site-notification/site-notification.service';
import { Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-site-notification',
  templateUrl: './site-notification.component.html',
  styleUrls: ['./site-notification.component.scss']
})
export class SiteNotificationComponent implements OnInit, OnDestroy {
  notifications: SiteNotificationInterface[];

  private readonly _onDestroy$ = new Subject<void>();

  constructor(private readonly _siteNotificationService: SiteNotificationService) {
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   *
   */
  ngOnInit(): void {
    this._siteNotificationService.siteNotifications$.pipe(
      takeUntil(this._onDestroy$),
      tap((s) => {
        this.notifications = s;
      })
    ).subscribe();
  }

}
