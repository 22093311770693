import { Component, HostBinding, Input } from '@angular/core';


/**
 * Utility to pair a tuple of icon + data for easy formatting and DRY
 */
@Component({
  selector: 'app-icon-data-tuple',
  templateUrl: './icon-data-tuple.component.html',
  styleUrls: ['./icon-data-tuple.component.scss'],
})
export class IconDataTupleComponent {
  @Input()
  display: 'horizontal' | 'vertical' = 'horizontal';

  @Input()
  iconPosition?: 'left' | 'right' = 'left';

  @HostBinding('class.horizontal')
  get horizontal(): boolean {
    return (this.display && this.display === 'horizontal');
  }

  @HostBinding('class.icon-left')
  get left(): boolean {
    return this.iconPosition === 'left';
  }

  @HostBinding('class.icon-right')
  get right(): boolean {
    return (!this.iconPosition || this.iconPosition === 'right');
  }

  @HostBinding('class.vertical')
  get vertical(): boolean {
    return (this.display && this.display === 'vertical');
  }
}
