import { alias, deserialize, object, serializable } from 'serializr';
import { CancellationUserEntity } from './cancellation-user.entity';
import { MembershipUser } from '../types/membership-user.interface';

export type CancellationState =
  | 'aborted'
  | 'awaiting_cancellation'
  | 'cancelled'
  | 'transferred_to_other_lo_union'
  | 'processing'
  | 'submitted'
  | 'transferred_to_other_lo_union,cancelled,aborted,awaiting_cancellation';

export type CancellationTransition =
  | 'no_transition'
  | 'to_awaiting_cancellation_and_transferred_to_other_lo_union'
  | 'to_awaiting_cancellation'
  | 'to_aborted'
  | 'to_processing';

export class CancellationStatesEntity {
  @serializable(alias('type'))
  private _type: CancellationState;

  @serializable(alias('user', object(CancellationUserEntity)))
  private _user: CancellationUserEntity;

  static simpleStates: CancellationState[] = [
    'submitted',
    'processing',
    'transferred_to_other_lo_union,cancelled,aborted,awaiting_cancellation',
  ];

  static stateLabels = new Map<CancellationState, { name: string }>([
    ['submitted', { name: 'Ubehandlet' }],
    ['transferred_to_other_lo_union', { name: 'Overført' }],
    ['awaiting_cancellation', { name: 'Til utmelding' }],
    ['cancelled', { name: 'Utmeldt' }],
    ['aborted', { name: 'Valgte å bli' }],
    ['processing', { name: 'Under behandling' }],
    [
      'transferred_to_other_lo_union,cancelled,aborted,awaiting_cancellation',
      { name: 'Ferdig behandlet' },
    ],
  ]);

  static transitionLabels = new Map<CancellationTransition, { name: string }>([
    ['to_processing', { name: 'Til behandling' }],
    [
      'to_awaiting_cancellation_and_transferred_to_other_lo_union',
      { name: 'Overført til annet LO-forbund' },
    ],
    ['to_awaiting_cancellation', { name: 'Til utmelding' }],
    ['to_aborted', { name: 'Valgt å bli' }],
  ]);

  private static _stateToTransition = new Map<
    CancellationState,
    CancellationTransition
  >([
    ['submitted', 'no_transition'],
    ['aborted', 'to_aborted'],
    [
      'transferred_to_other_lo_union',
      'to_awaiting_cancellation_and_transferred_to_other_lo_union',
    ],
    ['awaiting_cancellation', 'to_awaiting_cancellation'],
  ]);

  get type() {
    return this._type;
  }

  public get user(): CancellationUserEntity {
    return this._user;
  }

  set user(value: MembershipUser | CancellationUserEntity) {
    if (value instanceof CancellationUserEntity) {
      this._user = value;
      return;
    }
    this._user = deserialize(CancellationUserEntity, value);
  }

  get name() {
    return (
      CancellationStatesEntity.stateLabels.get(this._type)?.name || 'Ukjent'
    );
  }

  get transitionType() {
    return CancellationStatesEntity._stateToTransition.get(this._type)!;
  }

  get transitionName() {
    return CancellationStatesEntity.transitionLabels.get(this.transitionType);
  }
}
