import { Highlightable, ListKeyManagerOption } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';


@Component({
  selector: 'app-list-item',
  template: '<ng-content></ng-content>'
})

/**
 * Used by dropdown, typeahead etc. to make result lists
 */
export class ListItemComponent implements Highlightable, ListKeyManagerOption {
  @Input()
  item: object;

  @Output()
  readonly ffOptionSelect = new EventEmitter<any>();

  @Input()
  label: string;

  disabled: boolean;

  private _isActive = false;

  constructor(
    private _el: ElementRef
  ) {}

  @HostBinding('class.active')
  get isActive() {
    return this._isActive;
  }

  /**
   * @returns {number}
   */
  get scrollHeight(): number {
    return this._el.nativeElement.scrollHeight ? this._el.nativeElement.scrollHeight : this._el.nativeElement.offsetHeight;
  }

  @HostBinding('tabindex')
  get tabIndex() {
    return '-1';
  }

  getLabel(): string {
    return this.label || '';
  }

  /**
   *
   */
  setActiveStyles() {
    this._isActive = true;
  }

  /**
   *
   */
  setInactiveStyles() {
    this._isActive = false;
  }
}
