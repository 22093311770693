export class StringHelper {
  /**
   *
   */
  static firstCharIsUpper(str: string): boolean | null {
    if (!str) {
      return null;
    }

    const firstChar = str.charAt(0);
    return (firstChar === firstChar.toLocaleUpperCase() && firstChar !== firstChar.toLocaleLowerCase());
  }

  /**
   *
   */
  static generateId(): string {
    return Math.random().toString(36).substring(2);
  }

  /**
   *
   */
  static parsePlaceholders(haystack: string, replacements: {}): string {
    let response = haystack;

    Object.keys(replacements).forEach((placeholder) => {
      response = haystack.replace(placeholder, replacements[placeholder]);
    });

    return response;
  }

  /**
   * Convert to PascalCase for every word in string
   */
  static toPascalCase(str: string): string {
    return `${str}`
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
        new RegExp(/\s+(.)(\w*)/, 'g'),
        ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
      )
      .replace(new RegExp(/\w/), s => s.toUpperCase());
  }

  /**
   *
   */
  static ucFirst(str: string, lowercaseRest = false): string {
    const rest = lowercaseRest ? str.slice(1).toLowerCase() : str.slice(1);
    return !str ? '' : str.charAt(0).toUpperCase() + rest;
  }

  /**
   *
   */
  static verifyOnlyIntegers(str: string): boolean {
    return RegExp(/^[0-9]+$/).test(str);
  }
}
