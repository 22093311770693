import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DistributionInvoiceService } from '@modules/distribution/services/distribution-invoice.service';
import {
  DistributionInvoiceBatchDetailModalDataInterface,
} from '@shared/components/modal/content/batch-detail-modal/distribution-invoice-batch-detail-modal-data.interface';
import { ModalBaseClassDirective } from '@shared/components/modal/content/modal-base.class';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-distribution-invoice-batch-detail-modal',
  templateUrl: './distribution-invoice-batch-detail-modal.component.html',
  styleUrls: ['./distribution-invoice-batch-detail-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributionInvoiceBatchDetailModalComponent extends ModalBaseClassDirective {

  @Input()
  override data: DistributionInvoiceBatchDetailModalDataInterface;

  constructor(
    private _distributionInvoiceService: DistributionInvoiceService
  ) {
    super();
  }

  downloadLedger(): void {
    this._distributionInvoiceService.getInvoiceBatchesLedgerPdf(this.data.batch.uuid).pipe(
      tap((url: string) => {
        window.open(url, '_blank');
      })
    ).subscribe();
  }

  downloadImportFile(): void {
    this._distributionInvoiceService.getInvoiceBatchesImportFile(this.data.batch.uuid).pipe(
      tap((url: string) => {
        window.open(url, '_blank');
      })
    ).subscribe();
  }
}
