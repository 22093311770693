import { InjectionToken } from '@angular/core';


/**
 * Extend to support other platforms if needed
 */
export class WindowRefHelper {
  static WINDOW = new InjectionToken<Window>(
    'WindowToken',
    typeof window !== 'undefined' && window.document ? { providedIn: 'root', factory: () => window } : undefined
  );
  /**
   *
   */
  static getNativeWindow(): Window {
    return window;
  }
}
