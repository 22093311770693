import { StrikelogUnionEntity } from '@modules/strikelog/entities/strikelog-union.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { alias, date, identifier, list, object, serializable } from 'serializr';


export class StrikelogCommitteeEntity {
  @serializable(alias('createdAt', date()))
  private _createdAt?: Date;

  @serializable(alias('leader', object(StrikelogUserEntity)))
  private _leader?: StrikelogUserEntity;

  @serializable(alias('memberCount'))
  private _memberCount: string;

  @serializable(alias('members', list(object(StrikelogUserEntity))))
  private _members: StrikelogUserEntity[] = [];

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('unions', list(object(StrikelogUnionEntity)),))
  private _unions: StrikelogUnionEntity[] = [];

  @serializable(alias('updatedAt', date()))
  private _updatedAt?: Date;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;


  get createdAt(): Date | undefined {
    return this._createdAt;
  }

  set createdAt(value: Date | undefined) {
    this._createdAt = value;
  }

  get leader(): StrikelogUserEntity | undefined {
    return this._leader;
  }

  set leader(value: StrikelogUserEntity | undefined) {
    this._leader = value;
  }

  get memberCount(): string {
    return this._memberCount;
  }

  set memberCount(value: string) {
    this._memberCount = value;
  }

  get members(): StrikelogUserEntity[] {
    return this._members;
  }

  set members(value: StrikelogUserEntity[]) {
    this._members = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get unions(): StrikelogUnionEntity[] {
    return this._unions;
  }

  set unions(value: StrikelogUnionEntity[]) {
    this._unions = value;
  }

  get updatedAt(): Date | undefined {
    return this._updatedAt;
  }

  set updatedAt(value: Date | undefined) {
    this._updatedAt = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
