import { alias, list, object, serializable } from 'serializr';
import { CancellationReasonKey } from '../types/CancellationReasonKey.type';

export class CancellationChildReasonsEntity {
  @serializable(alias('key'))
  private _key: CancellationReasonKey;

  @serializable(alias('value'))
  private _value: boolean;

  get key() {
    return this._key;
  }

  get value() {
    return this._value;
  }

  get label() {
    return CancellationReasonsEntity.ReasonLabels.get(this.key);
  }
}

export class CancellationReasonsEntity {
  @serializable(alias('key'))
  private _key: CancellationReasonKey;

  @serializable(
    alias('childReasons', list(object(CancellationChildReasonsEntity)))
  )
  private _childReasons?: CancellationChildReasonsEntity[] = [];

  @serializable(alias('value'))
  private _value?: any;

  get key() {
    return this._key;
  }

  get childReasons() {
    return this._childReasons;
  }

  get value() {
    return this._value;
  }

  get label() {
    return CancellationReasonsEntity.ReasonLabels.get(this._key);
  }

  static ReasonLabels = new Map<CancellationReasonKey, string>([
    ['POOR_MEMBERSHIP_OFFERS', 'Misfornøyd med medlemstilbudet'],
    ['POOR_MEMBERSHIP_OFFERS_INSURANCE', 'Forsikringer'],
    ['POOR_MEMBERSHIP_OFFERS_SCHOLARSHIP', 'Stipendordning'],
    ['POOR_MEMBERSHIP_OFFERS_VOCATIONAL_OFFER', 'Yrkesfaglig tilbud'],
    ['POOR_MEMBERSHIP_OFFERS_RECRUITMENT_PRIZES', 'Vervepremier'],
    ['POOR_MEMBERSHIP_OFFERS_OTHER', 'Annet'],
    ['DISSATISFIED_WITH_FAGFORBUNDET', 'Misfornøyd med Fagforbundet'],
    ['DISSATISFIED_WITH_FAGFORBUNDET_POLITICS', 'Fagforbundets politikk'],
    ['DISSATISFIED_WITH_FAGFORBUNDET_FEES', 'Fagforbundets kontingent'],
    [
      'DISSATISFIED_WITH_FAGFORBUNDET_SALARY_NEGOTIATIONS',
      'Lønnsforhandlingene',
    ],
    ['DISSATISFIED_WITH_FAGFORBUNDET_OTHER', 'Annet'],
    ['POOR_SERVICE_OFFERS', 'Misfornøyd med servicetilbudet'],
    [
      'POOR_SERVICE_OFFERS_NON_ANSWERED_INQUIRIES',
      'Manglende oppfølging på henvendelser',
    ],
    [
      'POOR_SERVICE_OFFERS_POOR_FOLLOW_UP',
      'Manglende oppfølging fra min lokale fagforening',
    ],
    [
      'POOR_SERVICE_OFFERS_DISSATISFIED_WITH_SHOP_STEWARDS',
      'Manglende oppfølging fra min tillitsvalgt',
    ],
    ['POOR_SERVICE_OFFERS_OTHER', 'Annet'],
    ['JOINING_OTHER_LO_UNION', 'Melder meg inn i annet LO-forbund'],
    ['JOINING_OTHER_EXTERNAL_UNION', 'Melder meg inn i annet forbund'],
    ['CHANGING_JOBS', 'Bytter jobb'],
    ['OTHER', 'Annet'],
  ]);
}
