import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AdminWebUserEntity } from '@core/entities/user/admin-web-user.entity';
import { AuthService } from '@core/services/auth/auth.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { tap } from 'rxjs/operators';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  user: AdminWebUserEntity|undefined;

  /**
   *
   */
  constructor(private _oidcSecurityService: OidcSecurityService,
              private _authService: AuthService) { }

  /**
   *
   */
  logout(): void {
    this._oidcSecurityService.logoff().subscribe();
  }

  /**
   *
   */
  ngOnInit(): void {
    this._authService.getSelf().pipe(
      tap((user: AdminWebUserEntity) => {
        this.user = user;
      })
    ).subscribe();
  }
}
