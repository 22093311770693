import { StrikelogCommitteeEntity } from '@modules/strikelog/entities/strikelog-committee.entity';
import { StrikelogConflictEntity } from '@modules/strikelog/entities/strikelog-conflict.entity';
import { StrikelogDailyReportEntity } from '@modules/strikelog/entities/strikelog-daily-report.entity';
import { StrikelogExemptionApplicationEntity } from '@modules/strikelog/entities/strikelog-exemption-application.entity';
import { StrikelogUserRoleEntity } from '@modules/strikelog/entities/strikelog-user-role.entity';


/**
 * This class is only for the Strikelog module
 * @see STRIKELOG_USER_ENTITY_SCHEMA
 *
 * For AdminWeb authenticated user, see:
 * @see AdminWebUserEntity
 * @see AuthService.getSelf
 */
export class StrikelogUserEntity {
  private _committee?: StrikelogCommitteeEntity;
  private _dailyReports?: StrikelogDailyReportEntity[] = [];
  private _emailAddress: string;
  private _exemptionApplications: StrikelogExemptionApplicationEntity[] = [];
  private _fane2PersonId: string;
  private _firstName: string;
  private _idProviderUuid: string;
  private _lastName: string;
  private _organizedConflicts: StrikelogConflictEntity[] = [];
  private _participatedConflicts: StrikelogConflictEntity[] = [];
  private _roles: StrikelogUserRoleEntity[] = [];
  private _uuid: string;


  get committee(): StrikelogCommitteeEntity | undefined {
    return this._committee;
  }

  set committee(value: StrikelogCommitteeEntity | undefined) {
    this._committee = value;
  }

  get dailyReports(): StrikelogDailyReportEntity[] {
    return this._dailyReports ?? [];
  }

  set dailyReports(value: StrikelogDailyReportEntity[]) {
    this._dailyReports = value;
  }

  get emailAddress(): string {
    return this._emailAddress;
  }

  set emailAddress(value: string) {
    this._emailAddress = value;
  }

  get exemptionApplications(): StrikelogExemptionApplicationEntity[] {
    return this._exemptionApplications;
  }

  set exemptionApplications(value: StrikelogExemptionApplicationEntity[]) {
    this._exemptionApplications = value;
  }

  get fane2PersonId(): string {
    return this._fane2PersonId;
  }

  set fane2PersonId(value: string) {
    this._fane2PersonId = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get idProviderUuid(): string {
    return this._idProviderUuid;
  }

  set idProviderUuid(value: string) {
    this._idProviderUuid = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get organizedConflicts(): StrikelogConflictEntity[] {
    return this._organizedConflicts;
  }

  set organizedConflicts(value: StrikelogConflictEntity[]) {
    this._organizedConflicts = value;
  }

  get participatedConflicts(): StrikelogConflictEntity[] {
    return this._participatedConflicts;
  }

  set participatedConflicts(value: StrikelogConflictEntity[]) {
    this._participatedConflicts = value;
  }

  get roles(): StrikelogUserRoleEntity[] {
    return this._roles;
  }

  set roles(value: StrikelogUserRoleEntity[]) {
    this._roles = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
