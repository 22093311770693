import { membershipApiTargets } from '@config/apis/membership/membership-api-targets';
import { notificationApiTargets } from '@config/apis/notification/notification-api-targets';
import { distributionApi } from '@environments/apis/distribution.api';
import { employerApi } from '@environments/apis/employer.api';
import { filesApi } from '@environments/apis/files.api';
import { idApi } from '@environments/apis/id.api';
import { proxyApi } from '@environments/apis/proxy.api';
import { strikelogApi } from '@environments/apis/strikelog.api';
import { AvailableApisType } from '@environments/assets/available-apis.type';
import { EnvironmentTypeEnum } from '@environments/assets/environment-type.enum';
import { EnvironmentInterface } from '@environments/assets/environment.interface';

/**
 * This file will override environment.ts if Angular is served with a specific --configuration parameter
 * See file angular.json in root folder, ref its section:
 * "configurations": { "myEnvOverride": { "fileReplacements": []}}
 */
export const environment = {
  APIS: {
    distribution: distributionApi.staging,
    files: filesApi.staging,
    id: idApi.staging,
    proxy: proxyApi.staging,
    strikelog: strikelogApi.staging,
    membership: membershipApiTargets.staging,
    notification: notificationApiTargets.staging,
    employer: employerApi.staging,
  } satisfies Record<AvailableApisType, EnvironmentInterface>,
  clients: {
    min_side: 'https://minside.fagforbundet.dev',
  },
  circularLetterRootFolderUuid: '3adb63ad-eedd-4bdd-833d-6e33d2bd0fa5',
  debugOIDC: false,
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm',
  },
  frontend: {
    baseUrl: 'https://admin.fagforbundet.dev',
    redirectUrl: 'https://admin.fagforbundet.dev/login',
    postLogoutRedirectUri: 'https://fagforbundet.no',
  },
  idFrontend: {
    baseUrl: 'https://id.fagforbundet.dev',
    emailVerificationRoute: '/verification/email'
  },
  type: EnvironmentTypeEnum.STAGING,
  useMocks: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
