import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';


/**
 *
 */
export class FileUploadEntity {
  readonly file: File;
  readonly progress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  status: FileUploadStatus = 'selected';

  constructor(file, progress = new BehaviorSubject<number>(0)) {
    this.file = file;
    this.progress = progress;
  }

  /**
   *
   */
  getFile(): File {
    return this.file;
  }

  /**
   *
   */
  getProgress(): Observable<number> {
    return this.progress.asObservable();
  }

  /**
   *
   */
  updateProgress(percent: number): void {
    this.progress.next(percent);
  }

  /**
   *
   */
  setFailed(): void {
    this.status = 'failed';
    this.progress.complete();
  }

  /**
   *
   */
  setSucceeded(): void {
    this.status = 'succeeded';
    this.progress.complete();
  }

  /**
   *
   */
  setError(event: HttpEvent<unknown> | HttpErrorResponse): void {
    this.status = 'failed';
    this.progress.error(event);
  }
}

export type FileUploadStatus =
  | 'selected'
  | 'uploading'
  | 'succeeded'
  | 'failed'
;
