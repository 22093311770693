import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUploadOptionsInterface } from '@shared/components/file-upload/assets/file-upload-options.interface';
import { FileUploadEntity } from '@shared/components/file-upload/assets/file-upload.entity';
import { UploadResponse } from '@shared/components/file-upload/file-upload.component';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class FileUploadService {
  constructor(
    private _httpClient: HttpClient,
  ) {}

  upload(fileUpload: FileUploadEntity, config: FileUploadOptionsInterface, body?: HttpParams): Observable<UploadResponse> {
    if (config.apiRequest.method == null) {
      return throwError(() => 'Request missing method');
    }

    if (config.apiRequest.url == null) {
      return throwError(() => 'Request missing url');
    }

    const formData: FormData = new FormData();
    formData.append('file', fileUpload.file);

    if (body != null) {
      body.keys().forEach((key) => {
        const value = body.get(key);
        if(value){
          formData.append(key, value);
        }
      });
    }

    fileUpload.status = 'uploading';

    return this._httpClient.request(
      config.apiRequest.method,
      config.apiRequest.url,
      {
        body: formData,
        headers: config.apiRequest.headers,
        observe: 'events',
        params: config.apiRequest.params,
        reportProgress: true,
        responseType: config.apiRequest.responseType,
        withCredentials: config.apiRequest.withCredentials,
      }
    ).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          fileUpload.status = 'uploading';
          fileUpload.updateProgress(Math.round((event.loaded / event.total) * 100));
        } else if (event.type === HttpEventType.Response) {
          if (event.status === 200 || event.status === 201) {
            fileUpload.setSucceeded();
          } else {
            fileUpload.setFailed();
          }
        }
      }),
      catchError((e) => {
        console.log(e);
        fileUpload.setError(e);
        return throwError(() => e);
      })
    );
  }
}
