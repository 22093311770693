import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployerUser } from '@core/entities/employer/employer-user/employer-user.interface';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  private self$!: Observable<EmployerUser>;

  constructor(
    private readonly _httpClient: HttpClient,
  ) {}

  /**
   *
   */
  getSelfCached(): Observable<EmployerUser> {
    if (!this.self$) {
      this.self$ = this._httpClient
        .get<{ user: EmployerUser }>(
          EnvironmentHelper.fetchAPIBase('employer') + '/v1/users/self'
        )
        .pipe(
          map((response) => response.user),
          shareReplay(1)
        );
    }
    return this.self$;
  }
}
