import { alias, date, identifier, serializable } from 'serializr';


export class StrikelogUserRoleEntity {
  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get uuid(): string {
    return this._uuid;
  }
}
