import { alias, date, identifier, serializable } from 'serializr';


export class StrikelogCompetenceCenterEntity {
  @serializable(alias('emailAddress'))
  private _emailAddress: string;

  @serializable(alias('name'))
  private _name: number;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;


  get emailAddress(): string {
    return this._emailAddress;
  }

  set emailAddress(value: string) {
    this._emailAddress = value;
  }

  get name(): number {
    return this._name;
  }

  set name(value: number) {
    this._name = value;
  }

  get uuid(): string {
    return this._uuid;
  }
}
