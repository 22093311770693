import { StrikelogConflictStatus } from '@modules/strikelog/constants/strikelog-conflict-api-status.params';
import { StrikelogProcessorGroupEnum } from '@modules/strikelog/constants/strikelog-processor-group.enum';
import { StrikelogAttachmentEntity } from '@modules/strikelog/entities/strikelog-attachment.entity';
import { StrikelogCommitteeEntity } from '@modules/strikelog/entities/strikelog-committee.entity';
import { StrikelogConflictEntity } from '@modules/strikelog/entities/strikelog-conflict.entity';
import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { StrikelogEmployerWorkplaceEntity } from '@modules/strikelog/entities/strikelog-employer-workplace.entity';
import { StrikelogEmployerEntity } from '@modules/strikelog/entities/strikelog-employer.entity';
import {
  StrikelogExemptionApplicationDecisionEntity,
} from '@modules/strikelog/entities/strikelog-exemption-application-decision.entity';
import {
  StrikelogExemptionApplicationStatisticsEntity,
} from '@modules/strikelog/entities/strikelog-exemption-application-statistics.entity';
import { getTypedStrikelogConflictStatus } from '@modules/strikelog/entities/strikelog-model-schema.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { noop } from 'rxjs';
import { alias, custom, date, list, object, serializable } from 'serializr';


export class StrikelogExemptionApplicationEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('applicationDate', date()))
  private _applicationDate: Date;

  @serializable(alias('applicationText'))
  private _applicationText: string;

  @serializable(alias('exemptionApplicationFiles', list(object(StrikelogAttachmentEntity))))
  private _attachments?: StrikelogAttachmentEntity[] = [];

  @serializable(alias('localStrikeCommittee', object(StrikelogCommitteeEntity)))
  private _committee?: StrikelogCommitteeEntity;

  @serializable(alias('conflict', object(StrikelogConflictEntity)))
  private _conflict?: StrikelogConflictEntity;

  @serializable(alias('conflictStatus', custom(noop, (val) => getTypedStrikelogConflictStatus(val))))
  private _conflictStatus?: StrikelogConflictStatus;

  @serializable(alias('creator', object(StrikelogUserEntity)))
  private _creator?: StrikelogUserEntity;

  @serializable(alias('exemptionApplicationDecision', object(StrikelogExemptionApplicationDecisionEntity)))
  private _decision?: StrikelogExemptionApplicationDecisionEntity;

  @serializable(alias('employer', object(StrikelogEmployerEntity)))
  private _employer?: StrikelogEmployerEntity;

  @serializable(alias('exempted'))
  private _exempted: number;

  @serializable(alias('processor'))
  private _processor: StrikelogProcessorGroupEnum;

  @serializable(alias('statistics', object(StrikelogExemptionApplicationStatisticsEntity)))
  private _statistics?: StrikelogExemptionApplicationStatisticsEntity;

  @serializable(alias('workplace', object(StrikelogEmployerWorkplaceEntity)))
  private _workplace?: StrikelogEmployerWorkplaceEntity;


  get applicationDate(): Date {
    return this._applicationDate;
  }

  set applicationDate(value: Date) {
    this._applicationDate = value;
  }

  get applicationText(): string {
    return this._applicationText;
  }

  set applicationText(value: string) {
    this._applicationText = value;
  }

  get attachments(): StrikelogAttachmentEntity[] {
    return this._attachments ?? [];
  }

  set attachments(value: StrikelogAttachmentEntity[]) {
    this._attachments = value;
  }

  get creator(): StrikelogUserEntity | undefined {
    return this._creator;
  }

  set creator(value: StrikelogUserEntity | undefined) {
    this._creator = value;
  }

  get committee(): StrikelogCommitteeEntity | undefined {
    return this._committee;
  }

  set committee(value: StrikelogCommitteeEntity | undefined) {
    this._committee = value;
  }

  get conflict(): StrikelogConflictEntity | undefined {
    return this._conflict;
  }

  set conflict(value: StrikelogConflictEntity | undefined) {
    this._conflict = value;
  }

  get conflictStatus(): StrikelogConflictStatus | undefined {
    return this._conflictStatus;
  }

  set conflictStatus(value: StrikelogConflictStatus | undefined) {
    this._conflictStatus = value;
  }

  get decision(): StrikelogExemptionApplicationDecisionEntity | undefined {
    return this._decision;
  }

  set decision(value: StrikelogExemptionApplicationDecisionEntity | undefined) {
    this._decision = value;
  }

  get employer(): StrikelogEmployerEntity | undefined {
    return this._employer;
  }

  set employer(value: StrikelogEmployerEntity | undefined) {
    this._employer = value;
  }

  get exempted(): number {
    return this._exempted;
  }

  set exempted(value: number) {
    this._exempted = value;
  }

  get processor(): StrikelogProcessorGroupEnum {
    return this._processor;
  }

  set processor(value: StrikelogProcessorGroupEnum) {
    this._processor = value;
  }

  get statistics(): StrikelogExemptionApplicationStatisticsEntity | undefined {
    return this._statistics;
  }

  set statistics(value: StrikelogExemptionApplicationStatisticsEntity | undefined) {
    this._statistics = value;
  }

  get workplace(): StrikelogEmployerWorkplaceEntity | undefined {
    return this._workplace;
  }

  set workplace(value: StrikelogEmployerWorkplaceEntity | undefined) {
    this._workplace = value;
  }
}
