import { ValidationViolationResponseEntity } from '@core/entities/response/validation-violation-response.entity';
import { alias, list, object, primitive, serializable } from 'serializr';


export class SsnValidationResponseEntity {
  @serializable(alias('status', primitive()))
  private _status: number;

  @serializable(alias('valid', primitive()))
  private _valid: boolean;

  @serializable(alias('violations', list(object(ValidationViolationResponseEntity))))
  private _violations: ValidationViolationResponseEntity[] = [];

  get status(): number {
    return this._status;
  }

  set status(value: number) {
    this._status = value;
  }

  get valid(): boolean {
    return this._valid;
  }

  set valid(value: boolean) {
    this._valid = value;
  }

  get violations(): ValidationViolationResponseEntity[] {
    return this._violations;
  }

  set violations(value: ValidationViolationResponseEntity[]) {
    this._violations = value;
  }
}
