import { Component, DoCheck, HostBinding, Input, IterableDiffer, IterableDiffers } from '@angular/core';
import { FeedbackMessageTypesEnum } from '@shared/components/feedback-messages/feedback-message-types.enum';
import { FeedbackMessageEntity } from '@shared/components/feedback-messages/feedback-message.entity';


@Component({
  selector: 'app-feedback-messages',
  templateUrl: './feedback-messages.component.html',
  styleUrls: ['./feedback-messages.component.scss'],
})
export class FeedbackMessagesComponent implements DoCheck {
  @Input()
  messages: FeedbackMessageEntity[] = [];

  @HostBinding('style.display')
  display: string = 'none';

  private _iterableDiffer: IterableDiffer<FeedbackMessageEntity>;

  constructor(
    private _iterableDiffers: IterableDiffers,
  ) {
    this._iterableDiffer = this._iterableDiffers.find([]).create(undefined);
  }

  get errors(): FeedbackMessageEntity[] {
    return this.messages.filter((uf) => uf.type === FeedbackMessageTypesEnum.ERROR);
  }

  get informatives(): FeedbackMessageEntity[] {
    return this.messages.filter((uf) => uf.type === FeedbackMessageTypesEnum.INFORMATIVE);
  }

  /**
   *
   */
  ngDoCheck(): void {
    const changes = this._iterableDiffer.diff(this.messages);

    if (changes) {
      this.display = this.messages.length > 0 ? 'block' : 'none';
    }
  }
}
