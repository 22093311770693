import { environment } from '@environments/environment';
import { createApiConfiguration } from '@fagforbundet/ngx-components';
import { MembershipApiEndpoints } from '@config/apis/membership/membership-api-endpoints';

export const membershipApiConfig = createApiConfiguration<
  typeof MembershipApiEndpoints
>({
  baseUrl: environment.APIS.membership.baseUrl,
  endpoints: MembershipApiEndpoints,
});
