import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { alias, serializable } from 'serializr';


export class StrikelogEmployerWorkplaceEntity extends StrikelogDefaultBaseEntity {
  @serializable(alias('fane2Id'))
  private _fane2Id: number;

  @serializable(alias('name'))
  private _name: string;


  get fane2Id(): number {
    return this._fane2Id;
  }

  set fane2Id(value: number) {
    this._fane2Id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
