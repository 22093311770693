@if (processing) {
  <app-loading-indicator
    class="loading-indicator"
    stroke="#3c3c3b"
    size="s24"
  ></app-loading-indicator>
}
@if (!processing) {
  <label class="switch" [class.focused]="focused">
    <input
      [attr.id]="inputId"
      [formControl]="formControl"
      (change)="changed.emit(formControl.value)"
      (focusin)="focused = true"
      (focusout)="focused = false"
      type="checkbox"
      />
    <span class="slider round"></span>
  </label>
}
<label [attr.for]="inputId">
  @if (formControl.value) {
    <ng-content select=".active-label"></ng-content>
  }
  @if (!formControl.value) {
    <ng-content select=".inactive-label"></ng-content>
  }
  <ng-content></ng-content>
</label>
