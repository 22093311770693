import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DistributionRecipientListEntity } from '@core/entities/distribution/distribution-recipient-list.entity';
import { ObjectHelper } from '@core/helpers/object.helper';
import { StringHelper } from '@core/helpers/string.helper';
import { RecipientListSectionsEnum } from '@modules/distribution/constants/recipient-list-sections.enum';
import { RecipientListTypesEnum } from '@modules/distribution/constants/recipient-list-types.enum';

function spanToText (content: string, attributes: { [prop: string]: string }) {
  const el = document.createElement('span');
  Object.entries(attributes).forEach(([attr, value]) => el.setAttribute(attr, value));
  el.textContent = content;
  return el.outerHTML;
}

/**
 * Returns html, so use with inner or outer html:
 * <div [innerHTML]="recipientList | distributionRecipientListType"></div>
 */
@Pipe({
  name: 'distributionRecipientListType',
  pure: false,
})
export class DistributionRecipientListTypePipe implements PipeTransform {
  constructor(
    private readonly _sanitizer: DomSanitizer,
  ) {
  }

  transform(distributionRecipientList: DistributionRecipientListEntity): SafeHtml {
    let type = RecipientListTypesEnum[distributionRecipientList.type] as string;

    // Find all {handlebars} (including their curly braces)
    type.match(/\{.+?}/g)?.forEach((handleBar: string) => {
      let replacement: string | boolean = false;
      let title = 'Ukjent plassholder';

      // Get the content of each handlebar (excluding curly braces)
      //   Since Safari 16 doesn't support lookbehind regexes, we have several matching groups and use only index [2]
      const placeholder = handleBar.match(/([{])(.+?)(?=})/)?.[2];

      if (placeholder == null) return;

      const mapping = RecipientListPlaceholderMappings[placeholder];

      if (mapping) {
        replacement = ObjectHelper.getNestedPropertyAsString(mapping.split('.'), distributionRecipientList);
      }

      if (replacement && typeof replacement === 'string') {
        let response;

        if (placeholder === 'seksjon') {
          response = replacement.toUpperCase();
          title = StringHelper.ucFirst(placeholder) + ': ' +
            RecipientListSectionsEnum[replacement] + ' (' + response  + ')' ;
        } else {
          const words = replacement.split(' ');
          words.map((r, idx) => {
            words[idx] = StringHelper.ucFirst(r, true)
          });
          response = words.join(' ');

          title = StringHelper.ucFirst(placeholder) + ': ' + response;
        }

        type = type.replace(handleBar,
          spanToText(response, { title, class: 'replaced' }));
      } else {
        type = type.replace(handleBar,
          spanToText(handleBar, { title, class: 'placeholder' }));
      }
    });

    return this._sanitizer.sanitize(SecurityContext.HTML, '<span class="recipient-list-type">' + type + '</span>') as SafeHtml;
  }
}

/**
 * Keys must match {handlebar} placeholders in
 * @see RecipientListTypesEnum
 * Values must match properties (getters) of
 * @see DistributionRecipientListEntity
 */
enum RecipientListPlaceholderMappings {
  arbeidsplass = 'workplace.name',
  fagforening = 'departmentUnion.name',
  'lokalt arbeidssted' = 'localWorkplace.name',
  region = 'countyUnion.name',
  seksjon = 'section',
  tariffavtale = 'agreement.name',
}
