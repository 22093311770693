import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';


export enum ProxyEndpointsEnum {
  DELETE_FORWARDING_EMAIL = '/v1/forwarding-emails/:emailUuid',
  GET_AGREEMENTS = '/v1/agreements',
  GET_EXTERNAL_ORGANIZATIONS = '/v1/external-organizations',
  GET_FORWARDING_EMAILS = '/v1/forwarding-emails',
  GET_ORGANIZATIONAL_UNITS = '/v1/organizational-units',
  GET_SECTIONS = '/v1/employments/sections',
  POST_FORWARDING_EMAIL = '/v1/forwarding-emails',
}

export const proxyApi: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://api.proxy.fagforbundet.no',
  },
  staging: {
    baseUrl: 'https://api.proxy.fagforbundet.dev',
  },
};
