import { StrikelogDefaultBaseEntity } from '@modules/strikelog/entities/strikelog-default-base.entity';
import { alias, serializable } from 'serializr';


export class StrikelogAttachmentEntity extends StrikelogDefaultBaseEntity {

  @serializable(alias('extension'))
  private _extension: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('size'))
  private _size: number;

  get extension(): string {
    return this._extension;
  }

  set extension(value: string) {
    this._extension = value;
  }

  get size(): number {
    return this._size;
  }

  set size(value: number) {
    this._size = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}
