<div
  (mouseenter)="active=true"
  (mouseleave)="active=false"
  class="trigger-wrap"
  [tabindex]="0"
  >
  <div class="trigger">
    @if (triggerIcon) {
      <svg-icon
        [applyClass]="true"
        [class.active]="active || menuOpen"
        [src]="triggerIcon"
        [svgStyle]="{'height.px': triggerIconSize.toString(),'width.px': triggerIconSize.toString()}"
        class="trigger__icon icon"
      ></svg-icon>
    }

    @if (triggerText) {
      <div class="trigger__text">
        {{ triggerText }}
      </div>
    }

    @if (triggerButton) {
      <app-button
        (clicked)="toggleMenu()"
        [color]="triggerButtonIconColor"
        [iconPath]="triggerButton"
        [iconSize]="triggerButtonIconSize"
        [tabindex]="-1"
        class="trigger__button {{buttonClassList}}"
      ></app-button>
    }
  </div>
</div>

<div #menu aria-live="polite" class="menu {{arrowPosition}}" [class.menu--visible]="menuOpen" role="list">
  <div class="menu__arrow">
    <ng-content select="app-menu-item"></ng-content>
  </div>
</div>
