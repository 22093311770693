import { Component, HostBinding, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  /**
   * Displays as text under label
   */
  @Input()
  descriptionText?: string;

  @Input()
  disabled?: boolean = false;

  @Input()
  formCtrl: FormControl;

  /**
   * Displays in a tooltip on question mark icon placed at end of label
   */
  @Input()
  helpText?: string;

  @Input()
  labelText?: string;

  @HostBinding('attr.role')
  role: string = 'radio';

  @Input()
  validated: boolean = false;

  @Input()
  value: string;

  form: FormGroup;

  constructor(
    private _fb: FormBuilder
  ) { }

  /**
   *
   */
  toggle() {
    if (!this.disabled) {
      this.formCtrl.setValue(this.value);
    }
  }
}
