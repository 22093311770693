<ng-content select="input"></ng-content>

<div class="buttons">

  @if (exclude !== 'accept') {
    <app-button
      (clicked)="accept()"
      [iconSize]="20"
      [processing]="!!processing"
      [title]="labelAccept"
      class="buttons__button accept inline"
      color="green"
      data-cy="input-accept"
      iconPath="assets/icons/check_mark.svg"
      [tabindex]="0"
    ></app-button>
  }

  @if (exclude !== 'cancel') {
    <app-button
      (clicked)="cancel()"
      [iconSize]="20"
      [processing]="!!processing"
      [title]="labelCancel"
      class="buttons__button cancel inline"
      color="sand"
      data-cy="input-cancel"
      iconPath="assets/icons/x.svg"
      [tabindex]="0"
    ></app-button>
  }

</div>
