import { list, object, serializable } from "serializr"
import { CancellationStatesEntity } from "./cancellation-states.entity";

export class CancellationPostStateRequestEntity {
    @serializable
    uuid: string;

    @serializable(list(object(CancellationStatesEntity)))
    states: CancellationStatesEntity[] = []
}
