import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NavigationMenuEntity} from '@shared/components/navigation-menu/navigation-menu.entity';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationMenuComponent {
  @Input()
  menuItems: NavigationMenuEntity[] = [];
}
