<nav class="breadcrumbs">
  <span
    *ngFor="let crumb of breadcrumbs$ | async as breadcrumbs; index as i"
    class="crumb"
    >
    @if (i + 1 !== breadcrumbs.length) {
      <a [routerLink]="[crumb.url]">{{
        crumb.label
      }}</a>
    }

    @if (i + 1 === breadcrumbs.length) {
      <span>{{ crumb.label }}</span>
    }
  </span>
</nav>
