import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accept-cancel-input',
  templateUrl: './accept-cancel-input.component.html',
  styleUrls: ['./accept-cancel-input.component.scss'],
})
export class AcceptCancelInputComponent implements OnChanges {
  @Input()
  exclude?: 'accept' | 'cancel' | null = null;

  @Input()
  labelAccept = 'Lagre';

  @Input()
  labelCancel = 'Avbryt';

  @Input()
  processing?: boolean = false;

  @Output()
  accepted: EventEmitter<boolean> = new EventEmitter();

  @Output()
  cancelled: EventEmitter<boolean> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['processing']) {
      this.processing = changes['processing'].currentValue;
    }
  }

  accept() {
    this.accepted.emit(true);
  }

  cancel() {
    this.cancelled.emit(true);
    }
}
