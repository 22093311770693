import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AdminWebUserEntity } from "@core/entities/user/admin-web-user.entity";
import { ProxyRolesHelper } from "@core/helpers/proxy-roles.helper";
import { ProxyUserService } from "@core/services/proxy/proxy-user.service";
import { AuthStore } from "@core/stores/auth.store";
import { EmployerService } from "@modules/employer/services/employer.service";
import { IdRolesHelper } from "@modules/user-management/helpers/id-roles.helper";
import { IdUserService } from "@core/services/id/id-user.service";
import { MembershipService } from "@core/services/membership/membership.service";
import { StrikelogService } from "@modules/strikelog/services/strikelog.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { fromPromise } from "rxjs/internal/observable/innerFrom";
import { catchError, map } from "rxjs/operators";
import { ModalService } from "@shared/components/modal/modal.service";
import { TextModalComponent } from "@shared/components/modal/content/text-modal/text-modal.component";
import { TextModalDataInterface } from "@shared/components/modal/content/text-modal/text-modal-data.interface";
import { MembershipUser } from "@modules/membership-cancellations/types/membership-user.interface";
import { FilesystemService } from "@core/services/filesystem/filesystem.service";
import { IdRoleInterface } from "@core/interfaces/id-role.interface";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  readonly #authStore = inject(AuthStore);
  readonly #employerService = inject(EmployerService);
  readonly #filesystemService = inject(FilesystemService);
  readonly #idUserService = inject(IdUserService);
  readonly #membershipService = inject(MembershipService);
  readonly #modalService = inject(ModalService);
  readonly #proxyUserService = inject(ProxyUserService);
  readonly #router = inject(Router);
  readonly #strikeLogService = inject(StrikelogService);
  readonly #waitingForAuth$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  waitingForAuth$ = this.#waitingForAuth$.asObservable();

  authCompleted(): void {
    this.#waitingForAuth$.next(false);
  }

  deleteStoredUser(): void {
    this.#authStore.deleteItem(AuthStore.KEY_UUID);
    this.#authStore.deleteItem(AuthStore.KEY_FIRST_NAME);
    this.#authStore.deleteItem(AuthStore.KEY_LAST_NAME);
    this.#authStore.deleteItem(AuthStore.KEY_REMEMBER_ME);
  }

  getSelf(): Observable<AdminWebUserEntity | undefined> {
    return this.#authStore.user$;
  }

  getStoredUuid(): string | undefined {
    return this.#authStore.readItem<string>(AuthStore.KEY_UUID);
  }

  getStoredFirstName(): string | undefined {
    return this.#authStore.readItem<string>(AuthStore.KEY_FIRST_NAME);
  }

  getStoredLastName(): string | undefined {
    return this.#authStore.readItem<string>(AuthStore.KEY_LAST_NAME);
  }

  getStoredRememberMe(): boolean {
    return !!this.#authStore.readItem<boolean>(AuthStore.KEY_REMEMBER_ME);
  }

  /**
   * Role "ROLE_ORGANIZATIONAL_UNIT_ADMIN" in "Membership" means you have access
   */
  hasCountyManagementAccess(): Observable<boolean> {
    return this.#membershipService.getSelfCached().pipe(
      map((user: MembershipUser) => {
        return user.roles.some(
          (role) => role.name === 'ROLE_ORGANIZATIONAL_UNIT_ADMIN'
        );
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   *  TODO: Implement
   */
  hasDistributionAccess(): Observable<boolean> {
    return of(true);
  }

  /**
   * Role "ROLE_ADMIN" in "Employer" means you have access
   */
  hasEmployerManagementAccess(): Observable<boolean> {
    return this.#employerService.getSelfCached().pipe(
      map((response) => {
        return response.roles.some((role) => role.role === "ROLE_ADMIN");
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * One or more roles in "Files" means you have access
   */
  hasFilesystemAccess(): Observable<boolean> {
    return this.#filesystemService.getSelfRoles().pipe(
      map((roles: IdRoleInterface[]) => {
        return roles?.length > 0;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * Access to this endpoint means you have access
   */
  hasMembershipCancellationAccess(): Observable<boolean> {
    return this.#membershipService.getCancellations({ limit: 1 }).pipe(
      map(() => {
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   *  TODO: Implement
   */
  hasScholarshipAccess(): Observable<boolean> {
    return of(false);
  }

  /**
   * One or more roles in "StrikeLog" means you have access
   */
  hasStrikeLogAccess(): Observable<boolean> {
    return this.#strikeLogService.getSelf().pipe(
      map((self) => {
        return self.roles.length > 0;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * If user is "user manager" in "ID", this means they have access
   */
  hasUserManagementAccess(): Observable<boolean> {
    return this.#idUserService.getSelf().pipe(
      map((self) => {
        return IdRolesHelper.isUserManager(self);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * If user has role "ROLE_SECTION_ADMIN" in "Membership", they have access
   */
  hasVocationalAccess(): Observable<boolean> {
    return this.#membershipService.getSelfCached().pipe(
      map((user: MembershipUser) => {
        return user.roles.some(
          (role) => role.name === 'ROLE_SECTION_ADMIN'
        );
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * If user can manage forwarding emails (in "Proxy"), they have access
   */
  hasZendeskAccess(): Observable<boolean> {
    return this.#proxyUserService.getSelf().pipe(
      map((self) => {
        return ProxyRolesHelper.canManageForwardingEmails(self);
      }),
      catchError((e: HttpErrorResponse) => {
        return of(false);
      })
    );
  }

  navigateIfStoredRouteExists(): Observable<boolean> {
    const storedRoute = this.#authStore.readItem<string>(AuthStore.KEY_REDIRECT_ROUTE, true);
    const storedRouteStamp = this.#authStore.readItem<number>(AuthStore.KEY_REDIRECT_ROUTE_TIMESTAMP, true);
    const storedRouteExpired = storedRouteStamp == null || (new Date).getTime() > (storedRouteStamp + AuthStore.TTL_REDIRECT_ROUTE);

    if (storedRoute != null && !storedRouteExpired) {
      return fromPromise(this.#router.navigateByUrl(decodeURIComponent(storedRoute)));
    }

    return of(false);
  }

  /**
   * Saves the requested route to storage.
   */
  setStoredRedirectRoute(route: string): void {
    this.#authStore.storeItem<string>(AuthStore.KEY_REDIRECT_ROUTE, encodeURIComponent(route));
    this.#authStore.storeItem<number>(AuthStore.KEY_REDIRECT_ROUTE_TIMESTAMP, new Date().getTime());
  }

  /**
   *  Saves user name and uuid
   */
  setStoredUser(uuid?: string, firstName?: string, lastName?: string): void {
    if (uuid) {
      this.#authStore.storeItem<string>(AuthStore.KEY_UUID, uuid);
    }

    if (firstName) {
      this.#authStore.storeItem<string>(AuthStore.KEY_FIRST_NAME, firstName);
    }

    if (lastName) {
      this.#authStore.storeItem<string>(AuthStore.KEY_LAST_NAME, lastName);
    }
  }

  showAccessModal(): void {
    this.#modalService.openModal({
      component: TextModalComponent,
      title: "Mangler tilgang",
      bodyHtml: `Du mangler tilgang til dette verktøyet. For avklaring og spørsmål kan du
        <a href="https://kontakt.fagforbundet.no/">kontakte brukerstøtte her.</a>`
    } satisfies TextModalDataInterface);
  }

  setRememberMe(rememberMe: boolean): void {
    this.#authStore.storeItem<boolean>(AuthStore.KEY_REMEMBER_ME, rememberMe);
  }
}
