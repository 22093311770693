import { StrikelogEmployerEntity } from '@modules/strikelog/entities/strikelog-employer.entity';
import { alias, date, identifier, list, object, serializable } from 'serializr';


export class StrikelogUnionEntity {
  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('departmentNumber'))
  private _departmentNumber: string;

  @serializable(alias('employers', list(object(StrikelogEmployerEntity))))
  private _employers: StrikelogEmployerEntity[] = [];

  @serializable(alias('fane2Id'))
  private _fane2Id: number;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('organizationNumber'))
  private _organizationNumber: string;

  @serializable(alias('type'))
  private _type: string;

  @serializable(alias('updatedAt', date()))
  private _updatedAt: Date;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get departmentNumber(): string {
    return this._departmentNumber;
  }

  set departmentNumber(value: string) {
    this._departmentNumber = value;
  }

  get employers(): StrikelogEmployerEntity[] {
    return this._employers;
  }

  set employers(value: StrikelogEmployerEntity[]) {
    this._employers = value;
  }

  get fane2Id(): number {
    return this._fane2Id;
  }

  set fane2Id(value: number) {
    this._fane2Id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get organizationNumber(): string {
    return this._organizationNumber;
  }

  set organizationNumber(value: string) {
    this._organizationNumber = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
