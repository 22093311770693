<div class="flex flex--dual">
  <table class="details table table--row-borders table--break-lines">
    <tr>
      <td class="td--descriptor">Dato</td>
      <td class="td--data">{{data?.batch?.sentAt | date}}</td>
    </tr>
    <tr>
      <td class="td--descriptor">Total hovedbok</td>
      <td class="td--data">{{data?.batch?.totalGross | currency}}</td>
    </tr>
    <tr>
      <td class="td--descriptor">Agresso buntnr.</td>
      <td class="td--data">{{data?.batch?.batchNumber}}</td>
    </tr>
  </table>

  <ul class="actions">
    <li>
      <a (click)="downloadLedger()" [tabindex]="0">Last ned fakturabilag hovedbok
        <img alt="" src="assets/images/svg/document.svg" height="24" width="24"/>
      </a>
    </li>
    <li>
      <a (click)="downloadImportFile()" [tabindex]="0">Last ned Agresso importfil
        <img alt="" src="assets/images/svg/download.svg" height="24" width="24"/>
      </a>
    </li>
  </ul>
</div>
