import { Pipe, PipeTransform } from '@angular/core';
import { FileUploadStatus } from '@shared/components/file-upload/assets/file-upload.entity';


@Pipe({
  name: 'fileUploadStatus',
  pure: true
})
export class FileUploadStatusPipe implements PipeTransform {
  transform(status: FileUploadStatus): string {
    return FileUploadStatusStringsEnum[status] || '?';
  }
}

enum FileUploadStatusStringsEnum {
  failed = 'Mislyktes',
  selected = 'Valgt',
  succeeded = 'Fullført',
  uploading = 'Laster opp',
}
