<ng-content select="input"></ng-content>

@if (!processing) {
  <img
    (click)="clickAction()"
    (keydown.enter)="clickAction()"
    [src]="iconPath"
    [class]="'icon ' + iconPosition + ' ' + iconClassList"
    [class.clickable]="clicked.observed && !disabled"
    [class.disabled]="disabled"
    [tabindex]="clicked.observed && !disabled ? '0' : '-1'"
    alt=""
    role="button"
    />
}

@if (processing) {
  <app-loading-indicator class="icon" stroke="#979797" size="s20" />
}
