import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'objToKeys',
  pure: true,
})
export class ObjToKeysPipe implements PipeTransform {
  transform<T extends object>(value: T): string[] | T {
    if (!value) {
      return value;
    }

    return Object.keys(value);
  }
}
