import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input()
  id?: string;

  @Output()
  pageBoundsCorrection: EventEmitter<number>;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  autoHide = false;
  directionLinks = true;
  maxSize = 10;
  responsive = true;
}
