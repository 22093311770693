import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ButtonColors } from '@shared/components/button/button.component';
import { MenuItemComponent } from '@shared/components/menu-item/menu-item.component';
import { ArrowPositionsEnum } from '@shared/enums/arrow-positions.enum';


@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent implements AfterViewInit {
  @Input()
  arrowPosition?: ArrowPositionsEnum;

  @Input()
  disabled = null;

  @ContentChildren(MenuItemComponent, {descendants: true})
  items: QueryList<MenuItemComponent>;

  @ViewChild('menu', {static: false})
  menu: ElementRef;

  @HostBinding()
  role = 'button';

  @Input()
  triggerButton?: string;

  @Input()
  triggerButtonIconClass?: string;

  @Input()
  triggerButtonIconColor: ButtonColors = 'red';

  @Input()
  triggerButtonIconSize = 16;

  @Input()
  triggerIcon?: string;

  @Input()
  triggerIconSize = 20;

  @HostBinding('attr.aria-label')
  @Input()
  triggerText = 'Valg';

  hasMenuItems = false;

  active = false;
  keyManager: ActiveDescendantKeyManager<MenuItemComponent>;

  private _menuOpen: boolean;

  constructor(
    private _elementRef: ElementRef,
  ) {}

  /**
   *
   */
  get buttonClassList(): string {
    return this.triggerButtonIconClass || '';
  }

  /**
   * disabled=false still disables, while null omits attrib which is what we really want
   */
  @HostBinding('disabled')
  get disabledAttrib(): true | null {
    return this.disabled ? true : null;
  }

  /**
   *
   */
  @HostBinding('attr.aria-expanded')
  get menuOpen(): boolean {
    return this._menuOpen;
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.keyManager = new ActiveDescendantKeyManager(this.items).withWrap().withTypeAhead();

    setTimeout(() => {
      this.hasMenuItems = this._shouldBeVisible();
    });

    this.items.changes.subscribe(() => {
      this.hasMenuItems = this._shouldBeVisible();
    });
  }

  @HostListener('window:click', ['$event'])
  handleMouseClick(event: MouseEvent): void {
    // If mouse click outside autocomplete menu or its ancestor, hide menu
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.hideMenu();
    }
  }

  /**
   * Callback for keypress inside input
   */
  @HostListener('keydown', ['$event'])
  handleKeypress(event: KeyboardEvent): void {
    if (this.menuOpen) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }

    const key = event.key;

    switch (key) {
      case 'Tab':
        this.nextItem();
        break;

      case 'Escape':
        this.hideMenu();
        break;

      case 'Enter':
        if (!this.menuOpen) {
          this.showMenu();
        } else {
          // Select item
          this.hideMenu();

          if (this.items.length > 1 && this.keyManager.activeItem) {
            // Trigger the regular mouse click on the selected item
            this.keyManager.activeItem.elementRef.nativeElement.click();
            return;
          }
        }
        break;

      default:
        // All other keys are passed on to the manager
        this.keyManager.onKeydown(event);
        break;
    }
    return;
  }

  /**
   *
   */
  hideMenu(): void {
    this._menuOpen = false;
  }

  /**
   *
   */
  nextItem(): boolean {
    if (!this.menuOpen) {
      return true;
    }

    this.keyManager.setNextItemActive();
    return true;
  }

  /**
   *
   */
  showMenu(): void {
    this._menuOpen = true;
    this._elementRef.nativeElement.focus();
  }

  /**
   *
   */
  @HostListener('click')
  toggleMenu(): void {
    this.menuOpen ? this.hideMenu() : this.showMenu();
  }

  /**
   *
   */
  @HostBinding('class.visible')
  private _shouldBeVisible(): boolean {
    return this.items.length > 0;
  }
}
