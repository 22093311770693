import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { QueuedRequestInterface } from '@core/interfaces/queued-request.interface';
import { AvailableApisType } from '@environments/assets/available-apis.type';
import { Observable, Subject, tap } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _queuedRequests: QueuedRequestInterface<unknown>[] = [];
  private _requestQueue$ = new Subject<any>();

  requestQueueEmpty$: Subject<boolean> = new Subject();

  constructor(
    private _httpClient: HttpClient,
  ) {
    this._requestQueue$.pipe(
      take(1),
      tap(request => this._fireQueuedRequest(request))
    ).subscribe();
  }

  /**
   *
   */
  get<T>(api: AvailableApisType, endpoint: string, endpointVars?: object, queryParams?: object): Observable<T> {
    return this._httpClient.get(this._createURI(api, endpoint, endpointVars, queryParams)).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  /**
   *
   */
  getCollection<T>(api: AvailableApisType, endpoint: string, noContentResponse: T, endpointVars?: object, queryParams?: object): Observable<T> {
    return this._httpClient.get(this._createURI(api, endpoint, endpointVars, queryParams), {
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 204) {
          return noContentResponse; // TODO: Handle better?
        } else {
          return response.body as T;
        }
      })
    );
  }

  /**
   *
   */
  delete<T extends object>(api: AvailableApisType, endpoint: string, endpointVars?: object): Observable<T> {
    return this._httpClient.delete(this._createURI(api, endpoint, endpointVars)).pipe(
      map((response: object) => {
        return response as T;
      })
    );
  }

  /**
   *
   */
  patch<T>(api: AvailableApisType, endpoint: string, payload: {attribute: string, value: unknown}, endpointVars?: object): Observable<T> {
    return this._httpClient.patch(this._createURI(api, endpoint, endpointVars), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  /**
   *
   */
  post<T>(api: AvailableApisType, endpoint: string, payload: any, endpointVars?: object): Observable<T> {
    return this._httpClient.post(this._createURI(api, endpoint, endpointVars), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  /**
   *
   */
  put<T>(api: AvailableApisType, endpoint: string, payload: any, endpointVars?: object): Observable<T> {
    return this._httpClient.put(this._createURI(api, endpoint, endpointVars), payload).pipe(
      map((response: object) => {
        return response as unknown as T;
      })
    );
  }

  /**
   *
   */
  queueRequest<T>(request: Observable<T>): Subject<T> {
    return this._addRequestToQueue<T>(request);
  }

  /**
   *
   */
  private _createURI(api: AvailableApisType, uri: string, variables?: object, queryParams?: object): string {
    // TODO: Figure out if we can avoid passing in the api to all methods
    const baseUrl = EnvironmentHelper.fetchAPIBase(api);

    const urlArray = uri.split('/').map((segment: any) => {
      return variables != null && segment.charAt(0) === ':'
        ? variables[segment.substring(1)]
        : segment;
    });

    let url = baseUrl + urlArray.join('/');

    // Add query params
    if (queryParams && Object.entries(queryParams).length > 0) {
      url += '?';

      const params: string[] = [];

      for (const [key, value] of Object.entries(queryParams)) {
         params.push(key + '=' + value);
      }

      url += params.join('&');
    }

    return url;
  }

  /**
   *
   */
  private _addRequestToQueue<T>(request: Observable<T>): Subject<T> {
    const subscription = new Subject<T>();
    const req: QueuedRequestInterface<T> = {request, subscription};

    this._queuedRequests.push(req);
    if (this._queuedRequests.length === 1) {
      this._startNextQueuedRequest();
    }
    return subscription;
  }

  /**
   *
   */
  private _fireQueuedRequest(requestData): void {
    requestData.request.pipe(
      tap(res => {
        const sub = requestData.subscription;
        sub.next(res);
        this._queuedRequests.shift();
        this._startNextQueuedRequest();
      }),
      finalize(() => {
        requestData.subscription.complete();
      })
    ).subscribe();
  }

  /**
   *
   */
  private _startNextQueuedRequest(): void {
    if (this._queuedRequests.length > 0) {
      this._fireQueuedRequest(this._queuedRequests[0]);
    } else {
      this.requestQueueEmpty$.next(true);
    }
  }
}
