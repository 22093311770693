<a class="logo" [routerLink]="['/dashboard']">
  <img src="/assets/images/svg/logo.svg" alt="Fagforbundets logo">
</a>

<div class="menu">
  <a class="button logout" (click)="logout()">
    Logg ut
    <object data="assets/icons/user.svg" height="20" width="20"></object>
  </a>
</div>
