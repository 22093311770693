import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T> implements AfterViewInit, OnInit, OnChanges {
  @Input()
  allowDeselect = false;

  @Input()
  autofocus: boolean = false;

  @Input()
  isDisabled ? = false;

  /**
   * Which property is used to show/represent each item
   *
   * @example
   * Given that items look like: {code: 'US', name: 'United States'}
   *   having itemDisplayFormay: '%name% (%code%)'
   *   will yield: United States (US)
   */
  @Input()
  itemDisplayFormat = 'name';

  @Output()
  itemSelected: EventEmitter<T | null> = new EventEmitter();

  @Input()
  items: T[];

  @Input()
  processing = false;

  @ViewChild('selectRef', {static: false})
  selectRef: ElementRef<HTMLElement>;

  @Input()
  selectText: string;

  @Input()
  selectedItem?: T;

  currentItem: T | undefined;
  isOpen = false;

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.autofocus) {
        this.selectRef.nativeElement.focus();
      }
    }, 100);
  }

  ngOnInit(): void {
    if (this.selectedItem) {
      if (typeof this.selectedItem === 'string') {
        this.items.forEach((item) => {
          if (item === this.selectedItem) {
            this.currentItem = item;
          }
        });
      } else {
        this.currentItem = this.selectedItem;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && !changes['selectedItem'].isFirstChange()) {
      this.currentItem = changes['selectedItem'].currentValue;
    }

    if (changes['items'] && !changes['items'].isFirstChange()) {
      this.items = changes['items'].currentValue;

      // Reset if no longer available
      if (this.currentItem && this.items.indexOf(this.currentItem) === -1) {
        this.currentItem = undefined;
      }
    }
  }

  close(): void {
    this.isOpen = false;
  }

  formatDisplay(item: null | number | string | T): string {
    if (!item) {
      return '';
    }
    if (typeof item === 'string') {
      return item;
    }
    if (typeof item === 'number') {
      return item.toString();
    }

    // Direct match on a property
    if (item[this.itemDisplayFormat]) {
      // ucFirst if property specified with capital first letter
      return item[this.itemDisplayFormat];
    }

    // Replace placeholders
    return this.itemDisplayFormat.replace(
      /(%)([_?a-zA-Z.]+)(%)/g,
      (match, group1, group2) => {
        const field = item[group2];
        if (!field) {
          return '';
        }
        return field;
      },
    );
  }

  /**
   * Chooses the given (/selected) item
   */
  pickItem(item): void {
    if (this.currentItem === item) {
      this.close();
      return;
    }

    this.currentItem = item;
    this.close();
    this.selectRef.nativeElement.focus();
    this.itemSelected.emit(this.currentItem);
  }

  /**
   * Toggles open state
   */
  toggleState(): void {
    if (this.isDisabled) {
      this.close();
      return;
    }

    this.isOpen = !this.isOpen;
  }
}
