<div class="container">
  @if (data.body) {
    <div class="modal__body">
      {{ data.body }}
    </div>
  }

  <footer class="modal__footer buttons">
    <app-button
      (clicked)="confirm()"
      class="buttons__button btn btn--primary padded"
      label="{{ data.confirmLabel ? data.confirmLabel : 'Bekreft' }}"
    ></app-button>

    <app-button
      (clicked)="cancel()"
      class="buttons__button btn btn--secondary padded"
      color="beige"
      label="{{ data.cancelLabel ? data.cancelLabel : 'Avbryt' }}"
    ></app-button>
  </footer>
</div>
