import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PaginationQueryParamsEntity } from '@core/entities/query-params/pagination-query-params.entity';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-paged-results',
  templateUrl: './paged-results.component.html',
  styleUrls: ['./paged-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagedResultsComponent<T extends PaginationQueryParamsEntity> {
  @Output()
  pageChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  pagedEntity: T;

  @Input()
  processing = false;

  @Input()
  resultTemplate?: TemplateRef<unknown>;

  @Input()
  results$: Observable<unknown[]>;

  @Input()
  searchPerformed = false;
}
