<!-- Allowed files -->
@if (stagedFiles.length) {
  <div class="files uploads">
    <h3>{{config.translationStrings?.allowedFilesHeading || ''}}</h3>
    @for (fileUpload of stagedFiles; track fileUpload; let idx = $index) {
      <div class="file upload">
        <div class="name">{{fileUpload.getFile().name}}</div>
        <div class="file-details">
          <span>{{fileUpload.status | fileUploadStatus }} - </span>
          @if (( (fileUpload.getProgress() | async) ?? 0 ) < 100) {
            <span>
              {{ fileUpload.getProgress() | async }}
              {{ (( fileUpload.getProgress() | async ) ?? 0 ) > 0 ? ' % ' : '' }}
              /
            </span>
          }
          {{fileUpload.file.size | fileSize}}
        </div>
        @if (fileUpload.status === 'selected') {
          <a (click)="removeFile(idx)" role="button">
            <svg-icon
              [applyClass]="true"
              src="/assets/icons/x.svg"
            ></svg-icon>
          </a>
        }
      </div>
    }
  </div>
}

<!-- Discarded files -->
<ng-template #tmplDiscarded let-item="item" let-idx="index">
  <div class="file">
    <div class="file-name">{{item.name}}</div>
    <div class="file-details">{{item.size | fileSize}}</div>
    <div class="file-error">{{item.errorMsg}}</div>
  </div>
</ng-template>

@if (discardedFiles.length) {
  <div class="files discarded">
    <h3>{{config.translationStrings?.discardedFilesHeading || ''}}</h3>
    <app-file-list
      [files]="discardedFiles"
      [ngTemplate]="tmplDiscarded"
      [options]="discardedFilesOptions"
    ></app-file-list>
  </div>
}

<form method="post" enctype="multipart/form-data">
  <label
    (keyup.enter)="fileInputRef.click()"
    [class]="config.uploadButtonClassList ?? ''"
    [for]="config.htmlId"
    [ngClass]="processing ? 'disabled' : ''"
    [tabindex]="0"
    >
    {{config.translationStrings?.labelSelectBtn || (config.multipleFiles ? 'Velg filer' : 'Velg fil')}}
  </label>

  <input
    #fileInputRef
    (change)="fileSelectionChanged($event)"
    [accept]="allowedFormats"
    [attr.multiple]="config.multipleFiles ? '' : null"
    [disabled]="processing"
    [id]="config.htmlId"
    style="display: none"
    type="file"
    />

  <div class="constraints-info file-details">
    {{config.translationStrings?.sizeLimit}} <{{config.maxFileSize | fileSize}}{{descriptionOfFormatsAllowed}}
  </div>

</form>
