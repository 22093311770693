import { StrikelogCommitteeEntity } from '@modules/strikelog/entities/strikelog-committee.entity';
import { StrikelogConflictEntity } from '@modules/strikelog/entities/strikelog-conflict.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { alias, date, identifier, object, serializable } from 'serializr';


export class StrikelogDailyReportEntity {
  @serializable(alias('creator', object(StrikelogUserEntity)))
  private _author?: StrikelogUserEntity;

  @serializable(alias('localStrikeCommittee', object(StrikelogCommitteeEntity)))
  private _committee?: StrikelogCommitteeEntity;

  @serializable(alias('conflict', object(StrikelogConflictEntity)))
  private _conflict?: StrikelogConflictEntity;

  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('date', date()))
  private _date: Date;

  @serializable(alias('noteText'))
  private _noteText: string;

  @serializable(alias('rating'))
  private _rating: number;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;


  get author(): StrikelogUserEntity | undefined {
    return this._author;
  }

  set author(value: StrikelogUserEntity | undefined) {
    this._author = value;
  }

  get committee(): StrikelogCommitteeEntity | undefined {
    return this._committee;
  }

  set committee(value: StrikelogCommitteeEntity | undefined) {
    this._committee = value;
  }

  get conflict(): StrikelogConflictEntity | undefined {
    return this._conflict;
  }

  set conflict(value: StrikelogConflictEntity | undefined) {
    this._conflict = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get noteText(): string {
    return this._noteText;
  }

  set noteText(value: string) {
    this._noteText = value;
  }

  get rating(): number {
    return this._rating;
  }

  set rating(value: number) {
    this._rating = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
