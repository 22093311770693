import { ProxyUserEntity } from '@core/entities/proxy/proxy-user.entity';


export class ProxyRolesHelper {
  /**
   *
   */
  static canManageForwardingEmails(user: ProxyUserEntity): boolean {
    return this.hasRole(user, ProxyRolesEnum.ROLE_SUPER_ADMIN) ||
      this.hasRole(user, ProxyRolesEnum.ROLE_FORWARDING_EMAILS_ADMIN);
  }

  /**
   *
   */
  static canEditEmployeeInformation(user: ProxyUserEntity): boolean {
    return this.hasRole(user, ProxyRolesEnum.ROLE_SUPER_ADMIN) ||
      this.hasRole(user, ProxyRolesEnum.ROLE_EMPLOYEE_ADMIN);
  }

  /**
   *
   */
  static hasRole(user: ProxyUserEntity, role: ProxyRolesEnum): boolean {
    if (user.type === 'BASIC_AUTH_USER') {
      return false;
    }

    if (user.roles != null && user.roles.length > 0) {
      if (user.roles.findIndex((r) => r.name === role) > -1) {
        return true;
      }
    }

    return false;
  }
}

/**
 *
 */
export enum ProxyRolesEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_EMPLOYEE_ADMIN = 'ROLE_EMPLOYEE_ADMIN',
  ROLE_FORWARDING_EMAILS_ADMIN = 'ROLE_FORWARDING_EMAILS_ADMIN',
}
