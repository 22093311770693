<div [class.open]="isOpen" class="drop-down-component">
  @if (isOpen) {
    <div (click)="toggleState()" class="overlay"></div>
  }

  <div
    #selectRef
    (click)="toggleState()"
    (keyup.enter)="toggleState()"
    (keyup.escape)="isOpen = false"
    [class.is-disabled]="isDisabled"
    class="select"
    role="combobox"
    [tabindex]="0"
    >
    @if (currentItem) {
      <span [title]="formatDisplay(currentItem)">
        {{ formatDisplay(currentItem) }}
      </span>
    }

    @if (!currentItem) {
      <span [title]="selectText">
        {{ selectText }}
      </span>
    }

    @if (processing) {
      <app-loading-indicator class="indicator" size="s18" stroke="#000" />
    }
    @if (!processing) {
      <img alt="dropdown-indicator" class="indicator" src="assets/images/svg/arrows-up-down.svg" height="16" width="16">
    }
  </div>

  <div (blur)="isOpen = false" [class.open]="isOpen" class="list">
    @if (allowDeselect && selectText) {
      <div
        (click)="pickItem(null)"
        (keyup.enter)="pickItem(null)"
        (keyup.escape)="isOpen = false"
        class="item"
        [tabindex]="0"
        >
        {{ selectText }}
      </div>
    }

    @for (item of items; track item) {
      <div
        (click)="pickItem(item)"
        (keyup.enter)="pickItem(item)"
        (keyup.escape)="isOpen = false"
        class="item"
        role="listitem"
        [tabindex]="0"
        >
        {{ formatDisplay(item) }}
      </div>
    }
  </div>
</div>
