<div [class.open]="isOpen" class="multiselect">
  @if (isOpen) {
    <div (click)="toggleState()" class="overlay"></div>
  }

  <div
    #selectRef
    (click)="toggleState()"
    (keyup.enter)="toggleState()"
    (keyup.escape)="isOpen = false"
    [class.is-disabled]="isDisabled"
    class="select"
    role="combobox"
    [tabindex]="0"
    >
    <div class="label">
      @for (currentItem of selectedItems; track currentItem) {
        <span
          (click)="unselect($event, currentItem)"
          [title]="resolveText(currentItem)"
          class="badge"
          [tabindex]="0"
          >
          {{ resolveText(currentItem) }}
          <svg-icon
            [applyClass]="true"
            [svgStyle]="{height: '16px', width: '16px'}"
            class="icon"
            src="assets/icons/x.svg"
            [tabindex]="-1"
          ></svg-icon>
        </span>
      }

      @if (!selectedItems?.length) {
        <span [title]="selectText">
          {{ selectText }}
        </span>
      }
    </div>

    <svg-icon
      [applyClass]="true"
      [svgStyle]="{height: '16px', width: '16px'}"
      class="dropdown-indicator"
      src="assets/images/svg/arrows-up-down.svg"
    ></svg-icon>
  </div>

  <div (blur)="isOpen = false" [class.open]="isOpen" class="list">
    <div
      (click)="pickItem(null)"
      (keyup.enter)="pickItem(null)"
      (keyup.escape)="isOpen = false"
      class="item"
      [tabindex]="0"
      >
      {{ selectText }}
    </div>

    @for (item of items; track item) {
      <div
        (click)="pickItem(item)"
        (keyup.enter)="pickItem(item)"
        (keyup.escape)="isOpen = false"
        class="item"
        [class.selected]="isSelected(item)"
        role="listitem"
        [tabindex]="0"
        >
        {{ resolveText(item) }}
      </div>
    }
  </div>
</div>
