import { DateHelper } from '@core/helpers/date.helper';
import { NumberHelper } from '@core/helpers/number.helper';
import { noop } from 'rxjs';
import { alias, custom, identifier, list, object, serializable } from 'serializr';
import { DepartmentUnionEntity } from '../union/department-union.entity';
import { DistributionInvoiceBatchEntity } from './distribution-invoice-batch.entity';
import { DistributionInvoiceLineEntity } from './distribution-invoice-line.entity';


export class DistributionInvoiceEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('createdAt', custom(
    noop,
    timestamp =>  DateHelper.convertTimestampToDate(timestamp))
  ))
  private _createdAt: Date;

  @serializable(alias('departmentUnion', object(DepartmentUnionEntity)))
  private _departmentUnion: DepartmentUnionEntity;

  @serializable(alias('invoiceBatch', object(DistributionInvoiceBatchEntity)))
  private _invoiceBatch: DistributionInvoiceBatchEntity;

  @serializable(alias('invoiceLines', list(object(DistributionInvoiceLineEntity))))
  private _invoiceLines: DistributionInvoiceLineEntity[] = [];

  @serializable(alias('reference'))
  private _reference: number;

  @serializable(alias('title'))
  private _title: string;

  @serializable(alias('totalGross', custom(
    noop,
    amount => NumberHelper.convertIntToDecimal(amount))
  ))
  private _totalGross: number;

  @serializable(alias('totalNet', custom(
    noop,
    amount => NumberHelper.convertIntToDecimal(amount))
  ))
  private _totalNet: number;

  @serializable(alias('vatPercentage', custom(
    noop,
    amount => NumberHelper.convertIntToDecimal(amount))
  ))
  private _vatPercentage: number;

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get departmentUnion(): DepartmentUnionEntity {
    return this._departmentUnion;
  }

  set departmentUnion(value: DepartmentUnionEntity) {
    this._departmentUnion = value;
  }

  get invoiceBatch(): DistributionInvoiceBatchEntity {
    return this._invoiceBatch;
  }

  set invoiceBatch(value: DistributionInvoiceBatchEntity) {
    this._invoiceBatch = value;
  }

  get invoiceLines(): DistributionInvoiceLineEntity[] {
    return this._invoiceLines;
  }

  set invoiceLines(value: DistributionInvoiceLineEntity[]) {
    this._invoiceLines = value;
  }

  get reference(): number {
    return this._reference;
  }

  set reference(value: number) {
    this._reference = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get totalGross(): number {
    return this._totalGross;
  }

  set totalGross(value: number) {
    this._totalGross = value;
  }

  get totalNet(): number {
    return this._totalNet;
  }

  set totalNet(value: number) {
    this._totalNet = value;
  }

  get vatPercentage(): number {
    return this._vatPercentage;
  }

  set vatPercentage(value: number) {
    this._vatPercentage = value;
  }
}
