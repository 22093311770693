import { alias, date, identifier, serializable } from 'serializr';


export class StrikelogDefaultBaseEntity {
  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;


  get createdAt(): Date {
    return this._createdAt;
  }

  get uuid(): string {
    return this._uuid;
  }
}
