import { StrikelogConflictStatus } from '@modules/strikelog/constants/strikelog-conflict-api-status.params';
import { StrikelogCollectiveAgreementEntity } from '@modules/strikelog/entities/strikelog-collective-agreement.entity';
import { StrikelogCommitteeEntity } from '@modules/strikelog/entities/strikelog-committee.entity';
import { StrikelogCompetenceCenterEntity } from '@modules/strikelog/entities/strikelog-competence-center.entity';
import { StrikelogConflictClosingNoteEntity } from '@modules/strikelog/entities/strikelog-conflict-closing-note.entity';
import { StrikelogConflictSummaryEntity } from '@modules/strikelog/entities/strikelog-conflict-summary.entity';
import { StrikelogConflictEntity } from '@modules/strikelog/entities/strikelog-conflict.entity';
import { StrikelogDailyReportEntity } from '@modules/strikelog/entities/strikelog-daily-report.entity';
import { StrikelogEmailAddressEntity } from '@modules/strikelog/entities/strikelog-email-address.entity';
import { StrikelogExemptionApplicationEntity } from '@modules/strikelog/entities/strikelog-exemption-application.entity';
import { StrikelogStrikebreakEntity } from '@modules/strikelog/entities/strikelog-strikebreak.entity';
import { StrikelogUnionEntity } from '@modules/strikelog/entities/strikelog-union.entity';
import { StrikelogUserRoleEntity } from '@modules/strikelog/entities/strikelog-user-role.entity';
import { StrikelogUserEntity } from '@modules/strikelog/entities/strikelog-user.entity';
import { noop } from 'rxjs';
import { alias, custom, date, identifier, list, object, primitive, setDefaultModelSchema } from 'serializr';


export const STRIKELOG_CONFLICT_ENTITY_SCHEMA = {
  factory: context => new StrikelogConflictEntity(),
  props: {
    closingNote: object(StrikelogConflictClosingNoteEntity),
    collectiveAgreement: object(StrikelogCollectiveAgreementEntity),
    committees: alias('localStrikeCommittees', list(object(StrikelogCommitteeEntity))),
    competenceCenters: list(object(StrikelogCompetenceCenterEntity)),
    conflictEmailAddresses: list(object(StrikelogEmailAddressEntity)),
    conflictSummary: object(StrikelogConflictSummaryEntity),
    createdAt: date(),
    dailyReports: list(object(StrikelogDailyReportEntity)),
    description: primitive(),
    endDate: date(),
    exemptionApplications: list(object(StrikelogExemptionApplicationEntity)),
    name: primitive(),
    ongoing: primitive(),
    availableToLocalStrikeCommittee: primitive(),
    organizer: object(StrikelogUserEntity),
    startDate: date(),
    status: custom(noop, (val) => getTypedStrikelogConflictStatus(val)),
    strikebreaks: alias('strikeBreaks', list(object(StrikelogStrikebreakEntity))),
    unions: list(object(StrikelogUnionEntity)),
    updatedAt: date(),
    uuid: identifier(),
  }
};

export const STRIKELOG_USER_ENTITY_SCHEMA = {
  factory: context => new StrikelogUserEntity(),
  props: {
    committee: alias('localStrikeCommittee', object(StrikelogCommitteeEntity)),
    dailyReports: list(object(StrikelogDailyReportEntity)),
    emailAddress: primitive(),
    exemptionApplications: list(object(StrikelogExemptionApplicationEntity)),
    fane2PersonId: primitive(),
    firstName: primitive(),
    idProviderUuid: primitive(),
    lastName: primitive(),
    organizedConflicts: list(object(StrikelogConflictEntity)),
    participatedConflicts: list(object(StrikelogConflictEntity)),
    roles: list(object(StrikelogUserRoleEntity)),
    uuid: identifier(),
  }
};

setDefaultModelSchema<StrikelogConflictEntity>(StrikelogConflictEntity, STRIKELOG_CONFLICT_ENTITY_SCHEMA);
setDefaultModelSchema<StrikelogUserEntity>(StrikelogUserEntity, STRIKELOG_USER_ENTITY_SCHEMA);

// Transform string to key of the StrikelogConflictApiStatusParams enum
export function getTypedStrikelogConflictStatus(strVal: string): StrikelogConflictStatus | string {
  const match = Object.values(StrikelogConflictStatus).find((validKey) => validKey === strVal);
  if (match) {
    return match;
  }
  return strVal;
}
