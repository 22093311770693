<pagination-template
  #p="paginationApi"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
  (pageChange)="pageChange.emit($event)"
  [id]="id!"
  [maxSize]="maxSize"
  >
  @if (!(autoHide && p.pages.length <= 1)) {
    <ul
      [class.responsive]="responsive"
      aria-label="Sider"
      class="ngx-pagination"
      role="toolbar"
      >
      @if (directionLinks) {
        <li [class.disabled]="p.isFirstPage()" class="pagination-previous">
          @if (1 < p.getCurrent()) {
            <a
              (click)="p.previous()"
              (keyup.enter)="p.previous()"
              aria-label="Forrige side"
              [tabindex]="0"
              title="Forrige side: {{p.getCurrent()-1}} / {{p.getLastPage()}}"
              >
              Forrige <span class="show-for-sr">side</span>
            </a>
          }
          @if (p.isFirstPage()) {
            <span>
              Forrige <span class="show-for-sr">side</span>
            </span>
          }
        </li>
      }
      <li class="small-screen">
        {{ p.getCurrent() }} / {{ p.getLastPage() }}
      </li>
      @for (page of p.pages; track page) {
        <li
          [class.current]="p.getCurrent() === page.value"
          [class.ellipsis]="page.label === '...'"
          >
          @if (p.getCurrent() !== page.value) {
            <a
              (click)="p.setCurrent(page.value)"
              (keyup.enter)="p.setCurrent(page.value)"
              [tabindex]="0"
              title="Side {{ page.label }} / {{ p.getLastPage() }}"
              >
              <span class="show-for-sr">Side </span>
              <span>{{ (page.label === '...') ? page.label : (page.label | number:'') }}</span>
            </a>
          }
          @if (p.getCurrent() === page.value) {
            <span class="show-for-sr">Gjeldende </span>
            <span title="Gjeldende side: {{ page.label }} / {{ p.getLastPage() }}">
              {{ (page.label === '...') ? page.label : (page.label | number:'') }}
            </span>
          }
        </li>
      }
      @if (directionLinks) {
        <li [class.disabled]="p.isLastPage()" class="pagination-next">
          @if (!p.isLastPage()) {
            <a
              (click)="p.next()"
              (keyup.enter)="p.next()"
              aria-label="Neste side"
              [tabindex]="0"
              title="Neste side: {{p.getCurrent()+1}} / {{p.getLastPage()}}"
              >
              Neste <span class="show-for-sr">side</span>
            </a>
          }
          @if (p.isLastPage()) {
            <span>
              Neste <span class="show-for-sr">side</span>
            </span>
          }
        </li>
      }
    </ul>
  }
</pagination-template>
