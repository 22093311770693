import { alias, serializable } from 'serializr';


export class StrikelogConflictSummaryEntity {
  @serializable(alias('dailyReports'))
  private readonly _dailyReports: string;

  @serializable(alias('exemptionAppealApplicants'))
  private readonly _exemptionAppealApplicants: string;

  @serializable(alias('exemptionAppeals'))
  private readonly _exemptionAppeals: string;

  @serializable(alias('exemptionApplicants'))
  private readonly _exemptionApplicants: string;

  @serializable(alias('exemptionApplications'))
  private readonly _exemptionApplications: string;

  @serializable(alias('exemptionsDenied'))
  private readonly _exemptionsDenied: string;

  @serializable(alias('exemptionsGranted'))
  private readonly _exemptionsGranted: string;

  @serializable(alias('lastDailyReportRating'))
  private readonly _lastDailyReportRating?: number;

  @serializable(alias('strikeBreaks'))
  private readonly _strikeBreaks: string;


  get dailyReports(): string {
    return this._dailyReports;
  }

  get exemptionAppealApplicants(): string {
    return this._exemptionAppealApplicants;
  }

  get exemptionAppeals(): string {
    return this._exemptionAppeals;
  }

  get exemptionApplicants(): string {
    return this._exemptionApplicants;
  }

  get exemptionApplications(): string {
    return this._exemptionApplications;
  }

  get exemptionsDenied(): string {
    return this._exemptionsDenied;
  }

  get exemptionsGranted(): string {
    return this._exemptionsGranted;
  }

  get lastDailyReportRating(): number | undefined {
    return this._lastDailyReportRating;
  }

  get strikeBreaks(): string {
    return this._strikeBreaks;
  }
}
