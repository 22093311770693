export enum BreadcrumbEnum {
  ADMIN = 'Admin',
  CIRCULAR_LETTERS = 'Rundskriv',
  COUNTY_MANAGEMENT = 'Fylker',
  COUNTY_MANAGEMENT_ORGANIZATIONAL_UNITS = 'Org.-ledd',
  DASHBOARD = 'Dashbord',
  DETAILS = 'Detaljer',
  DISTRIBUTION = 'Distribusjonsverktøy',
  DISTRIBUTION_DEPARTMENT = 'Avdeling',
  DISTRIBUTION_DEPARTMENTS = 'Avdelinger',
  DISTRIBUTION_INVOICE = 'Faktura',
  DISTRIBUTION_INVOICE_BATCHES = 'Fakturabunter',
  DISTRIBUTION_INVOICES = 'Fakturaer',
  DISTRIBUTION_LIST_ACCESS_MANAGEMENT = 'Tilgangsstyring',
  DISTRIBUTION_LIST_ACCESSES = 'Listetilganger',
  DISTRIBUTION_MESSAGE = 'Melding',
  DISTRIBUTION_MESSAGES = 'Meldinger',
  DISTRIBUTION_PERMISSIONS = 'Tilganger',
  DISTRIBUTION_USER = 'Bruker',
  EDIT = 'Rediger',
  EMPLOYER = 'Arbeidsgiver',
  EMPLOYER_APPLICATIONS = 'Søknader',
  EMPLOYER_DEDUCTION_LIST = 'Trekkliste',
  EMPLOYER_DETAILS = 'Detaljer',
  EMPLOYER_ORG_LIST = 'Søk',
  EMPLOYER_PROFILE = 'Profil',
  FILE_MANAGEMENT = 'Filbehandling',
  FILE_SYSTEM = 'Filsystem',
  FORWARDING_EMAILS = 'Videresendingsadresser',
  MEMBERSHIP_APPLICATION = 'Innmelding',
  MEMBERSHIP_CANCELLATIONS = 'Utmeldinger',
  MEMBERSHIP_CANCELLATIONS_ACCESS_MANAGEMENT = 'Tilgangsstyring',
  MEMBERSHIP_CANCELLATIONS_ACCESSES = 'Tilganger',
  MEMBERSHIP_CANCELLATIONS_USER = 'Bruker',
  NATIONAL_POPULATION_REGISTER_LOOKUP = 'Folkeregisteroppslag',
  NEW = 'Ny',
  PROCESS = 'Behandle',
  SCHOLARSHIP = 'Stipend',
  STRIKELOG = 'Streikelogg',
  STRIKELOG_ACTIVITIES = 'Aktivitetsstrøm',
  STRIKELOG_ARCHIVE = 'Streikearkiv',
  STRIKELOG_ARCHIVE_DETAIL = 'Arkivert konflikt',
  STRIKELOG_ARCHIVED_APPLICATION = 'Arkivert søknad',
  STRIKELOG_COMMITTEE = 'Komité',
  STRIKELOG_COMMITTEES = 'Komiteer',
  STRIKELOG_CONFLICT = 'Konflikt',
  STRIKELOG_CONFLICT_END = 'Avslutt konflikt',
  STRIKELOG_CONFLICTS = 'Konflikter',
  STRIKELOG_DAILY_REPORTS = 'Dagsrapporter',
  STRIKELOG_EXEMPTION = 'Dispensasjonssøknad',
  STRIKELOG_EXEMPTIONS = 'Dispensasjonssøknader',
  STRIKELOG_STRIKEBREAKS = 'Streikebryteri',
  USER_MANAGEMENT = 'Brukerstyring',
  USER_MANAGEMENT_PROFILE = 'Brukerprofil',
  VOCATIONAL_SECTIONS = 'Yrkesseksjon',
  ZENDESK_SETTINGS = 'Zendesk-innstillinger',
}
