export enum RecipientListTypesEnum {
  COLLECTIVE_AGREEMENT_MEMBERS = 'Medlemmer under {tariffavtale}',
  COUNTY_BOARD = 'Fylkesstyret i {region}',
  COUNTY_UNION_DEPUTY_LEADERS = 'Nestledere i alle fagforeninger i {region}',
  COUNTY_PENSIONER_COMMITTEE = 'Pensjonistutvalget i {region}',
  COUNTY_PENSIONER_CONTACTS = 'Pensjonistkontakter i {region}',
  COUNTY_PENSIONER_MEMBERS = 'Pensjonistmedlemmer i {region}',
  COUNTY_PUPILS = 'Elevmedlemmer i {region}',
  COUNTY_SECTION_LEADERS = 'Seksjonsledere i {seksjon} i {region}',
  COUNTY_SHOP_STEWARDS_TRANSFERRED_TO_ITSLEARNING = 'Tillitsvalgte overført til itslearning i {region}',
  COUNTY_SHOP_STEWARDS_YOUTH = 'Ungdomstillitsvalgte i {region}',
  COUNTY_STUDENTS = 'Studentmedlemmer i {region}',
  COUNTY_TRAINING_MANAGERS = 'Ledere og opplæringsansvarlige i {region}',
  COUNTY_UNION_LEADERS = 'Ledere i alle fagforeninger i {region}',
  LOCAL_WORKPLACE_MEMBERS = 'Medlemmer ved {lokalt arbeidssted}',
  UNION_BOARD = 'Styret i {fagforening}',
  UNION_COLLECTIVE_AGREEMENT_MEMBERS = 'Medlemmer av {fagforening} under {tariffavtale}',
  UNION_MEMBERS = 'Medlemmer i {fagforening}',
  UNION_MEMBERS_DISABLED = 'Uføremedlemmer i {fagforening}',
  UNION_MEMBERS_PENSIONER = 'Pensjonistmedlemmer i {fagforening}',
  UNION_MEMBERS_PUPILS = 'Elevmedlemmer i {fagforening}',
  UNION_MEMBERS_REHABILITATING = 'Attføringsmedlemmer i {fagforening}',
  UNION_MEMBERS_STUDENTS = 'Studentmedlemmer i {fagforening}',
  UNION_MEMBERS_WORKING = 'Yrkesaktive medlemmer i {fagforening}',
  UNION_SECTION_LEADERS = 'Seksjonsledere i {fagforening}',
  UNION_SECTION_MEMBERS = 'Medlemmer av {fagforening} i {seksjon}',
  UNION_SECTION_MEMBERS_WORKING = 'Yrkesaktive medlemmer av {fagforening} i {seksjon}',
  UNION_SHOP_STEWARDS = 'Plasstillitsvalgte i {fagforening}',
  UNION_YOUTH_MEMBERS = 'Medlemmer yngre enn 30 år i {fagforening}',
  WORKPLACE_MEMBERS_WORKING = 'Yrkesaktive medlemmer ved {arbeidsplass} i {fagforening}',
}
