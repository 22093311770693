import { alias, date, identifier, serializable } from 'serializr';


export class StrikelogEmailAddressEntity {
  @serializable(alias('emailAddress'))
  private _emailAddress: string;

  @serializable(alias('updatedAt', date()))
  private _updatedAt: Date;

  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  constructor(adr: string) {
    this.emailAddress = adr;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get emailAddress(): string {
    return this._emailAddress;
  }

  set emailAddress(value: string) {
    this._emailAddress = value;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  set updatedAt(value: Date) {
    this._updatedAt = value;
  }

  get uuid(): string {
    return this._uuid;
  }
}
