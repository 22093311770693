import { alias, serializable } from 'serializr';


export class StrikelogExemptionApplicationStatisticsEntity {
  @serializable(alias('applicationExemptions'))
  private _applicationExemptions: number;

  @serializable(alias('applicationExemptionsGranted'))
  private _applicationExemptionsGranted: number;

  @serializable(alias('appealExemptions'))
  private _appealExemptions: number;

  @serializable(alias('appealExemptionsGranted'))
  private _appealExemptionsGranted: number;


  get applicationExemptions(): number {
    return this._applicationExemptions;
  }

  set applicationExemptions(value: number) {
    this._applicationExemptions = value;
  }

  get applicationExemptionsGranted(): number {
    return this._applicationExemptionsGranted;
  }

  set applicationExemptionsGranted(value: number) {
    this._applicationExemptionsGranted = value;
  }

  get appealExemptions(): number {
    return this._appealExemptions;
  }

  set appealExemptions(value: number) {
    this._appealExemptions = value;
  }

  get appealExemptionsGranted(): number {
    return this._appealExemptionsGranted;
  }

  set appealExemptionsGranted(value: number) {
    this._appealExemptionsGranted = value;
  }
}
