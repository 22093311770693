import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ElementRefHelper } from '@core/helpers/element-ref.helper';
import { WindowRefHelper } from '@core/helpers/window-ref.helper';
import { ModalBaseClassDirective } from '@shared/components/modal/content/modal-base.class';
import { ModalDataInterface } from '@shared/components/modal/content/modal-data.interface';
import { ModalEventTypeEnum } from '@shared/components/modal/modal-event-type.enum';
import { ModalEventInterface } from '@shared/components/modal/modal-event.interface';
import { ModalService } from '@shared/components/modal/modal.service';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-host',
  templateUrl: './modal-host.component.html',
  styleUrls: ['./modal-host.component.scss'],
})
export class ModalHostComponent implements OnDestroy, OnInit {
  private readonly _onDestroy$ = new Subject<void>();

  @HostBinding('class.closed')
  closed = true;

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  @Input()
  data: ModalDataInterface;

  @ViewChild('dialogRef', { static: true, read: ElementRef })
  dialogRef: ElementRef;

  @ViewChild('modalBodyRef', { static: true, read: ViewContainerRef })
  modalBodyElement: ViewContainerRef;

  containerClass = 'modal-host';
  defaultBackgroundColor = '#fff'
  currentModalEventData: ModalEventInterface = {
    modalData: {} as ModalDataInterface,
  } as ModalEventInterface;
  hoveringCloseButton = false;
  noOverflow: boolean;

  constructor(
    private readonly _modalService: ModalService,
    private readonly _router: Router,
    private readonly _renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this._modalService.modalEvents
      .pipe(
        takeUntil(this._onDestroy$),
        filter(
          (data) =>
            data.event === ModalEventTypeEnum.open &&
            data.modalData !== undefined
        ),
        tap((data) => {
          this.open(data);
        })
      )
      .subscribe();

    this._routerEventsSub();
  }

  close(data: ModalDataInterface): void {
    if (this.closed) {
      return;
    }

    this._modalService.modalClosed(data);
    this.closed = true;

    // reset data
    this.currentModalEventData = {
      modalData: {} as ModalDataInterface,
    } as ModalEventInterface;

    // Release body scroll on modal close
    document.body.classList.remove('no-scroll');

    this.dialogRef.nativeElement.close();
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(keyEvent: Event): void {
    if (this.closed || !(keyEvent instanceof KeyboardEvent)) {
      return;
    }

    let preventDefault = false;
    const key = keyEvent.key.toString();

    switch (key) {
      case 'Escape':
        const activeElement = WindowRefHelper.getNativeWindow().document
          .activeElement as HTMLElement;
        if (ElementRefHelper.isUserInteractable(activeElement)) {
          activeElement.blur();
          preventDefault = true;
        } else {
          this.close(this.currentModalEventData.modalData);
        }
        break;
    }

    if (preventDefault) {
      keyEvent.stopPropagation();
      keyEvent.preventDefault();
    }
  }

  open(eventData: ModalEventInterface): void {
    // Add no-scroll class on body element
    document.body.classList.add('no-scroll');

    this._renderer.setStyle(
      this.dialogRef.nativeElement,
      'background-color',
      eventData.modalData.backgroundColor || this.defaultBackgroundColor
    );

    this._setDefaultOptions(eventData);
    this.currentModalEventData = eventData;
    this.modalBodyElement.clear();

    if (eventData.modalData.component != null) {
      const modal: ComponentRef<ModalBaseClassDirective> =
        this.modalBodyElement.createComponent<ModalBaseClassDirective>(eventData.modalData.component);

      modal.instance.data = eventData.modalData;

      this.dialogRef.nativeElement.showModal();

      modal.instance.closeModal.subscribe(() => {
        this.close(eventData.modalData);
      });
    }

    this.closed = false;
  }

  @HostListener('click', ['$event'])
  outsideClick(event: MouseEvent): void {
    if (
      (event.target as HTMLElement).classList.contains(this.containerClass) &&
      this.currentModalEventData.modalData.closeOnClickOutside
    ) {
      this.close(this.currentModalEventData.modalData);
    }
  }

  /**
   * Close modal on navigation
   */
  private _routerEventsSub(): void {
    this._router.events
      .pipe(
        filter(
          () => this.currentModalEventData?.modalData?.component !== undefined
        ),
        tap((event) => {
          switch (event.constructor.name) {
            case NavigationEnd.name:
              if (this.currentModalEventData?.modalData?.closeOnNavigate) {
                this.close(this.currentModalEventData.modalData);
              }
              break;
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  private _setDefaultOptions(eventData: ModalEventInterface): void {
    eventData.modalData = {
      ...({
        component: null,
        closeOnClickOutside: true,
        closeOnNavigate: true,
        showCloseButton: true,
      } as ModalDataInterface),
      ...eventData.modalData,
    };
  }
}
