import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';


export enum DistributionEndpointsEnum {
  DELETE_RECIPENT_LIST_ACCESS = '/v1/recipient-list-accesses/:accessUuid',
  GET_RECIPENT_LIST_ACCESSES_BY_USER = '/v1/recipient-list-accesses/by-id-portal-uuid/:userUuid',
  GET_RECIPENT_LISTS_REQUIRED_DATA = '/v1/recipient-lists/required-data',
  GET_SYSTEM_SETTINGS = '/v1/system-settings',
  POST_RECIPENT_LIST_ACCESS = '/v2/recipient-list-accesses',
}

export const distributionApi: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://api.distribusjon.fagforbundet.no',
  },
  staging: {
    baseUrl: 'https://api.distribusjon.fagforbundet.dev',
  }
};
