import { alias, serializable } from 'serializr';


export class AdminWebUserEntity {
  @serializable(alias('given_name'))
  private _firstName: string;

  @serializable(alias('family_name'))
  private _lastName: string;

  @serializable(alias('sub'))
  private _sub: string;

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get sub(): string {
    return this._sub;
  }

  set sub(value: string) {
    this._sub = value;
  }
}
