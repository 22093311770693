import { custom } from 'serializr';
import { EnvironmentHelper } from './environment.helper';


export class DateHelper {
  /**
   * Use defaults for time when we instantiate Date()s and only care about days
   */
  static readonly DEFAULT_HOURS = 12;
  static readonly DEFAULT_MINUTES = 34;
  static readonly DEFAULT_SECONDS = 56;
  static readonly DEFAULT_MILLISECONDS = 78;

  /**
   * Regex for format DD.MM.YYYY
   * Requires DD to be (0)1-31
   * Requires MM to be (0)1-12
   * Requires YYYY to be any 2 or 4 digits
   */
  static readonly REGEX_DATE_NOR_SHORT: RegExp = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[0-2])\.(\d{2}|\d{4})$/;

  /**
   *
   */
  static readonly dateIso8601StringSerializer = custom(
    (modelValue: string) => {
      if (!modelValue) {
        return undefined;
      }
      return DateHelper.getDateAsIso8601(DateHelper.parseNorwegianDate(modelValue));
    },
    (jsonValue: string) => jsonValue,
  );

  /**
   *
   */
  static addSecondsToDate(date: Date, numberOfSeconds: number): Date {
    return new Date(date.getTime() + (numberOfSeconds * 1000));
  }

  /**
   *
   */
  static convertTimestampToDate(timestamp: number): Date {
    return new Date(timestamp * 1000);
  }

  /**
   *
   */
  static getDateFormat(): string {
    return EnvironmentHelper.getDateFormat();
  }

  /**
   * Returns date on format YYYY-MM-DD
   */
  static getDateAsIso8601(datum: Date, separator = '-'): string {
    return [
      datum.getFullYear(),
      (datum.getMonth() + 1).toString().padStart(2, '0'), // Use 1-index and prefix '0' if needed
      datum.getDate().toString().padStart(2, '0')
    ].join(separator);
  }

  /**
   * Returns date in timestamp in seconds
   */
  static getStartOfDayTimestamp(datum: Date): number {
    return DateHelper.getUtcTimestamp(datum.getFullYear(),
      datum.getMonth(),
      datum.getDate(),
      0,
      0,
      0,
      1
    );
  }

  /**
   * Returns date in timestamp in seconds
   */
  static getStartOfYearTimestamp(year?: number): number {
    return DateHelper.getUtcTimestamp(year || new Date().getFullYear(),
      0,
      1,
      0,
      0,
      0,
      0
    );
  }

  /**
   *
   */
  static getTimeAndDateFormat(): string {
    return EnvironmentHelper.getTimeAndDateFormat();
  }

  /**
   * Returns date in timestamp in seconds
   */
  static getNowTimestamp(): number {
    const now = new Date();

    return DateHelper.getUtcTimestamp(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );
  }

  /**
   * JavaScript uses milliseconds as the unit of measurement, whereas Unix Time is in seconds.
   */
  static getUnixTimestamp(date: Date): number {
    return (date.getTime() / 1000);
  }

  /**
   *
   */
  static getUtcTimestamp(year: number, month: number, date: number, hours: number, minutes: number, seconds: number, ms?: number): number {
    return Math.round(new Date(Date.UTC(
      year,
      month,
      date,
      hours,
      minutes,
      seconds,
      ms
    )).getTime() / 1000);
  }

  /**
   * Parses into Date from either of these formats:
   * 2021-02-27
   * 27.02.2021
   * 27/02/2021
   * 27 02 2021
   */
  static parseNorwegianDate(dateString: string, assumeCenturyPrefix = '20'): Date {
    if (!dateString) {
      throw new Error('Could not parse date from string ' + dateString);
    }

    const match: RegExpMatchArray | null = dateString.match(/^(\d{1,2})\.(\d{1,2})\.(\d{2}|\d{4})$|^(\d{2}|\d{4})-(\d{1,2})-(\d{1,2})$/);
    if (match == null) {
      throw new Error('Could not parse date from string ' + dateString);
    }

    // TODO: Check date max 28-30 for the shorter months (29 for leap)

    let year;
    let month;
    let day;
    if (match[1]) { // dd mm YYYY
      if (match[3].length === 2) {
        year = parseInt(assumeCenturyPrefix + match[3], 10);
      } else {
        year = parseInt(match[3], 10);
      }
      month = parseInt(match[2], 10);
      day = parseInt(match[1], 10);
    } else if (match[4]) { // YYYY-mm-dd
      if (match[3].length === 2) {
        year = parseInt(assumeCenturyPrefix + match[4], 10);
      } else {
        year = parseInt(match[4], 10);
      }
      month = parseInt(match[5], 10);
      day = parseInt(match[6], 10);
    }

    return new Date(year, month - 1, day, DateHelper.DEFAULT_HOURS);
  }

  /**
   *
   */
  static setDefaultTime(date: Date): Date {
    date.setUTCHours(DateHelper.DEFAULT_HOURS, DateHelper.DEFAULT_MINUTES, DateHelper.DEFAULT_SECONDS, DateHelper.DEFAULT_MILLISECONDS);
    return date;
  }
}
