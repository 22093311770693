import { HttpParameterCodec } from '@angular/common/http';

/**
 * Needed for URIs with special chars such as plus sign (+) until encoding issues are solved:
 * https://github.com/angular/angular/issues/18261
 * https://github.com/angular/angular/issues/11058#issuecomment-292457108
 */
export class CustomHttpParameterCodec implements HttpParameterCodec {
  /**
   *
   */
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  /**
   *
   */
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   *
   */
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  /**
   *
   */
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
