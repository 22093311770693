import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-menu-box',
  templateUrl: './menu-box.component.html',
  styleUrls: ['./menu-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuBoxComponent {
  @Input()
  route: any[];

  @Input()
  svgPath: string;

  @Input()
  title: string;
}
