import { IdUserEntity } from "@modules/user-management/entities/id-user.entity";

export class IdRolesHelper {
  static isAnyAdmin(user: IdUserEntity): boolean {
    return IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_APP_ADMIN) ||
      IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_SYSTEM_ADMIN) ||
      IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_USER_ADMIN);
  }

  static isAppAdmin(user: IdUserEntity): boolean {
    return IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_APP_ADMIN);
  }

  static isSysAdmin(user: IdUserEntity): boolean {
    return IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_SYSTEM_ADMIN);
  }

  static isUserAdmin(user: IdUserEntity): boolean {
    return IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_USER_ADMIN);
  }

  static isUserManager(user: IdUserEntity): boolean {
    return IdRolesHelper.hasRole(user, IdRolesEnum.ROLE_USER_MANAGER);
  }

  static hasRole(user: IdUserEntity, role: IdRolesEnum): boolean {
    for (const r of user.roles) {
      if (r.role === role) {
        return true;
      }
    }

    return false;
  }
}

export enum IdRolesEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_APP_ADMIN = 'ROLE_APP_ADMIN',
  ROLE_USER_ADMIN = 'ROLE_USER_ADMIN',
  ROLE_USER_MANAGER = 'ROLE_USER_MANAGER',
  ROLE_SYSTEM_ADMIN = 'ROLE_SYSTEM_ADMIN',
  ROLE_DEVELOPER = 'ROLE_DEVELOPER'
}
