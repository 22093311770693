import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostBinding, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

let checkboxIndex = 0;

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, OnChanges {
  private readonly HTML_ID_PREFIX: string = 'checkbox_';
  private readonly HTML_ID_LABEL_POSTFIX: string = '_label';

  @Output()
  changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  checked = false;

  @Input()
  bold = true;

  // Displays as text under label
  @Input()
  descriptionText?: string;

  @HostBinding()
  @Input()
  disabled: boolean | null = null; // disabled=false still disables, while null omits attrib

  // Optional. If specified, the [checked] input is not needed.
  @Input()
  formCtrl?: FormControl;

  // Displays as tooltip from ?-icon
  @Input()
  helpText?: string;

  @Input()
  inputId?: string = this.HTML_ID_PREFIX + checkboxIndex++;

  @Input()
  labelText?: string;

  // Used for showing loading indicator
  @HostBinding()
  @Input()
  processing = false;

  @HostBinding()
  tabindex = '-1';

  focused = false;
  form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    if (checkboxIndex === 0) {
      checkboxIndex = this._document.querySelectorAll('[id^="' + this.HTML_ID_PREFIX + '"]').length + 1;
    } else {
      checkboxIndex++;
    }
  }

  get checkCtrl(): FormControl {
    return (this.formCtrl) ? this.formCtrl : this.form.get('checked') as FormControl;
  }

  @HostBinding('attr.aria-checked')
  get isChecked(): boolean {
    return !!this.checkCtrl.value;
  }

  get isDisabled(): boolean | null {
    return (this.disabled || (this.formCtrl && this.formCtrl.disabled)) ?? null;
  }

  @HostBinding('attr.aria-labeledby')
  get labelId(): string {
    return this.inputId + this.HTML_ID_LABEL_POSTFIX;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checked'] && !changes['checked'].isFirstChange()) {
      this.checkCtrl.setValue(!!changes['checked'].currentValue);
    }
  }

  ngOnInit(): void {
    if (!this.formCtrl) {
      this.form = this._fb.group({
        checked: [!!this.checked]
      });
    }
  }

  toggle(event: Event): void {
    if (!this.disabled) {
      this.checkCtrl.setValue(!this.isChecked);
      this.changed.emit(this.isChecked);
    }

    event.preventDefault();
  }
}
