<div [class.hidden]="hidden" class="loading-screen">
  <main aria-hidden="true" class="main">
    <img src="/assets/images/svg/logo.svg" alt="Fagforbundets logo">
    <app-loading-indicator size="large" stroke="#000" />
  </main>

  <div aria-live="assertive" class="visually-hidden" lang="nb" role="status">
    Vennligst vent, siden lastes
  </div>
</div>
