import { alias, date, identifier, list, object, serializable } from 'serializr';

export class CancellationUserIdentifierEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('fane2PersonId'))
  private _fane2PersonId: number;

  @serializable(alias('idProviderUuid'))
  private _idProviderUuid: string;

  get uuid() {
    return this._uuid;
  }

  get fane2PersonId() {
    return this._fane2PersonId;
  }

  get idProviderUuid() {
    return this._idProviderUuid;
  }
}
