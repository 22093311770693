import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { ObjectHelper } from '@core/helpers/object.helper';
import { environment } from '@environments/environment';
import { FileListOptionsInterface } from './file-list-options.interface';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileListComponent<T> implements OnChanges, OnInit {
  @Input()
  files: T[];

  @Output()
  downloadFile: EventEmitter<T> = new EventEmitter<T>();

  @Input()
  ngTemplate?: TemplateRef<{item: T, index: number, total?: number}>; // Type describes the context-obj passed to the template

  @Input()
  options?: FileListOptionsInterface<T>;

  @Output()
  removeFile: EventEmitter<T> = new EventEmitter<T>();

  config: FileListOptionsInterface<T> = {
    canRemove: () => true,
    removeImmediately: true,
    showRemoveBtn: true
  };

  dateTimeFormat = environment.formats.dateTime;
  processing: boolean[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files']) {
      this.processing = [];
    }
  }

  ngOnInit(): void {
    if (this.options != null) {
      this.config = ObjectHelper.nestedAssign(this.config, this.options);
    }
  }

  remove(file: T, idx: number): void {
    if (this.processing[idx]) {
      return;
    }

    if (this.config.removeImmediately) {
      this.files.splice(idx, 1);
    }

    this.processing[idx] = true;
    this.removeFile.emit(file);
  }
}
