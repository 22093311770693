import { Injectable } from '@angular/core';
import { ModalDataInterface } from '@shared/components/modal/content/modal-data.interface';
import { ModalEventTypeEnum } from '@shared/components/modal/modal-event-type.enum';
import { ModalEventInterface } from '@shared/components/modal/modal-event.interface';
import { Observable, Subject } from 'rxjs';
import { UUID } from 'uuid-generator-ts';


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _modalEventsSubject: Subject<ModalEventInterface> = new Subject<ModalEventInterface>();

  get modalEvents(): Observable<ModalEventInterface> {
    return this._modalEventsSubject.asObservable();
  }

  /**
   *
   */
  modalClosed<D extends ModalDataInterface>(data: D): ModalEventInterface {
    const eventUuid = UUID.createUUID();
    const _modalDataUuid = UUID.createUUID();

    const event = {
      event: ModalEventTypeEnum.close,
      eventUuid,
      modalData: {
        _modalDataUuid,
        ...data
      }
    };

    if (data.closedCallback && typeof data.closedCallback === 'function') {
      data.closedCallback(event);
    }

    this._modalEventsSubject.next(event);

    return event;
  }

  /**
   *
   */
  openModal<D extends ModalDataInterface>(data: D): ModalEventInterface {
    const eventUuid = UUID.createUUID();
    const _modalDataUuid = UUID.createUUID();

    const event = {
      event: ModalEventTypeEnum.open,
      eventUuid,
      modalData: {
        _modalDataUuid,
        ...data
      }
    };

    this._modalEventsSubject.next(event);

    return event;
  }
}
