import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

let switchIndex = 0;

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent implements OnInit {
  private readonly HTML_ID_PREFIX: string = 'toggle-switch_';
  private readonly HTML_ID_LABEL_POSTFIX: string = '_label';

  @Output()
  changed = new EventEmitter<boolean>();

  @Input()
  checked = true;

  @Input()
  formControl: FormControl<boolean>;

  @Input()
  processing = false;

  @Input()
  inputId = this.HTML_ID_PREFIX + switchIndex++;

  constructor(
    @Inject(DOCUMENT)
    private _document: Document
  ) {
    if (switchIndex === 0) {
      switchIndex =
        this._document.querySelectorAll('[id^="' + this.HTML_ID_PREFIX + '"]')
          .length + 1;
    } else {
      switchIndex++;
    }
  }

  @HostBinding('class.focused')
  focused = false;

  @HostBinding('attr.aria-checked')
  get isChecked(): boolean {
    return !!this.formControl.value;
  }
  @HostBinding('attr.aria-labeledby')
  get labelId(): string {
    return this.inputId + this.HTML_ID_LABEL_POSTFIX;
  }

  ngOnInit(): void {
    this.formControl ??= new FormControl(this.checked, { nonNullable: true });
  }
}
