import { AvailableApisType } from '@environments/assets/available-apis.type';
import { EnvironmentTypeEnum } from '@environments/assets/environment-type.enum';
import { environment } from '@environments/environment';
import {throwError} from 'rxjs';
import { AvailableClientsType } from "@environments/assets/available-clients.type";

export class EnvironmentHelper {
  /**
   * Generates API URL for a given path
   */
  static fetchAPIBase(api: AvailableApisType): string {
    try {
      return environment.APIS[api].baseUrl;
    } catch (error) {
      // @ts-ignore
      return throwError(() => error);
    }
  }

  static fetchClientURI(client: AvailableClientsType): string {
    try {
      return environment['clients'][client];
    } catch (error) {
      // @ts-ignore
      return throwError(() => error);
    }
  }

  /**
   * Generates URL for a given path to the front end (based on config)
   */
  static fetchFrontEndURL(): string {
    return environment.frontend.baseUrl;
  }

  static getDateFormat(): string {
    return environment.formats.date;
  }

  static getTimeAndDateFormat(): string {
    return environment.formats.dateTime;
  }

  static isIDRequest(url: string): boolean {
    return new URL(url).origin === EnvironmentHelper.fetchAPIBase('id');
  }

  static isProduction(): boolean {
    return environment.type === EnvironmentTypeEnum.PRODUCTION;
  }

  /**
   * 1. Remove API base
   * 2. Prefix local folder base path (assets/mocks/api/)
   * 3. Postfix method name (get/post/etc.)
   * 4. Postfix file extension (.json)
   * 5. Return string. Ex: assets/mocks/api/users/self.get.json
   */
  static replaceWithMockUrl(url: string, method: string): string {
    return 'assets/mocks/api' + url.replace(new URL(url).origin, '') + '.' + method.toLowerCase() + '.json';
  }

  static useMocks(): boolean {
    return environment.useMocks;
  }
}
