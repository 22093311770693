import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DistributionInvoiceBatchEntity } from '@core/entities/distribution/distribution-invoice-batch.entity';
import { DistributionInvoiceEntity } from '@core/entities/distribution/distribution-invoice.entity';
import { PageableDistributionInvoicesEntity } from '@core/entities/distribution/pageable-distribution-invoices.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serializr';


@Injectable({
  providedIn: 'root'
})
export class DistributionInvoiceService {
  constructor(private _httpClient: HttpClient) {}

  /**
   *
   */
  getInvoice(invoiceUuid: string): Observable<DistributionInvoiceEntity> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoices/' + invoiceUuid).pipe(
      map((response: {invoice: object}) => {
        return deserialize(DistributionInvoiceEntity, response.invoice);
      })
    );
  }

  /**
   *
   */
  getInvoiceBatch(invoiceBatchUuid: string): Observable<DistributionInvoiceBatchEntity> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoice-batches/' + invoiceBatchUuid).pipe(
      map((response: {invoiceBatch: object}) => {
        return deserialize(DistributionInvoiceBatchEntity, response.invoiceBatch);
      })
    );
  }

  /**
   *
   */
  getInvoiceBatches(limit = 1): Observable<DistributionInvoiceBatchEntity[]> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoice-batches', {
      observe: 'response',
      params: new HttpParams().set('limit', limit.toString())
    }).pipe(
      map((response: HttpResponse<{invoiceBatches: Array<object>}>) => {
        if (response.status === 204 || response.body == null) {
          return [];
        } else {
          return deserialize(DistributionInvoiceBatchEntity, response.body.invoiceBatches);
        }
      })
    );
  }

  /**
   *
   */
  getInvoiceBatchesImportFile(invoiceBatchUUid: string): Observable<string> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoice-batches/' + invoiceBatchUUid + '/import-file').pipe(
      map((response: {url: string}) => {
        return response.url;
      })
    );
  }

  /**
   *
   */
  getInvoiceBatchesLedgerPdf(invoiceBatchUUid: string): Observable<string> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoice-batches/' + invoiceBatchUUid + '/ledger-pdf').pipe(
      map((response: {url: string}) => {
        return response.url;
      })
    );
  }

  /**
   *
   */
  getInvoicePdf(invoiceUuid: string): Observable<string> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoices/' + invoiceUuid + '/pdf').pipe(
      map((response: {url: string}) => {
        return response.url;
      })
    );
  }

  /**
   *
   */
  getInvoices(paginationParams: PageableDistributionInvoicesEntity): Observable<PageableDistributionInvoicesEntity> {
    return this._httpClient.get(EnvironmentHelper.fetchAPIBase('distribution') + '/v1/invoices', {
      observe: 'response',
      params: paginationParams.apiRequestParams
    }).pipe(
      map((response: HttpResponse<{invoices: object[]}>) => {
        let apiResponse: PageableDistributionInvoicesEntity;
        if (response.status === 204) {
          apiResponse = new PageableDistributionInvoicesEntity();
        } else {
          apiResponse = deserialize(PageableDistributionInvoicesEntity, response.body);
        }

        // Manually assign because API and frontend differs too much to auto serialize
        if (paginationParams.year) {
          apiResponse.year = paginationParams.year;
        }

        return apiResponse;
      })
    );
  }
}
