import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreadcrumbEnum } from "@shared/components/breadcrumb/breadcrumb.enum";
import { tap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-outlet-page',
  templateUrl: './outlet-page.component.html',
  styleUrls: ['./outlet-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutletPageComponent<T> implements OnInit {
  readonly #destroyRef = inject(DestroyRef);

  pageTitle: string;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._activatedRoute.data.pipe(
      tap((params) => {
        if (params['showBreadcrumbTitle'] && params['breadcrumb']) {
          this.pageTitle = BreadcrumbEnum[params['breadcrumb']];
        }
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }
}
