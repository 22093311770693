<ul class="list">
  @for (file of files; track file; let idx = $index) {
    <li class="list__item">
      <ng-container
        *ngTemplateOutlet="ngTemplate ? ngTemplate : defaultTemplate; context: {item: file, index: idx}"
      ></ng-container>
    </li>
  }
</ul>

<!--Default template-->
<ng-template #defaultTemplate let-file="item" let-idx="index">
  <div class="file-name">
    <a
      (click)="downloadFile.emit(file)"
      [download]="file.name"
      class="download"
      >
      {{file.name + file.extension}}
    </a>
    <div class="file-details">
      <div class="file-size">{{file.size | fileSize}}</div>
      <div class="file-date">{{file.createdAt | date:dateTimeFormat}}</div>
    </div>
  </div>

  @if (config.showRemoveBtn && config?.canRemove()) {
    <a (click)="remove(file, idx)" class="file-remove" role="button">
      @if (!processing[idx]) {
        <svg-icon
          [applyClass]="true"
          src="/assets/icons/x.svg"
        ></svg-icon>
      }
      @if (processing[idx]) {
        <app-loading-indicator
          stroke="#1b1b1b" size="s24"
        ></app-loading-indicator>
      }
    </a>
  }
</ng-template>
